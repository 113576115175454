import { Component} from '@angular/core';
//  import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'TripLink-root',
  template: '',
})
export class MsalComponent {
  constructor() {
  }
}
