import { Component, OnInit } from '@angular/core';
import { ApiService } from '../Services/api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { LoadingService } from '../Services/loading.service';
const triplinkConfig = environment.tripLinkConfig;
@Component({
    selector: 'tl-requestuseraccount',
    templateUrl: './requestuseraccount.component.html',
    styleUrls: ['./requestuseraccount.component.scss']
})
export class RequestuseraccountComponent implements OnInit {
    options: GlobalConfig;
    public nrSelect = '0';
    uploadForm: FormGroup;
    public companyList: any[];

    constructor(private apiService: ApiService, private formBuilder: FormBuilder, private httpClient: HttpClient,
        private loadingService: LoadingService, private authService: MsalService, public toastrService: ToastrService) { }

    ngOnInit() {
        document.getElementById('preloader').style['display'] = 'none';

    }

    submitrequest(data) {
        this.loadingService.show();
        const UserRequestData = {
            'FirstName': data.FirstName, 'LastName': data.LastName, 'EmailAddress': data.Email, 'CompanyID': data.CompanyID,
            'RequestStatus': 0
        };

        this.apiService.addUserRequest(UserRequestData).subscribe(res => {
            if (res) {
                if ((res.title) && res.title === 'Exception') {
                    setTimeout(() => {
                        this.toastrService.warning(res.messages[0]);
                    });
                } if (res.Status === true) {
                    setTimeout(() => {
                        this.toastrService.success('User Request Submitted Successfully');
                    });

                }
            } else {
                setTimeout(() => {
                    this.toastrService.warning('User Request Failed');
                });
            }
            this.loadingService.hide();
        });


    }

    navigatetoLogin() {
        sessionStorage.clear();
        this.authService.logout();
    }


    fillcompanyDropdown() {

        this.apiService.getCompanyList().subscribe((companys: any) => {
            this.companyList = companys.map(company => {
                return {
                    name: company.CompanyName,
                    id: company.CompanyID
                };
            });
            const abc = { name: '--Select Company--', id: 0 };
            this.companyList.unshift(abc);
        });
    }

}
