import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { CompanyService } from '../../Services/company.service';
import { async } from 'q';
import { ApiService } from '../../../Services/api.service';
import { UserInfoService } from '../../../Services/userInfo.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Guid } from '@syncfusion/ej2-pdf-export';
import { SearchService } from '../../ui/search/search.service';

@Component({
    selector: 'tl-companytreedropdown',
    templateUrl: './CompanyTreeDropDown.component.html',
})
export class CompanyTreeDropDownComponent implements OnInit, AfterViewInit {
    value;
    items: TreeviewItem[];
    config = TreeviewConfig.create({
        hasFilter: true,
        hasCollapseExpand: true
    });
    @Output() selectedParentCompanyEvent = new EventEmitter<number>();
    @Input() retrieveParentCompanyID: string;
    @Input() disabledControl: string;
    @Input() pagelevel: boolean;
    @Input() geoType: any;
    @Input() id?: any;
    @Input() currentCompany?: any;
    totalList: any[];
    public list: TreeviewItem[] = [];
    selectedCompany;

    constructor(private companyService: CompanyService, private apiService: ApiService, private cdr: ChangeDetectorRef,
        public router: Router, private userInfoService: UserInfoService, public searchSrvc: SearchService, public activatedRoute: ActivatedRoute
    ) {
        // this.value = '93aed8e6-39f6-4ca2-8c40-401ddd0f4214';
        const CompanyTreeDropdownListCopy = sessionStorage.getItem('CompanyTreeDropdownList') != null ?
        JSON.parse(sessionStorage.getItem('CompanyTreeDropdownList')) : [] ;
        const CompanyTreeDropdownList = JSON.parse(JSON.stringify(CompanyTreeDropdownListCopy));
        const combibedlist = [];
        if (CompanyTreeDropdownList) {
            // const othersCategory = new TreeviewItem(CompanyTreeDropdownList);
             CompanyTreeDropdownList.forEach(element => {
                element.text = element.text + '->' + element.CompanyLogoUri;
                this.getLogo(element);
                // element.children.map((child)=> {
                //     child.text =  child.text + 'logo' + child.CompanyLogo;
                //     child.children.map((innerchild)=> {
                //         innerchild.text =  innerchild.text + 'logo' + innerchild.CompanyLogo;
                //     });
                // });
                 combibedlist.push(new TreeviewItem(element));
            });
        this.list = combibedlist;
    }
    }

    getLogo(element) {
       if (element.children) {
        element.children.map((child) => {
            child.text =  child.text + '->' + child.CompanyLogoUri;
            this.getLogo(child);
        });
       }
    }

    ngOnInit() {
        // this.items = (this.companyService.itemData) ? this.companyService.itemData : this.list;
        this.items = this.list;

        //  this.companyService.getCompany();
        //  this.getCompany();
    }
    ngAfterViewInit() {
        // this.message = 'all done loading :)'
        this.cdr.detectChanges();
       }
    onValueChange(item: TreeviewItem) {
        let userInfoValue;
        if (typeof item === 'string') {
            this.selectedParentCompanyEvent.emit(item);
            if (this.userInfoService.loggedinUserData) {
            this.userInfoService.loggedinUserData.CompanyID = item;
            }
            // let companyid = JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID;
            userInfoValue = JSON.parse(sessionStorage.getItem('UserInfo'));
            if (item) {
            userInfoValue.CompanyID = item;
            // userInfoValue.CompanyName = item.text;
            sessionStorage.setItem('UserInfo', JSON.stringify(userInfoValue));
            // const currentUrl = this.router.url ;
            // this.router.navigate([currentUrl.substr(1)]);
        }
        } else { // to avoid multplie api calls
            item && (this.pagelevel) ?  this.selectedParentCompanyEvent.emit(item.value) : '';
        if (this.userInfoService.loggedinUserData) {
        this.userInfoService.loggedinUserData.CompanyID = item.value.toString();
        }
        // let companyid = JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID;
         userInfoValue = JSON.parse(sessionStorage.getItem('UserInfo'));
        if (item && (!this.pagelevel)) {
            if ((!this.selectedCompany || this.selectedCompany !== item.text)) {
                this.selectedParentCompanyEvent.emit(item.value);
            }
        userInfoValue.CompanyID = item.value;
        userInfoValue.CompanyName = item.text;
        sessionStorage.setItem('UserInfo', JSON.stringify(userInfoValue));
        this.selectedCompany = item.text ? item.text : null;
        }
        // const currentUrl = this.router.url ;

        // this.router.navigate([currentUrl.substr(1)]);
    }
        // sessionStorage.setItem('UserInfo.CompanyID', value.toString());

        // this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};


    // .then(() => {
    // //   this.router.navigated = false;
    // //   this.router.navigate([this.router.url]);
    // });
    this.searchSrvc.mapTilesLoadHandler('notloaded');
    }
}
