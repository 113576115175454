import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { BroadcastService } from '@azure/msal-angular';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { MsalCustom } from './Services/MsalCustom.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppState } from './app.state';
import { AppInsightService } from './Services/AppInsights-Service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
export declare enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}
const triplinkConfig = environment.tripLinkConfig;

// declare ga as a function to access the JS code in TS
declare let ga: Function;

@Component({
  selector: 'TripLink-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  isBrowserNotCompatable: boolean;

  hasUser = false;
  showContent = false;
  CompanyTreeDropdownRendered = false;
  userloaded = false;
  permissionsLoaded = false;
  public userLoggedInfo = false;
  isshowTerms = false;
  versionNumber: any;
  public isMenuCollapsed = false;
  public isIframe: boolean;
  private loginFailureSubscription: Subscription;
  private loginSuccessSubscription: Subscription;
  private acquireTokenSuccessSubscription: Subscription;
  private acquireTokenFailureSubscription: Subscription;
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: true,
      correlationHeaderExcludedDomains: ['*.googleapis.com']
    }
  });
  constructor(
    private msalCustom: MsalCustom,
    private broadcastService: BroadcastService,
    @Inject(DOCUMENT) private doc: any,
    public router: Router,
    public route: ActivatedRoute, public appInsightService: AppInsightService
  ) {
     this.isIframe = window !== window.parent && !window.opener;
    // this.userLoggedInfo = false;
   
  }
  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
     let cmp_dr: any = document.getElementById("cmp_dr");
        let isChild = cmp_dr.childNodes[0].classList;
       if (isChild.length>0 && (targetElement.classList[0] != 'btn' && targetElement.classList[1] !=  'dropdown-toggle' && targetElement.classList[2] != 'btn-outline-secondary')  ) {
            cmp_dr.childNodes[0].classList.remove('show');
            cmp_dr.childNodes[0].childNodes[1].classList.remove('show');
       }
       let nav_g_drpdwn: any = document.getElementById("nav_g_drpdwn");
        let isChild1 = nav_g_drpdwn.childNodes[0].classList;
       if (isChild1.length>0 && (targetElement.classList[0] != 'btn' && targetElement.classList[1] !=  'dropdown-toggle' && targetElement.classList[2] != 'btn-outline-secondary')  ) {
        nav_g_drpdwn.childNodes[0].classList.remove('show');
        nav_g_drpdwn.childNodes[0].childNodes[1].classList.remove('show');
       }
       
  }


  
  
  ngOnInit(): void {

    // this.router.events.subscribe(event => {

    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
    
    this.msalCustom.login();
    // this.appInsightService.logEvent('Message Application Loaded.');

    // this.appInsightService.logTrace('App initialised Traced at ' + new Date().toString());
    // this.appInsightService.logEvent('App initialised evented at ' + new Date().toString());
    // this.appInsightService.logMetric('App initialised metriced at ' + new Date().toString(), 1);
    //  this.appInsightService.logPageView('App initialised pageviewed at App component ' + new Date().toString());


    this.isBrowserNotCompatable = this.msalCustom.isIEBrowser();
    if (this.isBrowserNotCompatable) {
      document.getElementById('preloader').style['display'] = 'none';
    }
    this.loginFailureSubscription = this.broadcastService.subscribe
    ("msal:loginFailure", (payload) => {
    });

    this.loginSuccessSubscription = this.broadcastService.subscribe
    ("msal:loginSuccess", (payload) => {

    });
    this.acquireTokenSuccessSubscription = this.broadcastService.subscribe("msal:acquireTokenSuccess", (payload) => {

    });

    this.acquireTokenFailureSubscription = this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {

    });
  }
  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.broadcastService.getMSALSubject().next(1);
    if (this.loginFailureSubscription) {
      this.loginFailureSubscription.unsubscribe();
    }
    if (this.loginSuccessSubscription) {
      this.loginSuccessSubscription.unsubscribe();
    }
    if (this.acquireTokenSuccessSubscription) {
      this.acquireTokenSuccessSubscription.unsubscribe();
    }
    if (this.acquireTokenFailureSubscription) {
      this.acquireTokenFailureSubscription.unsubscribe();
    }
  }
}
