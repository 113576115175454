
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tempConverter'
})
export class TempConverterPipe implements PipeTransform {
    transform(value: number, dec: number = 2) {
        if(value != null) {
            let temperature = (value * 1.8 ) + 32
                    return temperature.toFixed(dec);
            }
             return;
    }
}
