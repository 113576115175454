export enum PowerState {
    "<2 Hrs OFF" = 0,
    "ON" = 1,
    "2-4 Hrs OFF" = 2,
    ">4 Hrs OFF" = 3,
    ">2 Hrs NOT REPORTING" = 4
}

export enum TripwiseStatusCode {
    "OFF" = 0,
    "EXPIRED" = 1,
    "CHECK" = 2,
    "PASS" = 3,
    "ANALYZING1" = 14,
    "DATANOTAVAILABLE" = 15,
    "ANALYZING" = 255,
    "NOTEQUIPPED" = -1
}

export enum FilterScreenNames {
    Dashboard=0,
    ContainerStatus=1,
    Containers=2,
    Devices=3
}

export enum CommandsStatus {
    "Pending" = 0,
    "SMSSent" = 1,
    "Delivered" = 2,
    "Completed" = 3,
    "Expired" = 4,
    "Failed" = 5,
    "Queued" = 6,
    "Unknown" = 7,
    "InvalidDevice" = 8,
    "GatewayTimeout" = 9,
    "MethodNotImplimented" = 10,
    "Cancelled" = 11
}

export enum Frequency {
    Every24hrs = 0,
    Every48hrs = 1,
    Every72hrs = 2
}