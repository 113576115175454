import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { TroubleshootComponent } from './troubleshoot/troubleshoot.component';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';

import { AlarmPopupComponent } from './alarm-popup/alarm-popup.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { TooltipModule, DialogModule } from '@syncfusion/ej2-angular-popups';
import { RefeerPopupComponent } from './refeer-popup/refeer-popup.component';
import { PowerStatusPopupComponent } from './power-status-popup/power-status-popup.component';
import { AssetModelPopupComponent } from './asset-model-popup/asset-model-popup.component';
import {
  PageService, SortService, FilterService, SearchService, ToolbarService, GridModule, EditService,
  ReorderService, ResizeService, ColumnChooserService, ExcelExportService, FreezeService,
  PdfExportService, GroupService, ContextMenuService
} from '@syncfusion/ej2-angular-grids';
import { ReportingStatusPopupComponent } from './reporting-status-popup/reporting-status-popup.component';
import { PTIResultsDialogComponent } from './ptiresults-dialog/ptiresults-dialog.component';
import { MixedFleetPTIResultsDialogComponent } from "./mixedfleet-ptiresults-dialog/mixedfleet-ptiresults-dialog.component";
import { CAPTResultsDialogComponent } from './captresults-dialog/captresults-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TRANSLATION_PROVIDERS } from 'src/language-translation';
import { TranslationModule } from 'src/language-translation/translation.module';
import { TripwiseExpiryPopupComponent } from './tripwise-expiry-popup/tripwise-expiry-popup.component';
import { ManufacturerPopupComponent } from './manufacturer-popup/manufacturer-popup.component';
import { CustomPipesModule } from 'src/app/shared/customPipes/customPipes.module';
import { GensetReportingStatusPopupComponent } from './gensetreporting-status-popup/gensetreporting-status-popup.component';
import { PtiresultsGensetDialogComponent } from './ptiresults-genset-dialog/ptiresults-genset-dialog.component';
// import { TranslocoRootModule } from 'src/app/transloco-root.module';

@NgModule({
  declarations: [AlarmPopupComponent,
    ModalInfoComponent,
    TroubleshootComponent,
    RefeerPopupComponent,
    PowerStatusPopupComponent,
    AssetModelPopupComponent,
    ReportingStatusPopupComponent,
    GensetReportingStatusPopupComponent,
    PTIResultsDialogComponent,
    MixedFleetPTIResultsDialogComponent,
    SpinnerComponent,
    CAPTResultsDialogComponent,
    TripwiseExpiryPopupComponent,
    ManufacturerPopupComponent,
    PtiresultsGensetDialogComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    GridModule,
    TabModule,
    ListViewModule,
    TranslationModule,
    CustomPipesModule
    // TranslocoRootModule
  ],
  exports: [AlarmPopupComponent,
    CAPTResultsDialogComponent,
    ModalInfoComponent,
    TroubleshootComponent,
     PowerStatusPopupComponent,
     AssetModelPopupComponent,
     ManufacturerPopupComponent,
     ReportingStatusPopupComponent,
     GensetReportingStatusPopupComponent,
     PTIResultsDialogComponent,
     MixedFleetPTIResultsDialogComponent,
     SpinnerComponent,
     RefeerPopupComponent,
     TripwiseExpiryPopupComponent
    ],
    providers: [SearchService, ToolbarService, TRANSLATION_PROVIDERS],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class SharedDialogsModule { }
