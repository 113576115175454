import { HttpClient } from '@angular/common/http';
import { TRANSLOCO_LOADER, Translation, TranslocoLoader, TRANSLOCO_CONFIG, translocoConfig,  TranslocoModule } from '@ngneat/transloco';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MsalComponent } from './msal.component';
import { MsalAngularConfiguration, MsalModule, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { Configuration } from 'msal';
const triplinkConfig = environment.tripLinkConfig;
export const protectedResourceMap: [string, string[]][] = [
  [
    'https://buildtodoservice.azurewebsites.net/api/todolist',
    triplinkConfig.consentScopes,
  ],
  ['https://graph.microsoft.com/v1.0/me', triplinkConfig.consentScopes],
];
function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: triplinkConfig.clientId,
      authority: triplinkConfig.authority,
      validateAuthority: triplinkConfig.validateAuthority,
      redirectUri: triplinkConfig.redirectUri,
      postLogoutRedirectUri: triplinkConfig.postLogoutRedirectUri,
      navigateToLoginRequestUrl: triplinkConfig.navigateToLoginRequestUrl,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true, // set to true for IE 11
    },
  };
}
function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes: triplinkConfig.consentScopes,
    unprotectedResources: ['https://www.microsoft.com/en-us/'],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}

@NgModule({
  declarations: [
    MsalComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([{path: '', component: MsalComponent}, {path: '**', redirectTo: ''}]),
    MsalModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

  providers: [ {
    provide: MSAL_CONFIG,
    useFactory: MSALConfigFactory
  },
  {
    provide: MSAL_CONFIG_ANGULAR,
    useFactory: MSALAngularConfigFactory
  }],
   bootstrap: [MsalComponent]
})
export class MyMsalModule {}
