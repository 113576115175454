import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchBehavior$ = this.searchTerm.asObservable();

  public containerStatusSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');
  containerSearchBehavior$ = this.containerStatusSearch.asObservable();

  public TripSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');
  TripSearchBehavior$ = this.TripSearch.asObservable();

  public searchTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  searchTitleBehavior$ = this.searchTitle.asObservable();

  public clearSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');
  clearSearch$ = this.clearSearch.asObservable();

  public mapLoadedHandler: BehaviorSubject<string> = new BehaviorSubject<string>('');
  mapLoadedBehaviour$ = this.mapLoadedHandler.asObservable();

  public gensetStatusSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');
  gensetSearchBehavior$ = this.gensetStatusSearch.asObservable();

  constructor() { }

  public containerStatus(term: string) {
    this.containerStatusSearch.next(term);
  }
  public TripNameSearch(term: string) {
    this.TripSearch.next(term);
  }

  public gensetStatus(term: string) {
    this.gensetStatusSearch.next(term);
  }

  public mapTilesLoadHandler(term: string) {
    this.mapLoadedHandler.next(term);
  }
  public updateSearchTerm(term: string) {
    this.searchTerm.next(term);
  }

  public titleSearch(term: string) {
    this.searchTitle.next(term);
  }

  public clear(term: string) {
    this.clearSearch.next(term);
    }
 }
