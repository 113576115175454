export const companyFilterRestrictedPages: any = [
  'company', 'assetdetails', 'permissions',  'containerdetails' ,'gensetdetails', 'addassets', 'users', 'adddevices', 'changepassword','userprofile', 'containers/commands', 'userdetails','addvessel','editvessel',
  'userDetails', 'shipper','notificationsettings', 'notifications', 'addfleet','userpreferences', 'devicefirmware', 'devicefirmwaredetails', 'controllersoftware', 'controllersoftwaredetails',
  'addcontainer', 'deviceconfiguration', 'updatefirmware', 'upgradehistory', 'loghistory', 'fleet-analytics' , 'roledetails','geofencedetails','containerSearch'
  
];

export const filterAllowPages: any = [
   'containerstatus', 'dashboard', 'devices', 'containers','containers/commands'
];

export const filterRestrictPages: any = [
  '/app/containermanagement/containers/commands', '/app/containermanagement/approvedevices', 
  '/app/containermanagement/devices/adddevices', '/app/containermanagement/containers/addcontainer','/app/containermanagement/devices/deviceconfiguration'
  ,'/app/containermanagement/devices/updatefirmware', '/app/containermanagement/devices/loghistory'
];
