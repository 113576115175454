import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { TabComponent, ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GridComponent, ToolbarItems, FilterSettingsModel, PageSettingsModel, PdfExportProperties, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { DatePipe } from '@angular/common';
import { CustomSyncFusionGridService } from '../../Services/customSyncfusionGrid.service';
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/Services/userInfo.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { TranslateService } from 'src/language-translation';
import { CONFIG } from 'src/app/Core/config';
import { LoadingService } from 'src/app/Services/loading.service';
import { UnitConverterService } from 'src/app/Services/unit-converter.service';
import { DataUtil } from '@syncfusion/ej2-data';

@Component({
  selector: 'tl-tripwise-expiry-popup',
  templateUrl: './tripwise-expiry-popup.component.html',
  styleUrls: ['./tripwise-expiry-popup.component.scss']
})
export class TripwiseExpiryPopupComponent implements OnInit {

  @Input() tripwiseStatusInfo: any;
  @ViewChild('tripwiseStatusDialog', { static: false })
  public tripwiseStatusDialog: DialogComponent;
  @ViewChild('tripwiseGrid', { static: false }) public tripwiseGrid: GridComponent;
  @Output() tripwiseDialogClose = new EventEmitter();

  public data: any;
  public measurementUnit = 'Metric';
  public tempUnit = 'C';

  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;
  public confirmHeader;
  public visible: Boolean = true;
  public hidden: Boolean = true;
  public toolbarOptions: ToolbarItems[] | object;
  public filterOptions: FilterSettingsModel;
  public pageSettings: PageSettingsModel;
  public formatOptions: object;
  public decimalpoint: any;
  public target = '.control-section';
  public animationSettings: Object = { effect: 'None' };
  public confirmCloseIcon: Boolean = true;
  public isModal: Boolean = true;
  public enablePowerStatus: Boolean = false;
  public dateFormat: string;
  public filterSettings = { type: 'Excel' };

  constructor(
    public datepipe: DatePipe,
    public customSyncFusionGridService: CustomSyncFusionGridService,
    public router: Router,
    private userInfoService: UserInfoService,
    public UnitConverterService: UnitConverterService,
    private LoadingService: LoadingService,
    private _dashboardService: DashboardService,
    private _translate: TranslateService
  ) {
    this.filterOptions = {
      type: 'Excel',
    };
    this.pageSettings = { pageSizes: ['10','20', '50', '100', '500'],  pageSize: 10 };
    this.dateFormat = this.UnitConverterService.getDateFormat();
    this.formatOptions = {
      type: 'dateTime',
      format: this.dateFormat + ' hh:mm:ss a',
    };
    this.decimalpoint =  'N2' ; // { format: 'N2' };
    this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
    this.measurementUnit = this.userInfoService.loggedinUserData
      ? this.userInfoService.loggedinUserData.UnitConversionType
      : sessionStorage.getItem('UserInfo')
      ? JSON.parse(sessionStorage.getItem('UserInfo')).UnitConversionType
      : '';
    if (this.measurementUnit == 'Imperial') {
      this.tempUnit = 'F';
    }
  }

  filldata() {
    if (this.data) {
      if (this.data.TestExpiryList) {
        this.data['TestExpiryList'] = this.data.TestExpiryList.map((item) => {
          item.EventTimeUTC = this.UnitConverterService.convertTimeZone(
            item.EventTimeUTC
          );
          item.ExpirationDate = this.UnitConverterService.convertTimeZone(
            item.ExpirationDate
          );
          if (this.tempUnit === 'F') {
            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
          } else if (this.tempUnit === 'C') {
            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
          }
          if (item.PowerStatus == 1) {
            item['PowerStatus'] = 'ON';
        }
        else if (item.PowerStatus == 0) {
            item['PowerStatus'] = 'OFF';
        }
        else if (item.PowerStatus == null) {
            item['PowerStatus'] = 'unknown';
        }
          return item;
        });
      }
      this.data = DataUtil.parse.parseJson(this.data.TestExpiryList);
      this.confirmHeader = 
      this._translate.translate("TripLINK_Expiry") +  " < 3" + this._translate.translate("days");

    }
  }

  ngOnInit() {
    this.filterSettings = { type: 'Excel' };
    if (this.tripwiseStatusInfo) {
      this.LoadingService.show();
      this._dashboardService
        .getTripwiseExpiryDetails(
          this.tripwiseStatusInfo.tripwiseExpiryData.apiObject
        )
        .subscribe((response) => {
          this.data = response;
          this.filldata();
          this.enablePowerStatus = true;
          this.LoadingService.hide();
        });
    }
  }

  toolbarClick(args: ClickEventArgs): void {
    let checkNum;
    const pdfExportProperties: PdfExportProperties = {
      pageOrientation: 'Landscape',
      fileName: 'TripwiseExpiry.pdf'
    };
    switch (args.item.text) {
      case 'PDF Export':
        this.tripwiseGrid.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        const excelExportProperties: ExcelExportProperties = {
          fileName: 'TripwiseExpiry.xlsx'
      };
        this.tripwiseGrid.excelExport(excelExportProperties);
        break;
      case 'CSV Export':
        this.tripwiseGrid.csvExport();
        break;
    }
  }

  navigateAssetHistory(invNumData) {
    this.tripDialogClosed();
    const invNum = this.tripwiseGrid.getRowInfo(invNumData.target).rowData;
    const expirydata = {
      startdate: this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
      endDate: this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
    };
    const data = { type: 'dashboard', data: expirydata };
    this.LoadingService.show();
    this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });
  }

  tripDialogClosed() {
    this.tripwiseDialogClose.emit(0);
  }
}
