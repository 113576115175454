import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { Browser } from '@syncfusion/ej2-base';
import { UnitConverterService } from '../../../Services/unit-converter.service';

@Component({
  selector: 'tl-ptiresults-genset-dialog',
  templateUrl: './ptiresults-genset-dialog.component.html',
  styleUrls: ['./ptiresults-genset-dialog.component.css']
})
export class PtiresultsGensetDialogComponent implements OnInit {

  @Input() PTIResultsInfo: any;
  data: any;
  public confirmCloseIcon: Boolean = true;
  public hidden: Boolean = true;
  public visible: Boolean = false;
  public animationSettings: Object = { effect: 'None' };
  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;
 // public position: object = {  X: 400, Y: 200 };
  public targetElement: HTMLElement;
  public target = '.control-section';
  public isModal: Boolean = true;
  public dateFormat: string;

  // Create element reference for dialog target element.
  @ViewChild('container',  {static: false}) container: ElementRef;
  @ViewChild('PTIResultDialog', {static: false}) public PTIResultDialog: DialogComponent;
  @ViewChild('MixedFleetPTIResultDialog', {static: false}) public MixedFleetPTIResultDialog: DialogComponent;

  constructor(public unitConverterService: UnitConverterService) {

   }
 // Initialize the Dialog component's target element.
 public initilaizeTarget: EmitType<object> = () => {
  this.targetElement = this.container.nativeElement.parentElement;
}
public oncreated(): void {
    this.PTIResultDialog.show(Browser.isDevice ? true : false);
}
  ngOnInit() {
    this.dateFormat = this.unitConverterService.getDateFormat();
    let data = JSON.parse(JSON.stringify(this.PTIResultsInfo))
    if (data.ptiResultData) {
      data.ptiResultData.EventTimeUTC = this.unitConverterService.convertTimeZone(this.PTIResultsInfo.ptiResultData.EventTimeUTC);
      data.ptiResultData.PTIStartTime = this.unitConverterService.convertTimeZone(this.PTIResultsInfo.ptiResultData.PTIStartTime);
    }
    this.data = data.ptiResultData;

  }

}
