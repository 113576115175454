import { DefaultTreeviewI18n, DropdownDirective, TreeviewItem, TreeviewSelection } from 'ngx-treeview';
import { MenuService } from 'src/app/theme/components/menu/menu.service';

export class DropdownTreeviewSelectI18n extends DefaultTreeviewI18n {
    private internalSelectedItem: TreeviewItem;

   // private closeDropdown:DropdownDirective;

    set selectedItem(value: TreeviewItem) {
        // if (value && value.children === undefined) {
            this.internalSelectedItem = value;
        // }
    }

    get selectedItem(): TreeviewItem {
        return this.internalSelectedItem;
    }

    getText(selection: TreeviewSelection): string {

        return this.internalSelectedItem ?  this.internalSelectedItem.text.split('->')[0] :  '--Select--' ;
    }

    // close(){
    //     this.closeDropdown.close();
    // }
}
