import { Injectable } from '@angular/core';
import { CONFIG } from 'src/app/Core/config';
import { of } from 'rxjs';
import { ServerService } from '../../Core/server.service';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private alarmInfo;
    constructor(private serverService: ServerService) {
    }

    public getDashboardMapInfo() {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.dashboardAPI
        }) || of('');
    }

    public getDashboardMarkerInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.markerInfoAPI, encodeURIComponent(CompanyID))

        }) || of('');
    }

    public getContainerInfo(deviceId: string, containerId: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(this.fillParam(CONFIG.url.containerInfoAPI, containerId), encodeURIComponent(deviceId))
        }) || of('');
    }

    public getGensetLocInfo(gensetId) {
        
        return this.serverService.get<any>({
            api: CONFIG.api.gensetAPI,
            // url: CONFIG.url.getgensetlocationdetails
            url: this.fillParam(CONFIG.url.getgensetlocationdetails, encodeURIComponent(gensetId))

        }) || of('');
    }

    public getVesselInfo(vesselId: string, companyId: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(this.fillParam(CONFIG.url.vesselInfoAPI, vesselId), encodeURIComponent(companyId))
        }) || of('');
    }

    public getAlarmAPIInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.alarmInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');
    }

    public getLocationAPIInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.locationInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');
    }

    public getPluggedinAPIInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.pluggedInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');
    }

    public getTripwiseStatusAPIInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.tripwiseInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');
    }

    public getSetPointInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.setPointInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');
    }

    public getControllerfwInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.controllerfwInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');
    }

    public getDevicefwInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.devicefwInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');
    }

    public getAssetModelInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.assetModelInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');

    }
    public getManufacturerInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetmanufacturerinfogeofencesummaryAPI
        }, data) || of('');
    }

    public getAssetNotReportingStatusInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getAssetNotReportingStatusInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');

    }

    public getAssetPowerStatusInfo(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getAssetPowerStatusInfoAPI, encodeURIComponent(CompanyID))
        }) || of('');

    }

    public setAlarmData(alarmData) {
        this.alarmInfo = alarmData;
    }

    public getAlarmData() {
        return this.alarmInfo;
    }

    fillParam(template: string, param: any): string {
        if (Array.isArray(param)) {
            param.forEach(p => {
                template = this.fillParam(template, p);
            });
            return template;
        } else {
            return template.replace('${}', param || '');
        }
    }

    dashboardLocationGeoInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.dashboardLocationGeoInfo
        }, data) || of('');
    }
    dashboardGensetLocationGeoInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.gensetAPI,
            url: CONFIG.url.getgensetlocationinfo
        }, data) || of('');
    }
    getlocationinfogeofence(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.getlocationinfogeofenceInfo
        }, data) || of('');
    }

    getAlarmGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.alarmGeofenceInfoAPI
        }, data) || of('');
    }

    getSetPointGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.setPointGeofenceInfoAPI
        }, data) || of('');
    }

    getAssetModelGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetModelGeofenceInfoAPI
        }, data) || of('');
    }

     getAssetModelGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetModelGeofenceSummaryAPI
        }, data) || of('');
    }
    getManufacturerDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetmanufacturerinfogeofencedetails
        }, data) || of('');
    }

    getSetPointGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.setPointGeofenceSummaryAPI
        }, data) || of('');
    }

    getControllerfwGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.controllerfwGeofenceInfoAPI
        }, data) || of('');
    }

 getControllerfwGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.controllerfwGeofenceSummaryAPI
        }, data) || of('');
    }

    getDevicefwGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.devicefwGeofenceInfoAPI
        }, data) || of('');
    }
    getDevicefwGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.devicefwGeofenceSummaryAPI
        }, data) || of('');
    }

    getTripwiseExpirySummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.tripwiseExpirySummaryGeofenceAPI
        }, data) || of('');
    }

    getAssetNotReportingStatusGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.getAssetNotReportingStatusGeofenceInfoAPI
        }, data) || of('');
    }

    getAssetPowerStatusGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.getAssetPowerStatusGeofenceInfoAPI
        }, data) || of('');
    }

    getTripwiseGeofenceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.tripwiseGeofenceInfoAPI
        }, data) || of('');
    }

    getAlarmGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.alarmInfoGeofenceSummaryAPI
        }, data) || of('');
    }

    getAlarmGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.alarmInfoGeofenceDetailsAPI
        }, data) || of('');
    }

    getTripwiseInfoGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.tripwiseInfoGeofenceSummaryAPI
        }, data) || of('');
    }

    getTripwiseInfoGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.tripwiseInfoGeofenceDetailsAPI
        }, data) || of('');
    }

    getAssetPowerStatusGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetPowerStatusGeofenceSummaryAPI
        }, data) || of('');
    }

    getAssetPowerStatusGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetPowerStatusGeofenceDetailsAPI
        }, data) || of('');
    }

   getReeferModalGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.reeferModelInfoGeofenceDetailsAPI
        }, data) || of('');
    }

    getTripwiseExpiryDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.tripwiseExpiryInfoGeofenceAPI
        }, data) || of('');
    }



    getSetPointGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.setPointInfoGeofenceDetailsAPI
        }, data) || of('');
    }

    getDeviceGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.deviceInfoGeofenceDetailsAPI
        }, data) || of('');
    }

    getControllerGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.controllerInfoGeofenceDetailsAPI
        }, data) || of('');
    }

    getAssetNotReportingStatusGeofenceSummary(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetNotReportingStatusGeofenceSummaryAPI
        }, data) || of('');
    }
    getGensetNotReportingStatusGeofenceSummary(data) {
        return this.serverService.get<any>({
            api: CONFIG.api.gensetAPI,
            // url: CONFIG.url.gensetNotReportingStatusGeofenceSummaryAPI
            url: this.fillParam(CONFIG.url.gensetNotReportingStatusGeofenceSummaryAPI, encodeURIComponent(data.CompanyID))

        }) || of('');
    }

    getAssetNotReportingStatusGeofenceDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.assetNotReportingStatusGeofenceDetailsAPI
        }, data) || of('');
    }
    getGensetAssetNotReportingStatusGeofenceDetails(data) {
        return this.serverService.get<any>({
            api: CONFIG.api.gensetAPI,
            url: this.fillParam(CONFIG.url.gensetNotReportingStatusGeofenceDetailsAPI, encodeURIComponent(data.CompanyID))

        }) || of('');
    }
    getWidgetExpandPreference(userId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getwidgetexpandpreferenceAPI, encodeURIComponent(userId))
        }) || of('');
    }
    updateWidgetExpandPreference(User) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.updateexpandcollapseAPI
        }, User) || of('');
    }
}
