import { Injectable } from '@angular/core';
import { LoadingService } from '../../Services/loading.service';
import { ApiService } from '../../Services/api.service';
import { SuccessFailureService } from '../Services/successFailure.service';
import { successFailureMessages } from '../../../assets/data/staticMessages';


@Injectable({
    providedIn: 'root',
  })
export class CustomSyncFusionGridService {
    lengthEqualtozero = 'equaltoZero';
    lengthGreatreThanZero = 'greaterThanZero';
    constructor(public loadingService: LoadingService,
        public apiService: ApiService,
        public successFailureService: SuccessFailureService
        ) {
    }
    HideExportButtons(grid: any) {
        let obj = null;
        let isHide = false;
        if ((grid.l10n) && grid.l10n.controlName === 'treegrid') {
            if (grid.dataResults.result) {
            isHide = grid.dataResults.result.length > 0 ? false : true;
            obj = (grid.toolbarModule) ? grid.toolbarModule.parent.toolbar : null;
            if (obj) {
                if (!isHide) {
                    grid.toolbarModule.parent.toolbar =  ['Add', 'Edit', 'ExcelExport', 'PdfExport', 'Search' ];
            } else {
                grid.toolbarModule.parent.toolbar =  ['Add', 'Edit', 'Search'];
            }
            }
        }
        } else {
        isHide = grid.currentViewData.length > 0 ? false : true;
        obj = (grid.toolbarModule) ? grid.toolbarModule.toolbar : null;
        if (obj) {
            obj.hideItem(obj.items.findIndex(c => c.text === 'Excel Export'), isHide);
            obj.hideItem(obj.items.findIndex(c => c.text === 'PDF Export'), isHide);
        }
        }
    }
    showNoRecordstoView(grid: any, data: any, type) {
        switch (type) {
            case 'equaltoZero':
                if (data && data.length === 0) {
                    if (grid) {
                        grid.element.classList.remove('noinitialdata');
                    }
                }
                break;
            case 'greaterThanZero':
                if (data && data.length > 0) {
                    if (grid) {
                        grid.element.classList.remove('noinitialdata');
                    }
                }
                break;
        }
    }
    saveGridSettings(gridsettings: any, GridID: any) {
        const stateData = {
            'UserID': JSON.parse(sessionStorage.getItem('UserInfo')).UserID,
            'GridID': GridID,
            'GridSetting': gridsettings
        };
        this.loadingService.show();
        this.apiService.saveGridPreference(stateData).subscribe(res => {
            this.successFailureService.successFailureReturn(res);
            this.loadingService.hide();

        });
    }
}





