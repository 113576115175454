import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MobileNumberCOdes } from '../../../../assets/data/CountryCodes';
@Component({
  selector: 'tl-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss']
})
export class CountryDropdownComponent implements OnInit {
  @Output() selectedCountryEvent = new EventEmitter<string>();

  selectedCountryCode = '';

  @Input() bindCountryCode: string;
  @Input() isShow?: boolean;


  countryCodes = [];
  customLabels = [];
  countryNames = [];
  constructor() {
  }

  ngOnInit() {
    this.isShow = this.isShow ? false : true;
    this.countryCodes = MobileNumberCOdes.map(u => u['code'].toLowerCase());
    const arra = [];
    MobileNumberCOdes.forEach(el => {
      arra[el['code'].toLowerCase()] = el['name'];
    });
    this.customLabels = arra.sort();
    this.customLabels = arra;
    if (this.bindCountryCode) {
      this.selectedCountryCode = this.bindCountryCode;

    }

  }
  bindCountryfromCOde(args) {
    this.selectedCountryCode = args.iso2;
  }
  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
    this.selectedCountryEvent.emit(value);

  }

}
