// export const timeZonesList: any = [
//   '(UTC-10:00) — French Polynesia',
//   '(UTC-09:30) — Marquesas Islands',
//   '(UTC-09:00) — Gambier Islands',
//   '(UTC-08:00) — Clipperton Island',
//   '(UTC-04:00) AST — Guadeloupe, Martinique, Saint Barthélemy, Saint Martin',
//   '(UTC-03:00) PMST — French Guiana, Saint Pierre and Miquelon',
//   '(UTC+01:00) CET — Metropolitan France',
//   '(UTC+03:00) — Mayotte',
//   '(UTC+04:00) — Réunion, Crozet Islands, Scattered Islands in the Indian Ocean',
//   '(UTC+05:00) — Kerguelen Islands, Îles Saint-Paul and Amsterdam',
//   '(UTC+10:00) unofficial — Dumont-d Urville Station',
//   '(UTC+11:00) — New Caledonia',
//   '(UTC+12:00) — Wallis and Futuna',
//   '(UTC+02:00) Kaliningrad Time — Kaliningrad Oblast',
//   '(UTC+03:00) Moscow Time ',
//   '(UTC+04:00) Samara Time — Astrakhan Oblast, Samara Oblast',
//   '(UTC+04:00) Saratov Oblast, Udmurtia, and Ulyanovsk Oblast',
//   '(UTC+05:00) Yekaterinburg Time — Bashkortostan',
//   '(UTC+05:00) Chelyabinsk Oblast, Khanty–Mansia',
//   '(UTC+05:00) Kurgan Oblast, Orenburg Oblast',
//   '(UTC+05:00) Perm Krai, Sverdlovsk Oblast',
//   '(UTC+05:00) Tyumen Oblast, and Yamalia',
//   '(UTC+06:00) Omsk Time — Omsk Oblast',
//   '(UTC+07:00) Krasnoyarsk Time — Altai Krai',
//   '(UTC+07:00) Altai Republic, Kemerovo Oblast',
//   '(UTC+07:00) Khakassia, Krasnoyarsk Krai',
//   '(UTC+07:00) Novosibirsk Oblast, Tomsk Oblast, and Tuva',
//   '(UTC+08:00) Irkutsk Time — Buryatia and Irkutsk Oblast',
//   '(UTC+09:00) Yakutsk Time — Amur Oblast, western Sakha Republic',
//   '(UTC+09:00) Zabaykalsky Krai',
//   '(UTC+10:00) Vladivostok Time — Jewish Autonomous Oblast',
//   '(UTC+10:00) Khabarovsk Krai, Primorsky Krai, and central Sakha Republic',
//   '(UTC+11:00) Magadan Time — Magadan Oblast, eastern Sakha, and Sakhalin Oblast',
//   '(UTC+12:00) Kamchatka Time — Chukotka and Kamchatka Krai',
//   '(UTC-12:00) unofficial — Baker Island and Howland Island',
//   '(UTC-11:00) ST — American Samoa, Jarvis Island, Kingman Reef',
//   '(UTC+11:00) Midway Atoll and Palmyra Atoll',
//   '(UTC-10:00) HT — Hawaii, most of the Aleutian Islands, and Johnston Atoll',
//   '(UTC-09:00) AKT — Alaska',
//   '(UTC-08:00) PT — Pacific Time zone: California',
//   '(UTC-08:00) Nevada, Washington',
//   '(UTC-07:00) MT — Mountain Time zone: Colorado ',
//   '(UTC-07:00) Montana , New Mexico, Utah ',
//   '(UTC-07:00) Wyoming, Arizona',
//   '(UTC-06:00) CT — Central Time zone: Alabama , Arkansas ',
//   '(UTC-06:00) Illinois , Iowa , Louisiana , Minnesota , Mississippi ',
//   '(UTC-06:00) Missouri , Oklahoma , Wisconsin',
//   '(UTC-05:00) ET — Eastern Time zone: Connecticut,Delaware',
//   '(UTC-05:00) Georgia ,Maine ,Maryland ,Massachusetts ,New Hampshire',
//   '(UTC-05:00) New Jersey ,New York ,North Carolina ,Ohio ,Pennsylvania',
//   '(UTC-05:00) Rhode Island ,South Carolina,Vermont ,Virginia ,West Virginia',
//   '(UTC-04:00) AST — Puerto Rico, the U.S. Virgin Islands',
//   '(UTC+10:00) ChT — Guam and the Northern Mariana Islands',
//   '(UTC+12:00) unofficial — Wake Island, McMurdo Station',
//   '(UTC+12:00) Amundsen–Scott South Pole Station',
//   '(UTC-03:00) ART — Palmer Station',
//   '(UTC-03:00) Rothera Station',
//   '(UTC+00) GMT — Troll Station',
//   '(UTC+03:00) — Syowa Station',
//   '(UTC+05:00 — Mawson Station',
//   '(UTC+06:00) — Vostok Station',
//   '(UTC+07:00) — Davis Station',
//   '(UTC+10:00) — Dumont-dUrville Station',
//   '(UTC+11:00) — Casey Station',
//   '(UTC+11:00) Macquarie Island',
//   '(UTC+12:00) — McMurdo Station',
//   '(UTC+05:00) — Heard and McDonald Islands',
//   '(UTC+06:30) — Cocos Keeling Islands',
//   '(UTC+07:00) CXT — Christmas Island',
//   '(UTC+08:00) AWST — Western Australia',
//   '(UTC+08:00) South Australia and Kalgoorlie, Western Australia',
//   '(UTC+08:45) CWT – South Australia Border Village',
//   '(UTC+08:45) Western Australia Caiguna, Eucla, Madura, Mundrabilla',
//   '(UTC+09:30) ACST — South Australia ,Northern Territory',
//   '(UTC+09:30) New South Wales Yancowinna County',
//   '(UTC+10:00) AEST — Queensland, New South Wales',
//   '(UTC+10:00) Australian Capital Territory, Victoria, Tasmania',
//   '(UTC+10:30) — Lord Howe Island',
//   '(UTC+11:00) NFT — Norfolk Island',
//   '(UTC-08:00) — Pitcairn Islands',
//   '(UTC-05:00) — Cayman Islands',
//   '(UTC-05:00) Turks and Caicos Islands',
//   '(UTC-04:00) AST — Anguilla, Bermuda',
//   '(UTC-04:00) British Virgin Islands, Montserrat',
//   '(UTC-03:00) FKST — Falkland Islands',
//   '(UTC-02:00) — South Georgia and the South Sandwich Islands',
//   '(UTC) Coordinated Universal Time',
//   '(UTC) GMT in winter/BST in summer-United Kingdom, Saint Helena',
//   '(UTC) Ascension and Tristan da Cunha',
//   '(UTC) Guernsey, Isle of Man, Jersey',
//   '(UTC) Coordinated Universal Time',
//   '(UTC+01:00) CET — Gibraltar',
//   '(UTC+02:00) EET — Akrotiri and Dhekelia',
//   '(UTC+06:00) — British Indian Ocean Territory',
//   '(UTC-08:00) PT — British Columbia, Tungsten and Cantung Mine, Yukon',
//   '(UTC-07:00) MT — Alberta, British Columbia',
//   '(UTC-07:00) Northwest Territories, Nunavut, Lloydminster',
//   '(UTC-06:00) CT — Manitoba, Nunavut, Ontario , Saskatchewan',
//   '(UTC-05:00) ET — Nunavut, Southampton Island, Ontario, Quebec ',
//   '(UTC-04:00) AT — Labrador, New Brunswick, Nova Scotia',
//   '(UTC-04:00) Prince Edward Island, eastern part of Quebec',
//   '(UTC-03:30) NT — Labrador southeastern, Newfoundland',
//   '(UTC-04:00) — Thule Air Base in Greenland',
//   '(UTC-03:00) — Greenland, south coast and west coast',
//   '(UTC-01:00) — Ittoqqortoormiit ',
//   '(UTC)— Danmarkshavn, Faroe Islands',
//   '(UTC+01:00) CET — Denmark',
//   '(UTC-11:00) — Niue',
//   '(UTC-10:00) — Cook Islands',
//   '(UTC+12:00) — New Zealand',
//   '(UTC+12:45 — Chatham Islands',
//   '(UTC+13:00) — Tokelau',
//   '(UTC-05:00) Brasília time−2-Acre and Southwestern Amazonas',
//   '(UTC-04:00) Brasília time-1—Amazonas State, Mato Grosso',
//   '(UTC-04:00) Mato Grosso do Sul',
//   '(UTC-04:00) Rondônia, Roraima',
//   '(UTC-03:00) Brasília time-the Southeast Region',
//   '(UTC-03:00) the South Region',
//   '(UTC-03:00) the Northeast Region',
//   '(UTC-03:00) Goiás',
//   '(UTC-03:00) Distrito Federal',
//   '(UTC-03:00) Tocantins, Pará, Amapá',
//   '(UTC-02:00) Brasília time +1—East coast of Brazil',
//   '(UTC-02:00) Trindade and Martim Vaz',
//   '(UTC-02:00) Rocas Atoll, Saint Peter',
//   '(UTC-02:00) Saint Paul Archipelago',
//   '(UTC-08:00) Northwest Zone — Baja California',
//   '(UTC-07:00) the states of Baja California Sur',
//   '(UTC-07:00) Chihuahua, Nayarit, Sinaloa and Sonora',
//   '(UTC-06:00) Zone 2 or Central Zone — Mexico',
//   '(UTC-05:00) Zone 1 or Southeast Zone — the state of Quintana Roo',
//   '(UTC-06:00)Easter Island',
//   '(UTC-04:00)Chile',
//   '(UTC-03:00)Magallanes and Chilean Antarctica',
//   '(UTC+07:00) Western Indonesian Standard Time — islands of Sumatra',
//   '(UTC+07:00) Java, provinces of Bangka Belitung Islands, Riau Islands',
//   '(UTC+07:00) West Kalimantan and Central Kalimantan',
//   '(UTC+08:00) Central Indonesian Standard Time — islands of Sulawesi',
//   '(UTC+08:00) Bali, provinces of East Nusa Tenggara',
//   '(UTC+08:00) West Nusa Tenggara, East Kalimantan',
//   '(UTC+08:00) North Kalimantan and South Kalimantan',
//   '(UTC+09:00) Eastern Indonesian Standard Time — provinces of Maluku',
//   '(UTC+09:30) North Maluku, Papua and West Papua',
//   '(UTC+12:00) — Gilbert Islands',
//   '(UTC+13:00) — Phoenix Islands',
//   '(UTC+14:00) — Line Islands',
//   '(UTC+01:00) WAT — provinces of Équateur, Kinshasa',
//   '(UTC+01:00) Kongo Central, Kwango, Kwilu',
//   '(UTC+01:00) Mai-Ndombe, Mongala, Nord-Ubangi',
//   '(UTC+01:00) Sud-Ubangi and Tshuapa',
//   '(UTC+02:00) CAT — provinces of Bas-Uele, Haut-Katanga',
//   '(UTC+02:00) Haut-Lomami, Haut-Uele, Kasaï, Kasaï-Central',
//   '(UTC+02:00) Kasaï Oriental, Lomami, Lualaba, Maniema',
//   '(UTC+02:00) Nord-Kivu, Sankuru, Sud-Kivu, Tanganyika',
//   '(UTC+02:00) Tshopo and Ituri Interim Administration',
//   '(UTC-06:00) GALT — Galápagos Province',
//   '(UTC-05:00) Ecuador Time — Ecuador',
//   '(UTC+10:00)the states of Chuuk and Yap',
//   '(UTC+11:00)the states of Kosrae and Pohnpei',
//   '(UTC+05:00)western Kazakhstan Aktobe',
//   '(UTC+05:00) Atyrau',
//   '(UTC+05:00) Kyzylorda, Mangystau',
//   '(UTC+05:00) West Kazakhstan',
//   '(UTC+06:00) — eastern Kazakhstan',
//   '(UTC-04:00) AST — Caribbean municipalities',
//   '(UTC-04:00) constituent countries',
//   '(UTC+01:00) CET — Netherlands',
//   '(UTC+07:00)the provinces of Khovd',
//   '(UTC+07:00)Uvs and Bayan-Ölgii',
//   '(UTC+08:00)Mongolia',
//   '(UTC+10:00)Papua New Guinea',
//   '(UTC+11:00)Autonomous Region of Bougainville (Bougainville Standard Time)',
//   '(UTC-01:00)Azores',
//   '(UTC)WET — Madeira and Portugal',
//   '(UTC+02:00)South African Standard Time — South Africa',
//   '(UTC+03:00)Prince Edward Islands',
//   '(UTC)WET — Canary Islands',
//   '(UTC+01:00)CET — Spain',
//   '(UTC+02:00)EET — Ukraine',
//   '(UTC+03:00)Donetsk and Luhansk regions',
//   '(UTC+04:30)',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) WAT',
//   '(UTC-04:00) AST',
//   '(UTC-03:00) ART',
//   '(UTC+04:00)',
//   '(UTC+01:00) CET',
//   '(UTC+04:00)',
//   '(UTC-05:00) EST',
//   '(UTC+03:00)',
//   '(UTC+06:00) BST',
//   '(UTC-04:00)',
//   '(UTC+03:00) FET',
//   '(UTC+01:00) CET',
//   '(UTC-06:00)',
//   '(UTC+01:00) WAT',
//   '(UTC+06:00) BTT',
//   '(UTC-04:00)',
//   '(UTC+01:00) CET',
//   '(UTC+02:00) CAT',
//   '(UTC+08:00)',
//   '(UTC+02:00) EET',
//   '(UTC)',
//   '(UTC+02:00) CAT',
//   '(UTC+07:00)',
//   '(UTC+01:00) WAT',
//   '(UTC-01:00) Cape Verde Time',
//   '(UTC+01:00) WAT',
//   '(UTC+01:00) WAT',
//   '(UTC+08:00) CST',
//   '(UTC-05:00)',
//   '(UTC+03:00) EAT',
//   '(UTC+01:00) WAT',
//   '(UTC-06:00)',
//   '(UTC+01:00) CET',
//   '(UTC-05:00)',
//   '(UTC+02:00) EET',
//   '(UTC+01:00) CET, RT',
//   '(UTC+03:00) EAT',
//   '(UTC-04:00)',
//   '(UTC-04:00)',
//   '(UTC+09:00)',
//   '(UTC+02:00) EET',
//   '(UTC-06:00)',
//   '(UTC+01:00) WAT',
//   '(UTC+03:00) EAT',
//   '(UTC+02:00) EET',
//   '(UTC+02:00)',
//   '(UTC+03:00) EAT',
//   '(UTC+12:00)',
//   '(UTC+02:00) EET',
//   '(UTC+01:00) WAT',
//   '(UTC)',
//   '(UTC+04:00)',
//   '(UTC+01:00) CET',
//   '(UTC+02:00) EET',
//   '(UTC-04:00)',
//   '(UTC-06:00)',
//   '(UTC-04:00)',
//   '(UTC-05:00)',
//   '(UTC-06:00)',
//   '(UTC+08:00) HKT',
//   '(UTC+01:00) CET',
//   '(UTC+05:30 IST',
//   '(UTC+03:30 IRST',
//   '(UTC+03:00)',
//   '(UTC) WET',
//   '(UTC+02:00) IST',
//   '(UTC+01:00) CET',
//   '(UTC-05:00)',
//   '(UTC+09:00) JST',
//   '(UTC+02:00)',
//   '(UTC+03:00) EAT',
//   '(UTC+09:00) Pyongyang Time',
//   '(UTC+09:00) Korea Standard Time',
//   '(UTC+03:00) Arabia Standard Time',
//   '(UTC+06:00)',
//   '(UTC+07:00)',
//   '(UTC+02:00) EET',
//   '(UTC+02:00) EET',
//   '(UTC+02:00)',
//   '(UTC+02:00) EET',
//   '(UTC+01:00) CET',
//   '(UTC+02:00) EET',
//   '(UTC+01:00) CET',
//   '(UTC+08:00) Macau Standard Time',
//   '(UTC+03:00) EAT',
//   '(UTC+02:00) CAT',
//   '(UTC+08:00) Malaysian Standard Time',
//   '(UTC+05:00)',
//   '(UTC+01:00) CET',
//   '(UTC+12:00)',
//   '(UTC+04:00) Mauritius Time',
//   '(UTC+02:00) EET',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) CET',
//   '(UTC+02:00) CAT',
//   '(UTC+06:30) MST',
//   '(UTC+01:00) WAT',
//   '(UTC+12:00)',
//   '(UTC+05:45) Nepal Time',
//   '(UTC-06:00)',
//   '(UTC+01:00) WAT',
//   '(UTC+01:00) WAT',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) CET',
//   '(UTC+04:00)',
//   '(UTC+05:00) PKT',
//   '(UTC+09:00)',
//   '(UTC+02:00)',
//   '(UTC-05:00)',
//   '(UTC-04:00)',
//   '(UTC-05:00) PET',
//   '(UTC+08:00) PHT',
//   '(UTC+01:00) CET',
//   '(UTC+03:00) Arabia Standard Time',
//   '(UTC+02:00) EET',
//   '(UTC+02:00) CAT',
//   '(UTC-04:00)',
//   '(UTC-04:00)',
//   '(UTC-04:00)',
//   '(UTC+13:00)',
//   '(UTC+01:00) CET',
//   '(UTC+03:00) Arabia Standard Time',
//   '(UTC+01:00) CET',
//   '(UTC+04:00) Seychelles Time',
//   '(UTC+08:00) SST',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) CET',
//   '(UTC+11:00)',
//   '(UTC+03:00) EAT',
//   '(UTC+03:00) EAT',
//   '(UTC+05:30) SLST',
//   '(UTC+02:00)',
//   '(UTC-03:00)',
//   '(UTC+01:00) CET',
//   '(UTC+01:00) CET',
//   '(UTC+02:00) EET',
//   '(UTC+08:00)',
//   '(UTC+05:00)',
//   '(UTC+03:00) EAT',
//   '(UTC+07:00) THA',
//   '(UTC+13:00)',
//   '(UTC-04:00) AST',
//   '(UTC+01:00) CET',
//   '(UTC+03:00) TRT',
//   '(UTC+05:00)',
//   '(UTC+12:00)',
//   '(UTC+03:00) EAT',
//   '(UTC+04:00)',
//   '(UTC-03:00)',
//   '(UTC+05:00) Uzbekistan Time',
//   '(UTC+11:00)',
//   '(UTC+01:00) CET',
//   '(UTC-04:00)',
//   '(UTC+07:00) Indochina Time',
//   '(UTC+03:00)',
//   '(UTC+02:00) CAT',
//   '(UTC+02:00) CAT'
// ]

export const timeZonesList: {[key: string]: Object }[] = [
   { timezone: "Pacific/Niue", text: "(UTC-11:00) Niue" },
   { timezone: "Pacific/Pago_Pago", text: "(UTC-11:00) Pago Pago" },
   { timezone: "Pacific/Honolulu", text: "(UTC-10:00) Hawaii Time" },
   { timezone: "Pacific/Rarotonga", text: "(UTC-10:00) Rarotonga" },
   { timezone: "Pacific/Tahiti", text: "(UTC-10:00) Tahiti" },
   { timezone: "Pacific/Marquesas", text: "(UTC-09:30) Marquesas" },
   { timezone: "America/Anchorage", text: "(UTC-09:00) Alaska Time" },
   { timezone: "Pacific/Gambier", text: "(UTC-09:00) Gambier" },
   { timezone: "America/Los_Angeles", text: "(UTC-08:00) Pacific Time" },
   { timezone: "America/Tijuana", text: "(UTC-08:00) Pacific Time - Tijuana" },
   {
     timezone: "America/Vancouver",
     text: "(UTC-08:00) Pacific Time - Vancouver"
   },
   {
     timezone: "America/Whitehorse",
     text: "(UTC-08:00) Pacific Time - Whitehorse"
   },
   { timezone: "Pacific/Pitcairn", text: "(UTC-08:00) Pitcairn" },
   { timezone: "America/Denver", text: "(UTC-07:00) Mountain Time" },
   {
     timezone: "America/Phoenix",
     text: "(UTC-07:00) Mountain Time - Arizona"
   },
   {
     timezone: "America/Mazatlan",
     text: "(UTC-07:00) Mountain Time - Chihuahua, Mazatlan"
   },
   {
     timezone: "America/Dawson_Creek",
     text: "(UTC-07:00) Mountain Time - Dawson Creek"
   },
   {
     timezone: "America/Edmonton",
     text: "(UTC-07:00) Mountain Time - Edmonton"
   },
   {
     timezone: "America/Hermosillo",
     text: "(UTC-07:00) Mountain Time - Hermosillo"
   },
   {
     timezone: "America/Yellowknife",
     text: "(UTC-07:00) Mountain Time - Yellowknife"
   },
   { timezone: "America/Belize", text: "(UTC-06:00) Belize" },
   { timezone: "America/Chicago", text: "(UTC-06:00) Central Time" },
   {
     timezone: "America/Mexico_City",
     text: "(UTC-06:00) Central Time - Mexico City"
   },
   { timezone: "America/Regina", text: "(UTC-06:00) Central Time - Regina" },
   {
     timezone: "America/Tegucigalpa",
     text: "(UTC-06:00) Central Time - Tegucigalpa"
   },
   {
     timezone: "America/Winnipeg",
     text: "(UTC-06:00) Central Time - Winnipeg"
   },
   { timezone: "America/Costa_Rica", text: "(UTC-06:00) Costa Rica" },
   { timezone: "America/El_Salvador", text: "(UTC-06:00) El Salvador" },
   { timezone: "Pacific/Galapagos", text: "(UTC-06:00) Galapagos" },
   { timezone: "America/Guatemala", text: "(UTC-06:00) Guatemala" },
   { timezone: "America/Managua", text: "(UTC-06:00) Managua" },
   { timezone: "America/Cancun", text: "(UTC-05:00) America Cancun" },
   { timezone: "America/Bogota", text: "(UTC-05:00) Bogota" },
   { timezone: "Pacific/Easter", text: "(UTC-05:00) Easter Island" },
   { timezone: "America/New_York", text: "(UTC-05:00) Eastern Time" },
   { timezone: "America/Iqaluit", text: "(UTC-05:00) Eastern Time - Iqaluit" },
   { timezone: "America/Toronto", text: "(UTC-05:00) Eastern Time - Toronto" },
   { timezone: "America/Guayaquil", text: "(UTC-05:00) Guayaquil" },
   { timezone: "America/Havana", text: "(UTC-05:00) Havana" },
   { timezone: "America/Jamaica", text: "(UTC-05:00) Jamaica" },
   { timezone: "America/Lima", text: "(UTC-05:00) Lima" },
   { timezone: "America/Nassau", text: "(UTC-05:00) Nassau" },
   { timezone: "America/Panama", text: "(UTC-05:00) Panama" },
   { timezone: "America/Port-au-Prince", text: "(UTC-05:00) Port-au-Prince" },
   { timezone: "America/Rio_Branco", text: "(UTC-05:00) Rio Branco" },
   {
     timezone: "America/Halifax",
     text: "(UTC-04:00) Atlantic Time - Halifax"
   },
   { timezone: "America/Barbados", text: "(UTC-04:00) Barbados" },
   { timezone: "Atlantic/Bermuda", text: "(UTC-04:00) Bermuda" },
   { timezone: "America/Boa_Vista", text: "(UTC-04:00) Boa Vista" },
   { timezone: "America/Caracas", text: "(UTC-04:00) Caracas" },
   { timezone: "America/Curacao", text: "(UTC-04:00) Curacao" },
   { timezone: "America/Grand_Turk", text: "(UTC-04:00) Grand Turk" },
   { timezone: "America/Guyana", text: "(UTC-04:00) Guyana" },
   { timezone: "America/La_Paz", text: "(UTC-04:00) La Paz" },
   { timezone: "America/Manaus", text: "(UTC-04:00) Manaus" },
   { timezone: "America/Martinique", text: "(UTC-04:00) Martinique" },
   { timezone: "America/Port_of_Spain", text: "(UTC-04:00) Port of Spain" },
   { timezone: "America/Porto_Velho", text: "(UTC-04:00) Porto Velho" },
   { timezone: "America/Puerto_Rico", text: "(UTC-04:00) Puerto Rico" },
   { timezone: "America/Santo_Domingo", text: "(UTC-04:00) Santo Domingo" },
   { timezone: "America/Thule", text: "(UTC-04:00) Thule" },
   {
     timezone: "America/St_Johns",
     text: "(UTC-03:30) Newfoundland Time - St. Johns"
   },
   { timezone: "America/Araguaina", text: "(UTC-03:00) Araguaina" },
   { timezone: "America/Asuncion", text: "(UTC-03:00) Asuncion" },
   { timezone: "America/Belem", text: "(UTC-03:00) Belem" },
   {
     timezone: "America/Argentina/Buenos_Aires",
     text: "(UTC-03:00) Buenos Aires"
   },
   { timezone: "America/Campo_Grande", text: "(UTC-03:00) Campo Grande" },
   { timezone: "America/Cayenne", text: "(UTC-03:00) Cayenne" },
   { timezone: "America/Cuiaba", text: "(UTC-03:00) Cuiaba" },
   { timezone: "America/Fortaleza", text: "(UTC-03:00) Fortaleza" },
   { timezone: "America/Godthab", text: "(UTC-03:00) Godthab" },
   { timezone: "America/Maceio", text: "(UTC-03:00) Maceio" },
   { timezone: "America/Miquelon", text: "(UTC-03:00) Miquelon" },
   { timezone: "America/Montevideo", text: "(UTC-03:00) Montevideo" },
   { timezone: "Antarctica/Palmer", text: "(UTC-03:00) Palmer" },
   { timezone: "America/Paramaribo", text: "(UTC-03:00) Paramaribo" },
   { timezone: "America/Punta_Arenas", text: "(UTC-03:00) Punta Arenas" },
   { timezone: "America/Recife", text: "(UTC-03:00) Recife" },
   { timezone: "Antarctica/Rothera", text: "(UTC-03:00) Rothera" },
   { timezone: "America/Bahia", text: "(UTC-03:00) Salvador" },
   { timezone: "America/Santiago", text: "(UTC-03:00) Santiago" },
   { timezone: "Atlantic/Stanley", text: "(UTC-03:00) Stanley" },
   { timezone: "America/Noronha", text: "(UTC-02:00) Noronha" },
   { timezone: "America/Sao_Paulo", text: "(UTC-02:00) Sao Paulo" },
   { timezone: "Atlantic/South_Georgia", text: "(UTC-02:00) South Georgia" },
   { timezone: "Atlantic/Azores", text: "(UTC-01:00) Azores" },
   { timezone: "Atlantic/Cape_Verde", text: "(UTC-01:00) Cape Verde" },
   { timezone: "America/Scoresbysund", text: "(UTC-01:00) Scoresbysund" },
   { timezone: "Africa/Abidjan", text: "(UTC+00:00) Abidjan" },
   { timezone: "Africa/Accra", text: "(UTC+00:00) Accra" },
   { timezone: "Africa/Bissau", text: "(UTC+00:00) Bissau" },
   { timezone: "Atlantic/Canary", text: "(UTC+00:00) Canary Islands" },
   { timezone: "Africa/Casablanca", text: "(UTC+00:00) Casablanca" },
   { timezone: "America/Danmarkshavn", text: "(UTC+00:00) Danmarkshavn" },
   { timezone: "Europe/Dublin", text: "(UTC+00:00) Dublin" },
   { timezone: "Africa/El_Aaiun", text: "(UTC+00:00) El Aaiun" },
   { timezone: "Atlantic/Faroe", text: "(UTC+00:00) Faeroe" },
   { timezone: "Etc/UTC", text: "(UTC+00:00) UTC (no daylight saving)" },
   { timezone: "Europe/Lisbon", text: "(UTC+00:00) Lisbon" },
   { timezone: "Europe/London", text: "(UTC+00:00) London" },
   { timezone: "Africa/Monrovia", text: "(UTC+00:00) Monrovia" },
   { timezone: "Atlantic/Reykjavik", text: "(UTC+00:00) Reykjavik" },
   { timezone: "UTC", text: "UTC" },
   { timezone: "Africa/Algiers", text: "(UTC+01:00) Algiers" },
   { timezone: "Europe/Amsterdam", text: "(UTC+01:00) Amsterdam" },
   { timezone: "Europe/Andorra", text: "(UTC+01:00) Andorra" },
   { timezone: "Europe/Berlin", text: "(UTC+01:00) Berlin" },
   { timezone: "Europe/Brussels", text: "(UTC+01:00) Brussels" },
   { timezone: "Europe/Budapest", text: "(UTC+01:00) Budapest" },
   {
     timezone: "Europe/Belgrade",
     text: "(UTC+01:00) Central European Time - Belgrade"
   },
   {
     timezone: "Europe/Prague",
     text: "(UTC+01:00) Central European Time - Prague"
   },
   { timezone: "Africa/Ceuta", text: "(UTC+01:00) Ceuta" },
   { timezone: "Europe/Copenhagen", text: "(UTC+01:00) Copenhagen" },
   { timezone: "Europe/Gibraltar", text: "(UTC+01:00) Gibraltar" },
   { timezone: "Africa/Lagos", text: "(UTC+01:00) Lagos" },
   { timezone: "Europe/Luxembourg", text: "(UTC+01:00) Luxembourg" },
   { timezone: "Europe/Madrid", text: "(UTC+01:00) Madrid" },
   { timezone: "Europe/Malta", text: "(UTC+01:00) Malta" },
   { timezone: "Europe/Monaco", text: "(UTC+01:00) Monaco" },
   { timezone: "Africa/Ndjamena", text: "(UTC+01:00) Ndjamena" },
   { timezone: "Europe/Oslo", text: "(UTC+01:00) Oslo" },
   { timezone: "Europe/Paris", text: "(UTC+01:00) Paris" },
   { timezone: "Europe/Rome", text: "(UTC+01:00) Rome" },
   { timezone: "Europe/Stockholm", text: "(UTC+01:00) Stockholm" },
   { timezone: "Europe/Tirane", text: "(UTC+01:00) Tirane" },
   { timezone: "Africa/Tunis", text: "(UTC+01:00) Tunis" },
   { timezone: "Europe/Vienna", text: "(UTC+01:00) Vienna" },
   { timezone: "Europe/Warsaw", text: "(UTC+01:00) Warsaw" },
   { timezone: "Europe/Zurich", text: "(UTC+01:00) Zurich" },
   { timezone: "Asia/Amman", text: "(UTC+02:00) Amman" },
   { timezone: "Europe/Athens", text: "(UTC+02:00) Athens" },
   { timezone: "Asia/Beirut", text: "(UTC+02:00) Beirut" },
   { timezone: "Europe/Bucharest", text: "(UTC+02:00) Bucharest" },
   { timezone: "Africa/Cairo", text: "(UTC+02:00) Cairo" },
   { timezone: "Europe/Chisinau", text: "(UTC+02:00) Chisinau" },
   { timezone: "Asia/Damascus", text: "(UTC+02:00) Damascus" },
   { timezone: "Asia/Gaza", text: "(UTC+02:00) Gaza" },
   { timezone: "Europe/Helsinki", text: "(UTC+02:00) Helsinki" },
   { timezone: "Asia/Jerusalem", text: "(UTC+02:00) Jerusalem" },
   { timezone: "Africa/Johannesburg", text: "(UTC+02:00) Johannesburg" },
   { timezone: "Africa/Khartoum", text: "(UTC+02:00) Khartoum" },
   { timezone: "Europe/Kiev", text: "(UTC+02:00) Kiev" },
   { timezone: "Africa/Maputo", text: "(UTC+02:00) Maputo" },
   {
     timezone: "Europe/Kaliningrad",
     text: "(UTC+02:00) Moscow-01 - Kaliningrad"
   },
   { timezone: "Asia/Nicosia", text: "(UTC+02:00) Nicosia" },
   { timezone: "Europe/Riga", text: "(UTC+02:00) Riga" },
   { timezone: "Europe/Sofia", text: "(UTC+02:00) Sofia" },
   { timezone: "Europe/Tallinn", text: "(UTC+02:00) Tallinn" },
   { timezone: "Africa/Tripoli", text: "(UTC+02:00) Tripoli" },
   { timezone: "Europe/Vilnius", text: "(UTC+02:00) Vilnius" },
   { timezone: "Africa/Windhoek", text: "(UTC+02:00) Windhoek" },
   { timezone: "Asia/Baghdad", text: "(UTC+03:00) Baghdad" },
   { timezone: "Europe/Istanbul", text: "(UTC+03:00) Istanbul" },
   { timezone: "Europe/Minsk", text: "(UTC+03:00) Minsk" },
   { timezone: "Europe/Moscow", text: "(UTC+03:00) Moscow+00 - Moscow" },
   { timezone: "Africa/Nairobi", text: "(UTC+03:00) Nairobi" },
   { timezone: "Asia/Qatar", text: "(UTC+03:00) Qatar" },
   { timezone: "Asia/Riyadh", text: "(UTC+03:00) Riyadh" },
   { timezone: "Antarctica/Syowa", text: "(UTC+03:00) Syowa" },
   { timezone: "Asia/Tehran", text: "(UTC+03:30) Tehran" },
   { timezone: "Asia/Baku", text: "(UTC+04:00) Baku" },
   { timezone: "Asia/Dubai", text: "(UTC+04:00) Dubai" },
   { timezone: "Indian/Mahe", text: "(UTC+04:00) Mahe" },
   { timezone: "Indian/Mauritius", text: "(UTC+04:00) Mauritius" },
   { timezone: "Europe/Samara", text: "(UTC+04:00) Moscow+01 - Samara" },
   { timezone: "Indian/Reunion", text: "(UTC+04:00) Reunion" },
   { timezone: "Asia/Tbilisi", text: "(UTC+04:00) Tbilisi" },
   { timezone: "Asia/Yerevan", text: "(UTC+04:00) Yerevan" },
   { timezone: "Asia/Kabul", text: "(UTC+04:30) Kabul" },
   { timezone: "Asia/Aqtau", text: "(UTC+05:00) Aqtau" },
   { timezone: "Asia/Aqtobe", text: "(UTC+05:00) Aqtobe" },
   { timezone: "Asia/Ashgabat", text: "(UTC+05:00) Ashgabat" },
   { timezone: "Asia/Dushanbe", text: "(UTC+05:00) Dushanbe" },
   { timezone: "Asia/Karachi", text: "(UTC+05:00) Karachi" },
   { timezone: "Indian/Kerguelen", text: "(UTC+05:00) Kerguelen" },
   { timezone: "Indian/Maldives", text: "(UTC+05:00) Maldives" },
   { timezone: "Antarctica/Mawson", text: "(UTC+05:00) Mawson" },
   {
     timezone: "Asia/Yekaterinburg",
     text: "(UTC+05:00) Moscow+02 - Yekaterinburg"
   },
   { timezone: "Asia/Tashkent", text: "(UTC+05:00) Tashkent" },
   { timezone: "Asia/Colombo", text: "(UTC+05:30) Colombo" },
   { timezone: "Asia/Kolkata", text: "(UTC+05:30) India Standard Time" },
   { timezone: "Asia/Katmandu", text: "(UTC+05:45) Katmandu" },
   { timezone: "Asia/Almaty", text: "(UTC+06:00) Almaty" },
   { timezone: "Asia/Bishkek", text: "(UTC+06:00) Bishkek" },
   { timezone: "Indian/Chagos", text: "(UTC+06:00) Chagos" },
   { timezone: "Asia/Dhaka", text: "(UTC+06:00) Dhaka" },
   { timezone: "Asia/Omsk", text: "(UTC+06:00) Moscow+03 - Omsk" },
   { timezone: "Asia/Thimphu", text: "(UTC+06:00) Thimphu" },
   { timezone: "Antarctica/Vostok", text: "(UTC+06:00) Vostok" },
   { timezone: "Indian/Cocos", text: "(UTC+06:30) Cocos" },
   { timezone: "Asia/Yangon", text: "(UTC+06:30) Rangoon" },
   { timezone: "Asia/Bangkok", text: "(UTC+07:00) Bangkok" },
   { timezone: "Indian/Christmas", text: "(UTC+07:00) Christmas" },
   { timezone: "Antarctica/Davis", text: "(UTC+07:00) Davis" },
   { timezone: "Asia/Saigon", text: "(UTC+07:00) Hanoi" },
   { timezone: "Asia/Hovd", text: "(UTC+07:00) Hovd" },
   { timezone: "Asia/Jakarta", text: "(UTC+07:00) Jakarta" },
   {
     timezone: "Asia/Krasnoyarsk",
     text: "(UTC+07:00) Moscow+04 - Krasnoyarsk"
   },
   { timezone: "Asia/Brunei", text: "(UTC+08:00) Brunei" },
   { timezone: "Asia/Shanghai", text: "(UTC+08:00) China Time - Beijing" },
   { timezone: "Asia/Choibalsan", text: "(UTC+08:00) Choibalsan" },
   { timezone: "Asia/Hong_Kong", text: "(UTC+08:00) Hong Kong" },
   { timezone: "Asia/Kuala_Lumpur", text: "(UTC+08:00) Kuala Lumpur" },
   { timezone: "Asia/Macau", text: "(UTC+08:00) Macau" },
   { timezone: "Asia/Makassar", text: "(UTC+08:00) Makassar" },
   { timezone: "Asia/Manila", text: "(UTC+08:00) Manila" },
   { timezone: "Asia/Irkutsk", text: "(UTC+08:00) Moscow+05 - Irkutsk" },
   { timezone: "Asia/Singapore", text: "(UTC+08:00) Singapore" },
   { timezone: "Asia/Taipei", text: "(UTC+08:00) Taipei" },
   { timezone: "Asia/Ulaanbaatar", text: "(UTC+08:00) Ulaanbaatar" },
   { timezone: "Australia/Perth", text: "(UTC+08:00) Western Time - Perth" },
   { timezone: "Asia/Pyongyang", text: "(UTC+08:30) Pyongyang" },
   { timezone: "Asia/Dili", text: "(UTC+09:00) Dili" },
   { timezone: "Asia/Jayapura", text: "(UTC+09:00) Jayapura" },
   { timezone: "Asia/Yakutsk", text: "(UTC+09:00) Moscow+06 - Yakutsk" },
   { timezone: "Pacific/Palau", text: "(UTC+09:00) Palau" },
   { timezone: "Asia/Seoul", text: "(UTC+09:00) Seoul" },
   { timezone: "Asia/Tokyo", text: "(UTC+09:00) Tokyo" },
   { timezone: "Australia/Darwin", text: "(UTC+09:30) Central Time - Darwin" },
   {
     timezone: "Antarctica/DumontDUrville",
     text: '(UTC+10:00) Dumont D"Urville'
   },
   {
     timezone: "Australia/Brisbane",
     text: "(UTC+10:00) Eastern Time - Brisbane"
   },
   { timezone: "Pacific/Guam", text: "(UTC+10:00) Guam" },
   {
     timezone: "Asia/Vladivostok",
     text: "(UTC+10:00) Moscow+07 - Vladivostok"
   },
   { timezone: "Pacific/Port_Moresby", text: "(UTC+10:00) Port Moresby" },
   { timezone: "Pacific / Chuuk", text: "(UTC+10:00) Truk" },
   {
     timezone: "Australia/Adelaide",
     text: "(UTC+10:30) Central Time - Adelaide"
   },
   { timezone: "Antarctica/Casey", text: "(UTC+11:00) Casey" },
   { timezone: "Australia/Hobart", text: "(UTC+11:00) Eastern Time - Hobart" },
   {
     timezone: "Australia/Sydney",
     text: "(UTC+11:00) Eastern Time - Melbourne, Sydney"
   },
   { timezone: "Pacific/Efate", text: "(UTC+11:00) Efate" },
   { timezone: "Pacific/Guadalcanal", text: "(UTC+11:00) Guadalcanal" },
   { timezone: "Pacific/Kosrae", text: "(UTC+11:00) Kosrae" },
   { timezone: "Asia/Magadan", text: "(UTC+11:00) Moscow+08 - Magadan" },
   { timezone: "Pacific / Norfolk", text: "(UTC+11:00) Norfolk" },
   { timezone: "Pacific/Noumea", text: "(UTC+11:00) Noumea" },
   { timezone: "Pacific/Pohnpei", text: "(UTC+11:00) Ponape" },
   { timezone: "Pacific/Funafuti", text: "(UTC+12:00) Funafuti" },
   { timezone: "Pacific/Kwajalein", text: "(UTC+12:00) Kwajalein" },
   { timezone: "Pacific/Majuro", text: "(UTC+12:00) Majuro" },
   {
     timezone: "Asia/Kamchatka",
     text: "(UTC+12:00) Moscow+09 - Petropavlovsk - Kamchatskiy"
   },
   { timezone: "Pacific / Nauru", text: "(UTC+12:00) Nauru" },
   { timezone: "Pacific/Tarawa", text: "(UTC+12:00) Tarawa" },
   { timezone: "Pacific/Wake", text: "(UTC+12:00) Wake" },
   { timezone: "Pacific/Wallis", text: "(UTC+12:00) Wallis" },
   { timezone: "Pacific/Auckland", text: "(UTC+13:00) Auckland" },
   { timezone: "Pacific/Enderbury", text: "(UTC+13:00) Enderbury" },
   { timezone: "Pacific/Fakaofo", text: "(UTC+13:00) Fakaofo" },
   { timezone: "Pacific/Fiji", text: "(UTC+13:00) Fiji" },
   { timezone: "Pacific/Tongatapu", text: "(UTC+13:00) Tongatapu" },
   { timezone: "Pacific/Apia", text: "(UTC+14:00) Apia" },
   { timezone: "Pacific/Kiritimati", text: "(UTC+14:00) Kiritimati" }
 ];

// export const timeZonesList: any = [
//    '(UTC-12:00) International Date Line West', '(UTC-11:00) Coordinated Universal Time-11',
//    '(UTC-10:00) Aleutian Islands',
//    '(UTC-10:00) Hawaii', '(UTC-09:30) Marquesas Islands', '(UTC-09:00) Alaska',
//    '(UTC-09:00) Coordinated Universal Time-09',
   
//    '(UTC-08:00) Baja California', '(UTC-08:00) Pacific Time (US & Canada)',
//    '(UTC-08:00) Coordinated Universal Time-08',

//    '(UTC-07:00) Mountain Time (US & Canada)',
//    '(UTC-07:00) Arizona',
//    '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
//    '(UTC-07:00) Mountain Time (US & Canada)',
//    '(UTC-06:00) Central America',
//    '(UTC-06:00) Easter Island',
//    '(UTC-06:00) Central Time (US & Canada)',
//    '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
//    '(UTC-06:00) Saskatchewan',

//    '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
//    '(UTC-05:00) Eastern Time (US & Canada)', '(UTC-05:00) Indiana (East)',
//    '(UTC-05:00) Chetumal',
//    '(UTC-05:00) Haiti',
//    '(UTC-05:00) Havana',
//    '(UTC-05:00) Turks and Caicos',

//    '(UTC-04:00) Caracas', '(UTC-04:00) Asuncion', '(UTC-04:00) Atlantic Time (Canada)',
//    '(UTC-04:00) Cuiaba', '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
//    '(UTC-04:00) Santiago',
   
//    '(UTC-03:30) Newfoundland',
//    '(UTC-03:30) Araguaina',
//    '(UTC-03:00) Brasilia',
//    '(UTC-03:00) Buenos Aires', '(UTC-03:00) Cayenne, Fortaleza', '(UTC-03:00) City of Beunos Aires',
//    '(UTC-03:00) Greenland', '(UTC-03:00) Montevideo', '(UTC-03:00) Salvador', '(UTC-03:00) Punta Arenas',
//    '(UTC-03:00) Saint Pierre and Miquelon',

//    '(UTC-02:00) Coordinated Universal Time-02',
//    '(UTC-01:00) Azores', '(UTC-01:00) Cape Verde Is.',

//    '(UTC) Coordinated Universal Time', '(UTC) Dublin, Edinburgh, Lisbon, London',
//    '(UTC) Monrovia, Reykjavik',
//    '(UTC) Sao Tome',
//    // 'Middle East Standard Time', 'Egypt Standard Time', 'Syria Standard Time',
//    '(UTC+01:00) Casablanca',
//    '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
//    '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris', '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb', '(UTC+01:00) West Central Africa',

//    '(UTC+02:00) Windhoek', 
//    '(UTC+02:00) Athens, Bucharest',
//    '(UTC+02:00) Amman', '(UTC+02:00) Tripoli',
//    '(UTC+02:00) E. Europe', '(UTC+02:00) Harare, Pretoria', '(UTC+02:00) Kaliningrad', '(UTC+02:00) Kharatoum',
//    '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
//    '(UTC+02:00) Beirut', '(UTC+02:00) Cairo', '(UTC+02:00) Chisinau', '(UTC+02:00) Damascus', '(UTC+02:00) Gaza Hebron',
//    '(UTC+02:00) Jerusalem',

   
//    '(UTC+03:00) Istanbul',
//    '(UTC+03:00) Jerusalem',
//    '(UTC+03:00) Baghdad',
//    '(UTC+03:00) Kuwait, Riyadh',
//    '(UTC+03:00) Nairobi',  '(UTC+03:00) Moscow, St. Petersburg, Minsk',

//    '(UTC+03:30) Tehran',

//    '(UTC+04:00) Astrakhan, Ulyanovsk, Saratov', '(UTC+04:00) Abu Dhabi, Muscat',
//    '(UTC+04:00) Baku', '(UTC+04:00) Port Louis',
//    '(UTC+04:00) Tbilisi', '(UTC+04:00) Yerevan', 
//    '(UTC+04:00) Izhevsk, Samara', '(UTC+04:00) Volgograd',

//    '(UTC+04:30) Kabul', 

//    '(UTC+05:00) Ashgabat, Tashkent',
//    '(UTC+05:00) Qyzylorda',
//    '(UTC+05:00) Ekaterinburg',
//    '(UTC+05:00) Islamabad, Karachi',
//     '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
//    '(UTC+05:30) Sri Jayawardenepura',
//     '(UTC+05:45) Kathmandu',

//    '(UTC+06:00) Nur-Sultan (Astana)', '(UTC+06:00) Dhaka', '(UTC+06:00) Omsk',

//    '(UTC+06:30) Yangon (Rangoon)',

//    '(UTC+07:00) Bangkok, Hanoi, Jakarta',
//    '(UTC+07:00) Barnaul, Gorno-Altaysk',
//    '(UTC+07:00) Hovd',
//    '(UTC+07:00) Tomsk',
//    '(UTC+07:00) Novosibirsk',
//    '(UTC+07:00) Krasnosibirsk',

//    '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
//    '(UTC+08:00) Kuala Lumpur, Singapore',
//    '(UTC+08:00) Perth', '(UTC+08:00) Taipei', '(UTC+08:00) Ulaanbaatar', '(UTC+08:00) Irkutsk',
//    '(UTC+08:45) Eucla',

//    '(UTC+09:00) Osaka, Sapporo, Tokyo', '(UTC+09:00) Yakutsk', '(UTC+09:00) Seoul', '(UTC+09:00) Chita', '(UTC+09:00) Pyongyang',

//    '(UTC+09:30) Adelaide', '(UTC+09:30) Darwin',

//    '(UTC+10:00) Brisbane', '(UTC+10:00) Canberra, Melbourne, Sydney', '(UTC+10:00) Guam, Port Moresby', '(UTC+10:00) Hobart',
//    '(UTC+10:00) Vladivostok',

//    '(UTC+10:30) Lord Howe Island',

//    '(UTC+11:00) Solomon Is., New Caledonia',
//    '(UTC+11:00) Bougainville Island', '(UTC+11:00) Chokurdakh', '(UTC+11:00) Magadan', '(UTC+11:00) Norfolk Islands',
//    '(UTC+11:00) Sakhalin',

//    '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
//    '(UTC+12:00) Auckland, Wellington', '(UTC+12:00) Coordinated Universal Time+12', '(UTC+12:00) Fiji',

//    '(UTC+12:45) Chatham Islands',

//    '(UTC+13:00) Coordinated Universal Time+13',
//    '(UTC+13:00) Nukualofa', '(UTC+13:00) Samoa', 
//    '(UTC+14:00) Kirithimati Island'
// ];
