import {
    Component, Injectable, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges,
    ChangeDetectorRef, OnDestroy, AfterViewInit, ElementRef, HostListener
} from '@angular/core';
import { isNil } from 'lodash';
import { TreeviewI18n, TreeviewItem, TreeviewConfig, DropdownTreeviewComponent, TreeviewHelper, TreeviewComponent, DropdownDirective } from 'ngx-treeview';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';
import { CompanyService } from '../../Services/company.service';
import { ApiService } from '../../../Services/api.service';
import { Router, NavigationEnd, RouteConfigLoadEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { AppState } from 'src/app/app.state';
import { FormControl } from '@angular/forms';
//import { Tree } from '@angular/router/src/utils/tree';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
    selector: 'tl-dropdown-treeview-select',
    templateUrl: './dropdown-treeview-select.component.html',
    styleUrls: [
        './dropdown-treeview-select.component.scss'
    ],
    providers: [
        { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
    ]
})
export class DropdownTreeviewSelectComponent implements OnChanges, AfterViewInit {
    @Input() config: TreeviewConfig;
    @Input() items: TreeviewItem[];
    itemData: TreeviewItem[];
    public list: TreeviewItem[] = [];
    //  items: TreeviewItem[] ;
    @Input() value: any;
    @Input() bindParentCompanyID: any;
    @Input() bindParentShipperID: any;
    @Input() disabledControlValue: any;
    @Input() pagelevel: boolean;
    @Input() geoType: any;
    @Input() id?: any;

    //Raju Added
    @Input() parentId: any;
    public currentSelCompany: any;
    @Input() set currentCompany(value) {
        this.removeCompany(value);
        this.currentSelCompany = value;
    };


    @Output() valueChange = new EventEmitter<any>();
    @ViewChild(DropdownTreeviewComponent, { static: true }) dropdownTreeviewComponent: DropdownTreeviewComponent;
    @ViewChild('dropdownRef') dropdownRef;

    isVisible: boolean = true;
    filterText: string;
    FilterPlaceholder = 'search';
    isChanged = false;
    private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;
    totalList: any[];
    searchCompany = new FormControl();
    noLogoURI = 'assets/img/logo/lynxfleet/LYNX-fleet-logo-white.svg';
   
    constructor(private companyService: CompanyService, private _state: AppState, private cdr: ChangeDetectorRef,
        public i18n: TreeviewI18n, private router: Router, private menuService: MenuService
    ) {

        if (sessionStorage.getItem('CompanyTreeDropdownList') !== null) {
            this._state.subscribe('Company.CompanyListChanged', (isCollapsed) => {
                this.updateCompanies();
            });
            if (!this.isChanged) {
                this.updateCompanies();
            }

        }
        // this.items = (this.companyService.itemData)
        //  ? this.companyService.itemData :
        //  this.list ;
        this.config = TreeviewConfig.create({
            hasAllCheckBox: false,
            hasCollapseExpand: false,
            hasFilter: true,
            maxHeight: 500,

        });
        this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
        let tt = this.searchCompany.valueChanges.subscribe((data) => {
            if (this.list && data) {
                this.items = this.list.filter((item) => {
                    return item.children ? this.filterChildren(item, data) : item.text.split('->')[0].toLowerCase().includes(data ? data.toLowerCase() : data)
                })
            } else {
                this.items = this.list;
            }
        });
    }

    filterChildren(item, data) {
        return !item.text.split('->')[0].toLowerCase().includes(data) ?
            (item.children ? item.children.some((tt) => {
                return tt.text.split('->')[0].toLowerCase().includes(data) && !tt.children ? item : this.filterChildren(tt, data)
            }) : item.text.split('->')[0].toLowerCase().includes(data)) : true;

    }

    updateCompanies() {
        const CompanyTreeDropdownList = JSON.parse(sessionStorage.getItem('CompanyTreeDropdownList'));
        const combibedlist = [];
        CompanyTreeDropdownList.forEach(element => {
            element.text = element.text + '->' + element.CompanyLogoUri;
            this.getLogo(element);
            combibedlist.push(new TreeviewItem(element));
        });
        this.list = combibedlist;
        this.items = this.list;
    }


    getLogo(element) {
        if (element.children) {
            element.children.map((child) => {
                child.text = child.text + '->' + child.CompanyLogoUri;
                this.getLogo(child);
            });
        }
    }

    removeCompany(value) {
        if (value) {
            let indexForParent;
            let currentCompanyItem = TreeviewHelper.findItemInList(this.list, value);
            this.list.forEach((company: TreeviewItem, index) => {
                let parentItem = TreeviewHelper.findParent(company, currentCompanyItem);
                if (parentItem) {
                    TreeviewHelper.removeItem(parentItem, currentCompanyItem);
                } else if (currentCompanyItem.value == company.value) {
                    indexForParent = index;
                }
            });
            this.items = this.list;
            indexForParent ? this.items.splice(indexForParent, 1) : null;
            this.cdr.detectChanges();
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        // this.companyService.itemData;
        //    if ( (this.value) && (((this.value) ? this.value : this.value.value) === this.bindParentCompanyID)) {

        const flaged = (this.dropdownTreeviewSelectI18n.selectedItem) ?
            this.dropdownTreeviewSelectI18n.selectedItem.checked : false;
        if (!flaged && this.bindParentCompanyID) {

            if (this.bindParentCompanyID && !this.value && this.pagelevel) {
                this.value = this.bindParentCompanyID;
            } else if (!this.value && !this.pagelevel) {
                this.value = (JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID);
            }
            // if(!this.value  && !(JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID) )
            // {
            //     this.bindParentCompanyID;
            // }
            // (this.value = this.value ? this.value  : (JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID))
            //  this.bindParentCompanyID;
            this.updateSelectedItem();
        } else if (flaged) {
            this.items = (this.companyService.itemData)
                ? this.companyService.itemData :
                this.list;
            this.value = this.value ? this.value : this.bindParentCompanyID;
            //  if ( (this.value) && (((((typeof this.value === 'string') ? this.value : this.value.value)
            //   !== this.bindParentCompanyID) ? this.value : this.value.value) !== this.bindParentCompanyID)) {
            this.valueChange.emit(this.value ? this.value : this.bindParentCompanyID);
            this.updateSelectedItem();
            //  }
            // this.cdr.detectChanges();
        }
    }
    dropdownRefopen() {
        let cmp_dr: any = document.getElementById("cmp_dr");
        cmp_dr.childNodes[0].classList.add('show');
        cmp_dr.childNodes[0].childNodes[1].classList.add('show');
    }
    select(item: TreeviewItem) {
        // if (item.children === undefined) {
        this.selectItem(item);

        // }
    }
    ngAfterViewInit() {
        // this.message = 'all done loading :)'
        // this.cdr.detectChanges();
    }
    private updateSelectedItem() {
        if (!isNil(this.items)) {
            // const selectedItem = TreeviewHelper.findItemInList(this.items, (this.value) ? this.value : this.value.value);
            // const selectedItem = TreeviewHelper.findItemInList(this.items, (this.value) ? this.value : this.value.value);

            const selectedItem = TreeviewHelper.findItemInList(this.items, (typeof this.value === 'string') ?
                this.value : this.value.value);
            if (selectedItem) {
                this.selectItem(selectedItem);
                const currentUrl = this.router.url;
                if (currentUrl !== '/') {
                    this.router.navigate([currentUrl.substr(1)], { state: this.geoType });
                }
            } else {
                // this.selectAll();
            }
        }
    }


    private selectItem(item: TreeviewItem) {
        // this.dropdownTreeviewComponent.dropdownDirective.close();
        // setTimeout(() => {

        //     let dMenu = document.getElementsByClassName("dropdown-menu show")
        //     if (dMenu.length > 0) {
        //         dMenu[0].classList.remove("show");   //remove the class
        //     }
        //     let dropdown = document.getElementsByClassName("dropdown show")
        //     if (dropdown.length > 0) {
        //         dropdown[0].classList.remove("show");   //remove the class
        //     }

        //     let dropdownBtn = document.getElementsByClassName("btn dropdown-toggle btn-outline-secondary");
        //     if (dropdownBtn.length > 0) {
        //         dropdownBtn[0].ariaExpanded = 'false';
        //         dropdownBtn[1].ariaExpanded = 'false';

        //         console.log('ariaExpanded',dropdownBtn[0].ariaExpanded)
        //         // dropdownBtn[0].classList.remove("show");   //remove the class
        //     }
        //     // let element: HTMLElement =  document.querySelector('.dropdown-menu') as HTMLElement;
        //     // element.click();

        // }, 1000);

        let cmp_dr: any = document.getElementById("cmp_dr");
        cmp_dr.childNodes[0].classList.remove('show');
        cmp_dr.childNodes[0].childNodes[1].classList.remove('show');

        if (!this.pagelevel) {
            this.menuService.setCompanyLogo(item);
        }
        if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
            this.dropdownTreeviewSelectI18n.selectedItem = item;
            // if(this.dropdownTreeviewComponent && item){
            //     console.log('this.dropdownTreeviewComponent', this.dropdownTreeviewComponent)
            //    this.dropdownTreeviewComponent.onSelectedChange(item.value);
            // }

            if (!this.value || (((typeof this.value === 'string') ? this.value : this.value.value)
                !== item.value)) {
                this.value = item.value;
                this.valueChange.emit(item);
            } else {
                this.valueChange.emit(item);
            }
        }
    }



    // private selectAll() {
    //     const allItem = this.dropdownTreeviewComponent.treeviewComponent.allItem;
    //     allItem.text = '--Select--';
    //     this.selectItem(allItem);
    // }
}

