
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import {MatDialogModule, MatPaginatorModule, MatTableModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { CompanyTreeDropDownComponent } from './pages/Controls/tree-drop-down/CompanyTreeDropDown.component';
import { DropdownTreeviewSelectComponent } from './pages/Controls/tree-drop-down/dropdown-treeview-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeViewModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { TreeviewModule } from 'ngx-treeview';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { TooltipModule, DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule, NumericTextBoxAllModule, UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule, ListBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { CountryDropdownComponent } from './pages/Controls/country-dropdown/country-dropdown.component';
import { NgxFlagPickerModule } from 'ngx-flag-picker';
import { PhonenumberwithcontrycodeComponent } from './pages/Controls/phonenumberwithcontrycode/phonenumberwithcontrycode.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { PagerModule, GridModule, GridAllModule } from '@syncfusion/ej2-angular-grids';
import { AgGridModule } from 'ag-grid-angular';
import { SharedDialogsModule } from './pages/shared-dialogs/shared-dialogs.module';
import { DatePickerAllModule,TimePickerModule  } from '@syncfusion/ej2-angular-calendars';
import { CheckBoxModule, SwitchAllModule, ChipListModule, ButtonAllModule } from '@syncfusion/ej2-angular-buttons';

import { TRANSLATION_PROVIDERS } from 'src/language-translation';
import { TranslationModule } from 'src/language-translation/translation.module';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { DeviceComponent } from './pages/device/device.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FiltersComponent } from 'src/app/shared/components/filters/filters.component';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    CompanyTreeDropDownComponent,
    DropdownTreeviewSelectComponent,
    BackTopComponent,
    CountryDropdownComponent,
    PhonenumberwithcontrycodeComponent,
    FiltersComponent
  ],
  imports: [
    TranslationModule,
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    TreeViewModule,
    TreeviewModule.forRoot(),
    TooltipModule,
    TextBoxModule,
    DropDownListAllModule,
    NgxFlagPickerModule,
    DialogModule,
    Ng2TelInputModule,
    MatPaginatorModule,
    MatTableModule,
    GridModule,
    AgGridModule.withComponents([]),
    SharedDialogsModule,
    MatDialogModule,
    DatePickerAllModule,
    TabModule,
    NumericTextBoxAllModule,
    CheckBoxModule,
    SwitchAllModule,
    UploaderAllModule,
    GridAllModule,
    ChipListModule,
    ButtonAllModule,
    TimePickerModule,
    ListViewModule,
    ListBoxModule,
    NgxIntlTelInputModule,
    PopoverModule
  ],
  exports: [MatDialogModule,
    CompanyTreeDropDownComponent,
    DropdownTreeviewSelectComponent,
    FormsModule,
    ReactiveFormsModule,
    TreeViewModule,
    TreeviewModule,
    BackTopComponent,
    TooltipModule,
    TextBoxModule,
    DropDownListAllModule,
    CountryDropdownComponent,
    NgxFlagPickerModule,
    DialogModule,
    Ng2TelInputModule,
    MatPaginatorModule,
    MatTableModule,
    PagerModule,
    GridModule,
    AgGridModule,
    SharedDialogsModule,
    MatDialogModule,
    PhonenumberwithcontrycodeComponent,
    TabModule,
    NumericTextBoxAllModule,
    CheckBoxModule,
    SwitchAllModule,
    UploaderAllModule,
    GridAllModule,
    ChipListModule,
    ButtonAllModule,
    DatePickerAllModule,
    TimePickerModule,
    ListViewModule,
    ListBoxModule,
    NgxIntlTelInputModule,
    FiltersComponent
  ],
  providers: [TRANSLATION_PROVIDERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents:[CompanyTreeDropDownComponent]

})
export class SharedModule {
}
