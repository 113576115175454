import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
    PageSettingsModel, ToolbarItems,
    GridComponent, FilterSettingsModel, PdfExportProperties, ExcelExportProperties
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CONFIG } from '../../../Core/config';
import {  TabComponent } from '@syncfusion/ej2-angular-navigations';
import { CustomSyncFusionGridService } from '../../Services/customSyncfusionGrid.service';
import { UserInfoService } from '../../../Services/userInfo.service';
import { UnitConverterService } from '../../../Services/unit-converter.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { LoadingService } from '../../../Services/loading.service';

@Component({
    selector: 'app-reporting-status-popup',
    templateUrl: './reporting-status-popup.component.html',
    styleUrls: ['./reporting-status-popup.component.scss']
})
export class ReportingStatusPopupComponent implements OnInit {
    @Input() ReportingStatusInfo: any;
    @ViewChild('tab', { static: false }) tabObj: TabComponent;
    @ViewChild('assetModelDialog', { static: false }) public assetModelDialog: DialogComponent;
    @ViewChild('DayGrid', { static: false }) public DayGrid: GridComponent;
    @ViewChild('MonthGrid', { static: false }) public MonthGrid: GridComponent;
    @ViewChild('WeekGrid', { static: false }) public WeekGrid: GridComponent;
    @ViewChild('BeyondMonthGrid', { static: false }) public BeyondMonthGrid: GridComponent;
    @Output() reportingDialogClose = new EventEmitter();

    public data: any;
    public reportingStatusCount: number;
    public dayData: Object[] = [];
    public monthData: Object[] = [];
    public weekData: Object[] = [];
    public beyondMonthData: Object[] = [];
    public showContainerId = true;
    public reportingCount = 0;
    public notreporingCount = 0;
    public measurementUnit = 'Metric';
    public tempUnit = 'C';
    public showreportingpopup: Boolean = false;

    public dialogdragging: Boolean = true;
    public dialogResize: Boolean = true;
    public confirmHeader;
    public visible: Boolean = true;
    public hidden: Boolean = true;
    public toolbarOptions: ToolbarItems[] | object;
    public filterOptions: FilterSettingsModel;
    public pageSettings: PageSettingsModel;
    public formatOptions: object;
    public decimalpoint: any;
    public target = '.control-section';
    // public position: object = { X: 600, Y: 200 };
    public animationSettings: Object = { effect: 'None' };
    public confirmCloseIcon: Boolean = true;
    public isModal: Boolean = true;
    public dateFormat: string;


    constructor(public datepipe: DatePipe, public customSyncFusionGridService: CustomSyncFusionGridService, public router: Router,
        private userInfoService: UserInfoService, public UnitConverterService: UnitConverterService
        , private LoadingService: LoadingService, private _dashboardService: DashboardService) {
        this.filterOptions = {
            type: 'Excel'
        };
        this.pageSettings = { pageSizes: ['10','20', '50', '100', '500'],  pageSize: 10 };
        this.dateFormat = this.UnitConverterService.getDateFormat();
        this.formatOptions = { type: 'dateTime', format: this.dateFormat + ' hh:mm:ss a' };
        this.decimalpoint =   'N2' ; // { format: 'N2' };
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
        this.measurementUnit = this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.UnitConversionType
            : ((sessionStorage.getItem('UserInfo')) ? JSON.parse(sessionStorage.getItem('UserInfo')).UnitConversionType : '');

        if (this.measurementUnit == 'Imperial') {
            this.tempUnit = 'F';
        }
    }

    ngOnInit() {
        if (this.ReportingStatusInfo) {
            this.LoadingService.show();
            this._dashboardService.getAssetNotReportingStatusGeofenceDetails(this.ReportingStatusInfo.ReportingStatusData.apiObject).subscribe(response => {
                this.data = response;
                this.filldata();
                this.showreportingpopup = true;
                this.LoadingService.hide();
            });
            // else {
            //   this.data = this.AlarmInfo.alarmData;
            // }
            // this.filldata();
            // this.alarmPopupShowData = true;
        }

    }

    filldata() {
        if (this.data) {
            if (this.data.TodayReportingList) {
                this.data['TodayReportingList'] = this.data.TodayReportingList.map(item => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                      } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                    }
                    if (item.PowerStatus == 1) {
                        item['PowerStatus'] = 'ON';
                    }
                    else if (item.PowerStatus == 0) {
                        item['PowerStatus'] = 'OFF';
                    }
                    else if (item.PowerStatus == null) {
                        item['PowerStatus'] = 'unknown';
                    }
                    return item;
                });
            }
            if (this.data.WeekReportingList) {
                this.data['WeekReportingList'] = this.data.WeekReportingList.map(item => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                    } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                    }
                    if (item.PowerStatus == 1) {
                        item['PowerStatus'] = 'ON';
                    }
                    else if (item.PowerStatus == 0) {
                        item['PowerStatus'] = 'OFF';
                    }
                    else if (item.PowerStatus == null) {
                        item['PowerStatus'] = 'unknown';
                    }
                    return item;
                });
            }
            if (this.data.MonthReportingList) {
                this.data['NotReportingList'] = this.data.MonthReportingList.map(item => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                      } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                      }
                    if (item.PowerStatus == 1) {
                        item['PowerStatus'] = 'ON';
                    }
                    else if (item.PowerStatus == 0) {
                        item['PowerStatus'] = 'OFF';
                    }
                    else if (item.PowerStatus == null) {
                        item['PowerStatus'] = 'unknown';
                    }
                    return item;
                });
            }
            if (this.data.BeforeMonthReportingList) {
                this.data['BeforeMonthReportingList'] = this.data.BeforeMonthReportingList.map(item => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                    } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                    }
                    if (item.PowerStatus == 1) {
                        item['PowerStatus'] = 'ON';
                    }
                    else if (item.PowerStatus == 0) {
                        item['PowerStatus'] = 'OFF';
                    }
                    else if (item.PowerStatus == null) {
                        item['PowerStatus'] = 'unknown';
                    }
                    return item;
                });
            }
            this.data = DataUtil.parse.parseJson(this.data);

        }
        // if (this.data.ReportingStatusData.ReportingList) {
        //     this.reportingCount = this.data.ReportingStatusData.ReportingList.length;
        // }
        // if (this.data.ReportingStatusData.NotReportingList) {
        //     this.notreporingCount = this.data.ReportingStatusData.NotReportingList.length;
        // }

        this.reportingStatusCount = this.data.TodayReportingCount + this.data.WeekReportingCount +
            this.data.MonthReportingCount + this.data.BeforeMonthReportingCount;
        this.dayData = DataUtil.parse.parseJson(this.data.TodayReportingList);
        this.weekData = DataUtil.parse.parseJson(this.data.WeekReportingList);
        this.monthData = DataUtil.parse.parseJson(this.data.MonthReportingList);
        this.beyondMonthData = DataUtil.parse.parseJson(this.data.BeforeMonthReportingList);
        this.confirmHeader = 'Reporting Status ' + '(' + this.reportingStatusCount + ')';
        this.showContainerId = (this.data.fromHistorypage) ? this.data.fromHistorypage : false;
        // this.tabObj.select(this.data.activeTab);
    }

    toolbarClick(args: ClickEventArgs, type): void {
        let checkNum;
        switch (type) {
            case 'day':
                checkNum = this.DayGrid;
                break;
            case 'week':
                checkNum = this.WeekGrid;
                break;
            case 'month':
                checkNum = this.MonthGrid;
                break;
            case 'beyondmonth':
                checkNum = this.BeyondMonthGrid;
                break;
            default:
                break;
        }
        switch (args.item.text) {
            case 'PDF Export': 
            const pdfExportProperties: PdfExportProperties = {
                fileName: 'ReportingStatus.pdf'
            };
            checkNum.pdfExport(pdfExportProperties);
                break;
            case 'Excel Export': 
            const excelExportProperties: ExcelExportProperties = {
                fileName: 'ReportingStatus.xlsx'
            };
            checkNum.excelExport(excelExportProperties);
                break;
            case 'CSV Export': checkNum.csvExport();
                break;
        }
    }

    dataBound(type) {
        if (type === 'day') {
            this.customSyncFusionGridService.showNoRecordstoView(this.DayGrid, this.dayData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.DayGrid.autoFitColumns();
            let toolObjCritical;
            let iscriticalhide;
            toolObjCritical = this.DayGrid.toolbarModule.toolbar;
            iscriticalhide = this.DayGrid.currentViewData.length ? false : true;
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
        }
        if (type === 'week') {
            this.customSyncFusionGridService.showNoRecordstoView(this.WeekGrid, this.weekData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.WeekGrid.autoFitColumns();
            let toolObjCritical;
            let iscriticalhide;
            toolObjCritical = this.WeekGrid.toolbarModule.toolbar;
            iscriticalhide = this.WeekGrid.currentViewData.length ? false : true;
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
        }
        if (type === 'month') {
            this.customSyncFusionGridService.showNoRecordstoView(this.MonthGrid, this.monthData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.MonthGrid.autoFitColumns();
            let toolObjCritical;
            let iscriticalhide;
            toolObjCritical = this.MonthGrid.toolbarModule.toolbar;
            iscriticalhide = this.MonthGrid.currentViewData.length ? false : true;
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
        }
        if (type === 'beyondmonth') {
            this.customSyncFusionGridService.showNoRecordstoView(this.BeyondMonthGrid, this.beyondMonthData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.BeyondMonthGrid.autoFitColumns();
            let toolObjCritical;
            let iscriticalhide;
            toolObjCritical = this.BeyondMonthGrid.toolbarModule.toolbar;
            iscriticalhide = this.BeyondMonthGrid.currentViewData.length ? false : true;
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
        }
    }

    public tabCreated(): void {
        this.tabObj.selectedItem = this.ReportingStatusInfo.activeTab;
    }

    navigateAssetHistory(invNumData, type) {
        let invNum;
        switch (type) {
            case 'day':
                invNum = this.DayGrid.getRowInfo(invNumData.target).rowData;
                break;
            case 'week':
                invNum = this.WeekGrid.getRowInfo(invNumData.target).rowData;
                break;
            case 'month':
                invNum = this.MonthGrid.getRowInfo(invNumData.target).rowData;
                break;
            case 'beyondmonth':
                invNum = this.BeyondMonthGrid.getRowInfo(invNumData.target).rowData;
                break;

            default:
                break;
        }
        const reportingdata = {
            'startdate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
            'endDate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
        };
        const data = { type: 'dashboard', data: reportingdata };
        this.LoadingService.show();
        this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });

    }

    reportingDialogClosed() {
        this.reportingDialogClose.emit(0);
    }
}
