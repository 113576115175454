import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { SaveFilterRequest } from 'src/app/models/save-filters.model';
import { FilterScreenNames, PowerState, TripwiseStatusCode } from 'src/app/constants/save-filters-enum';
import { catchError, finalize, map } from 'rxjs/operators';
import { Fleet } from 'src/app/models/fleet.model';
import { UserInfoService } from 'src/app/Services/userInfo.service';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { ApiService } from 'src/app/Services/api.service';
import { SuccessFailureService } from 'src/app/pages/Services/successFailure.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { UnitConverterService } from 'src/app/Services/unit-converter.service';
import { DataUtil } from '@syncfusion/ej2-data';
import { filterAllowPages, filterRestrictPages } from 'src/assets/data/restrictPages';
import { NavigationEnd, Router } from '@angular/router';
import { FiltersService } from 'src/app/Services/filters.service';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { of } from 'rxjs';

@Component({
  selector: 'tl-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @ViewChild('filtersDataGrid', { static: false }) public filtersDataGrid: GridComponent;
  @ViewChild('pop', { static: false }) pop;
  

  public fleetList: Fleet[];
  public fleetListforFilter: any =[];
  showFleet:boolean = true;
  isSelectedAll: boolean = false;
  isIndeterminated: boolean = false;
  

  public powerStateArray: any =[];
  public tripwiseArray: any =[];
  public criticalAlaramsArray: any =[];
  public nonCriticalAlarmsArray: any =[];
  public toolbarOptions: object[];
  geoItems: any =[];
  selectOptions;
  filtermenuItems: any;
  activeItem: any;
  selectedFilterItem = 'f_fl';
  filtersData;
  filterAllowpagesList = filterAllowPages;
  filterRestrictPagesList = filterRestrictPages;
  isApplyOtherScreens= false;
  filterRestrictpage = false;
  isLoading = false;
  searchOptions: { fields: any[]; operator: string; ignoreCase: boolean; };
  currentScreen: FilterScreenNames;
  filterScreenNames = FilterScreenNames;
  currentCompany: string;
  constructor(private _menuService: MenuService, 
    private userInfoService: UserInfoService, 
    private apiService: ApiService,
    private successFailureService: SuccessFailureService,
    private loadingService: LoadingService,
    public unitConverterService: UnitConverterService,
    public router: Router,
    private filtersService: FiltersService) { }

  ngOnInit(): void {
    this.selectOptions = { persistSelection: true };
    this.powerStateArray = [{ 'value': '<2 Hrs OFF', 'id': 1, 'isSelected':false }, { 'id': 2, 'value': '2-4 Hrs OFF', 'isSelected':false }, { 'id': 3, 'value': '>4 Hrs OFF', 'isSelected':false }, { 'id': 4, 'value': 'ON', 'isSelected':false }, { 'id': 5, 'value': '>2 Hrs NOT REPORTING', 'isSelected':false }];
    this.tripwiseArray = [{ 'tvalue': 'PASS', 'id': 't1', 'isSelected':false }, { 'id': 't2', 'tvalue': 'CHECK', 'isSelected':false }, { 'id': 't3', 'tvalue': 'EXPIRED', 'isSelected':false }, { 'id': 't4', 'tvalue': 'OFF', 'isSelected':false }]
    this.toolbarOptions = [{ text: 'Search', align: 'Left'}];
    this.filtermenuItems = this._menuService.getFilterMenuItems();
    this.activeItem = this.filtermenuItems[0];

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event:NavigationEnd)  => {
      if (this.filterAllowpagesList.some(y => this.router.url.indexOf(y) >= 0)) {
        if (this.filterRestrictPagesList.some(y => this.router.url.indexOf(y) >= 0) ){
         this.filterRestrictpage = false;
       } else {
       this.filterRestrictpage = true;
       }       
   } else {
     this.filterRestrictpage = false;
   }
   if(!this.router.url.includes(this.filterScreenNames[this.currentScreen]?.toLowerCase()) && !this.isApplyOtherScreens) {
    this.clearfilter();
   }
   else{
    this.getFiltersData();
   }
    this.routeData(event);
    });

    this.filtersService.isFiltersCompChanged.subscribe(res => {
      if(res.isCompChanged) {
        this.currentCompany = res.currentCompany;
        this.getFleet(res.currentCompany);
      }
    }); 
  }

  routeData(e) {
    // If it is a NavigationEnd event re-initalise the component
    // this.pop.hide();
    if(this?.pop) {
      this.pop.hide();
    }
  }

  itemClick(event) {
    this.activeItem = event;
    this.selectedFilterItem = event.id;
    this.isAllSelected(this.selectedFilterItem);
  }

  isAllSelected(type) {
    if (type == 'f_fl') {
      let numSelected = this.fleetListforFilter?.filter(item => item.isSelected == true) || []
      const numRows = this.fleetListforFilter?.length || 0;
      this.isSelectedAll = false;
      if (numSelected.length === 0) {
        this.isIndeterminated = false;
      } else if (numSelected.length === numRows) {
        this.isIndeterminated = false;
        this.isSelectedAll = true;
      } else {
        this.isIndeterminated = true;
      }
    }else if(type == 'f_Geo'){
      let numSelected = this.geoItems?.filter(item => item.isSelected == true) || []
      const numRows =this.geoItems?.length || 0;
      this.isSelectedAll = false;
      if (numSelected.length === 0) {
        this.isIndeterminated = false;
      } else if (numSelected.length === numRows) {
        this.isIndeterminated = false;
        this.isSelectedAll = true;
      } else {
        this.isIndeterminated = true;
      }
    }else if(type == 'f_pwr'){
      let numSelected = this.powerStateArray?.filter(item => item.isSelected == true) || []
      const numRows = this.powerStateArray?.length || 0;
      this.isSelectedAll = false;
      if (numSelected.length === 0) {
        this.isIndeterminated = false;
      } else if (numSelected.length === numRows) {
        this.isIndeterminated = false;
        this.isSelectedAll = true;
      } else {
        this.isIndeterminated = true;
      }
    }else if(type == 'f_tri'){
      let numSelected = this.tripwiseArray?.filter((item:any) => item.isSelected == true) || []
      const numRows = this.tripwiseArray?.length || 0;
      this.isSelectedAll = false;
      if (numSelected.length === 0) {
        this.isIndeterminated = false;   
      } else if (numSelected.length === numRows) {
        this.isIndeterminated = false;
        this.isSelectedAll = true;
      } else {
        this.isIndeterminated = true;
      }
    }
    else if(type == 'f_ca'){
      let numSelected = this.criticalAlaramsArray?.filter((item:any) => item.isSelected == true) || []
      const numRows = this.criticalAlaramsArray?.length || 0;
      this.isSelectedAll = false;
      if (numSelected.length === 0) {
        this.isIndeterminated = false;
      } else if (numSelected.length === numRows) {
        this.isIndeterminated = false;
        this.isSelectedAll = true;
      } else {
        this.isIndeterminated = true;
      }
    }
    else if(type == 'f_nca'){
      let numSelected = this.nonCriticalAlarmsArray?.filter((item:any) => item.isSelected == true) || []
      const numRows = this.nonCriticalAlarmsArray?.length || 0;
      this.isSelectedAll = false;
      if (numSelected.length === 0) {
        this.isIndeterminated = false;
      } else if (numSelected.length === numRows) {
        this.isIndeterminated = false;
        this.isSelectedAll = true;
      } else {
        this.isIndeterminated = true;
      }
    }
  }

  filterToggle(event) {
    if (this.selectedFilterItem == 'f_fl') {
      setTimeout(() => {
        this.isSelectedAll = event.target.checked;
        this.fleetListforFilter.map((item: any) => {
          return item.isSelected = event.target.checked;
        })
        this.isAllSelected('f_fl');
      }, 0);
      this.filtersDataGrid.refresh()
    } else if (this.selectedFilterItem == 'f_Geo') {
      setTimeout(() => {
        this.isSelectedAll = event.target.checked;
        this.geoItems.map((item: any) => {
          return item.isSelected = event.target.checked;
        })
        this.isAllSelected('f_Geo');
      }, 0);
      this.filtersDataGrid.refresh()
    }else if (this.selectedFilterItem == 'f_pwr') {
      setTimeout(() => {
        this.isSelectedAll = event.target.checked;
        this.powerStateArray.map((item: any) => {
          return item.isSelected = event.target.checked;
        })
        this.isAllSelected('f_pwr');
      }, 0);
      this.filtersDataGrid.refresh()
    }
    else if (this.selectedFilterItem == 'f_tri') {
      setTimeout(() => {
        this.isSelectedAll = event.target.checked;
        this.tripwiseArray.map((item: any) => {
          return item.isSelected = event.target.checked;
        })
        this.isAllSelected('f_tri');
      }, 0);
      this.filtersDataGrid.refresh()
    }
    else if (this.selectedFilterItem == 'f_ca') {
      setTimeout(() => {
        this.isSelectedAll = event.target.checked;
        this.criticalAlaramsArray.map((item: any) => {
          return item.isSelected = event.target.checked;
        })
        this.isAllSelected('f_ca');
      }, 0);
      this.filtersDataGrid.refresh()
    }
    else if (this.selectedFilterItem == 'f_nca') {
      setTimeout(() => {
        this.isSelectedAll = event.target.checked;
        this.nonCriticalAlarmsArray.map((item: any) => {
          return item.isSelected = event.target.checked;
        })
        this.isAllSelected('f_nca');
      }, 0);
      this.filtersDataGrid.refresh()
    }

  }

  rowSelection(event, object) {
    if(this.selectedFilterItem == 'f_fl'){
      this.fleetListforFilter.find(item => item.FleetID == object.FleetID).isSelected = event.checked;
    }else if(this.selectedFilterItem == 'f_Geo'){
      this.geoItems.find(item => item.value == object.value).isSelected = event.checked;
    }else if(this.selectedFilterItem == 'f_pwr'){
      this.powerStateArray.find(item => item.id == object.id).isSelected = event.checked;
    }else if(this.selectedFilterItem == 'f_tri'){
      this.tripwiseArray.find((item:any) => item.id == object.id).isSelected = event.checked;
    }
    else if(this.selectedFilterItem == 'f_ca'){
      this.criticalAlaramsArray.find((item:any) => item.alarmID == object.alarmID).isSelected = event.checked;
    }
    else if(this.selectedFilterItem == 'f_nca'){
      this.nonCriticalAlarmsArray.find((item:any) => item.alarmID == object.alarmID).isSelected = event.checked;
    }
    
    this.isAllSelected(this.selectedFilterItem)
  }

  clearfilter() {
    this.fleetListforFilter?.map(item=>item.isSelected = false);
    this.geoItems?.map(item=>item.isSelected = false)
    this.powerStateArray?.map(item=>item.isSelected = false)
    this.tripwiseArray?.map(item=>item.isSelected = false)
    this.criticalAlaramsArray?.map(item=>item.isSelected = false)
    this.nonCriticalAlarmsArray?.map(item=>item.isSelected = false)
    this.filtersDataGrid?.refresh();
    this.isApplyOtherScreens = false;

    this.isSelectedAll = false;
    this.isIndeterminated = false;
  }

  onClearFilters() {
    this.clearfilter();
    this.onApplyFilters();
  }

  getFiltersData(companyId?) {
    const companyid = companyId ? companyId : this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.CompanyID
      : ((sessionStorage.getItem('UserInfo')) ? JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID : '');
    this.apiService.getFiltersDetails(companyid).
    pipe(
      map((response: any) => {
        this.filtersData = response?.filtersData;
        if(this.filtersData) {
          this.setSelectedFilterOptions();
        }
        else {
          this.clearfilter();
        }
      if(!this.router.url.includes(this.filterScreenNames[this.currentScreen]?.toLowerCase()) && !this.isApplyOtherScreens) {
        this.clearfilter();
       }
      }),
      catchError(() => {
        this.clearfilter();
        return of([]);
      }),
      finalize(() => {
        this.loadingService.hide();
      })
    ).
    subscribe();
  }
  setSelectedFilterOptions () {
    
    if(this.filtersData.configuredFilters.fleets) {
      this.fleetListforFilter?.map((fleet)=> {
        fleet.isSelected = false;
        if(this.filtersData.configuredFilters.fleets.find((fleetID) => fleetID === fleet['FleetID'])) {
             fleet.isSelected = true;
        }   
    });
    }
    
  if(this.filtersData.configuredFilters.geofences) {
    this.geoItems.map((geofence)=> {
      geofence.isSelected = false;
      if(this.filtersData.configuredFilters.geofences.find((geofenceID) => geofenceID === geofence['value'])) {
        geofence.isSelected = true;
      }   
    });
  }
  this.powerStateArray.map((powerState)=> {
    powerState.isSelected = false;
    if(this.filtersData.configuredFilters.powerState.find((powerStateID) => powerStateID === PowerState[powerState.value])) {
      powerState.isSelected = true;
    }  
    else if(this.filtersData.configuredFilters.powerState.find((powerStateID) => powerStateID === PowerState[powerState.value]) === 0) {
      powerState.isSelected = true;
    } 
  });
  this.tripwiseArray.map((tripWise)=> {
    tripWise.isSelected = false;
    if(this.filtersData.configuredFilters.tripwiseStatus.find((tripWiseID) => tripWiseID == TripwiseStatusCode[tripWise.tvalue])) {
      tripWise.isSelected = true;
    }
    else if(this.filtersData.configuredFilters.tripwiseStatus.find((tripWiseID) => tripWiseID == TripwiseStatusCode[tripWise.tvalue]) === 0) {
      tripWise.isSelected = true;
    }
  });
  this.criticalAlaramsArray.map((criticalAlarm)=> {
    criticalAlarm.isSelected = false;
    if(this.filtersData.configuredFilters.criticalAlarms.find((criticalAlarmID) => criticalAlarmID === criticalAlarm.alarmName)) {
      criticalAlarm.isSelected = true;
    }   
  });
  this.nonCriticalAlarmsArray.map((nonCriticalAlarm)=> {
    nonCriticalAlarm.isSelected = false;
    if(this.filtersData.configuredFilters.nonCriticalAlarms.find((nonCriticalAlarmID) => nonCriticalAlarmID === nonCriticalAlarm.alarmName)) {
      nonCriticalAlarm.isSelected = true;
    }   
  });
  if(this.filtersData.configuredFilters.fleets) {
  this.filtersData.configuredFilters.fleets.map((fleetID) => {
    const element = this.fleetListforFilter.find((fleet) => fleetID === fleet['FleetID']);
     const index = this.fleetListforFilter.findIndex((fleet) => fleetID === fleet['FleetID']);
     if(element) {
      this.fleetListforFilter.splice(index, 1);
      this.fleetListforFilter.unshift(element);
     }
  });
}
  if(this.filtersData.configuredFilters.geofences) {
  this.filtersData.configuredFilters.geofences.map((geofenceID) => {
    const element = this.geoItems.find((geofence) => geofenceID === geofence['value']);
     const index = this.geoItems.findIndex((geofence) => geofenceID === geofence['value']);
     if(element) {
     this.geoItems.splice(index, 1);
     this.geoItems.unshift(element);
     }
  });
  }
  if(this.filtersData.configuredFilters.tripwiseStatus) {
  this.filtersData.configuredFilters.tripwiseStatus.map((tripwiseStatus) => {
    const element = this.tripwiseArray.find((tripwiseStatusId) => tripwiseStatus === TripwiseStatusCode[tripwiseStatusId.tvalue]);
     const index = this.tripwiseArray.findIndex((tripwiseStatusId) => tripwiseStatus === TripwiseStatusCode[tripwiseStatusId.tvalue]);
     if(element) {
     this.tripwiseArray.splice(index, 1);
     this.tripwiseArray.unshift(element);
     }
  });
}
if(this.filtersData.configuredFilters.powerState) {
  this.filtersData.configuredFilters.powerState.map((powerStateID) => {
    const element = this.powerStateArray.find((powerState) => powerStateID === PowerState[powerState.value]);
     const index = this.powerStateArray.findIndex((powerState) => powerStateID === PowerState[powerState.value]);
     if(element) {
     this.powerStateArray.splice(index, 1);
     this.powerStateArray.unshift(element);
     }
  });
}
if(this.filtersData.configuredFilters.criticalAlarms) {
  this.filtersData.configuredFilters.criticalAlarms.map((criticalAlarmId) => {
    const element = this.criticalAlaramsArray.find((criticalAlarm) => criticalAlarmId === criticalAlarm.alarmName);
     const index = this.criticalAlaramsArray.findIndex((criticalAlarm) => criticalAlarmId === criticalAlarm.alarmName);
     if(element) {
     this.criticalAlaramsArray.splice(index, 1);
     this.criticalAlaramsArray.unshift(element);
     }
  });
}
if(this.filtersData.configuredFilters.nonCriticalAlarms) {
  this.filtersData.configuredFilters.nonCriticalAlarms.map((noncriticalAlarmId) => {
    const element = this.nonCriticalAlarmsArray.find((noncriticalAlarm) => noncriticalAlarmId === noncriticalAlarm.alarmName);
     const index = this.nonCriticalAlarmsArray.findIndex((noncriticalAlarm) => noncriticalAlarmId === noncriticalAlarm.alarmName);
     if(element) {
     this.nonCriticalAlarmsArray.splice(index, 1);
     this.nonCriticalAlarmsArray.unshift(element);
     }
  });
}
  this.isApplyOtherScreens = this.filtersData.isOtherScreenEnabled;
  this.currentScreen = this.filtersData.currentScreen;
  }

  getAlarms(companyId?) {
    this.loadingService.show();
    this.apiService.getAlarmsFilters().
    pipe(
      map((response: any) => {
       this.criticalAlaramsArray = response.alarmsList.criticalAlarms;
       this.nonCriticalAlarmsArray = response.alarmsList.nonCriticalAlarms;
      }),
      finalize(() => {
        this.loadingService.hide();
      })
    ).
    subscribe();
  }

  getFleet(companyId?) {
    const CompanyID = companyId ? companyId: this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.CompanyID
      : JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID;
    this.fleetList = [];
    this.apiService.getFleetListByCount(CompanyID).pipe(
      map((fleet: any) => {
        if (this.successFailureService.successFailureReturn(fleet)) {
          this.fleetList = fleet?.Fleets?.map(fleet => {
            return {
              FleetID: fleet?.FleetID,
              FleetName: fleet?.FleetName,
              AssetCount: fleet?.AssetCount,
              UserCount: fleet?.UserCount,
              ModifiedByName: fleet?.ModifiedByName,
              ModifiedOn: this.unitConverterService.convertTimeZone(fleet?.ModifiedOn)
            };
          });
          this.fleetList = DataUtil.parse.parseJson(this.fleetList);
          this.fleetListforFilter = this.fleetList ? this.fleetList : [];
        }
        this.getAlarms();
        this.getGeoFenceList(companyId);
      })
    ).subscribe();
  }

  getGeoFenceList(companyId) {
    this.apiService.getGeofenceList(this.currentCompany).pipe(map((List: any) => {
      if (List && List.geofenceListModel) {
        this.geoItems = List.geofenceListModel.map(item => {
          return {
            value: item.GeofenceID,
            text: item.GeofenceName,
            isSelected: false,
          };
        });
      }
      }
     ), 
     finalize(() => {
      this.getFiltersData(companyId);
     })).subscribe();
  }

 
  onApplyFilters(): void {
    this.isLoading = true;
    const companyid = this.userInfoService?.loggedinUserData ? this.userInfoService.loggedinUserData.CompanyID
      : ((sessionStorage.getItem('UserInfo')) ? JSON.parse(sessionStorage.getItem('UserInfo')).CompanyID : '');
    this.currentScreen = this.getCurrentScreen();
    const filterObj: SaveFilterRequest = {
      companyID: companyid,
      configuredFilters : {
        fleets : this.fleetListforFilter ?  this.fleetListforFilter.filter(fleet => fleet.isSelected == true).map(fleet => fleet.FleetID):[],
        geofences : this.geoItems ? this.geoItems.filter(geofence => geofence.isSelected == true).map(geofence => geofence.value) :[],
        powerState:  this.powerStateArray.filter(item => item.isSelected == true).map(powerState => PowerState[powerState.value]) ,
        tripwiseStatus: this.tripwiseArray.filter(item => item.isSelected == true).map(tripWise => TripwiseStatusCode[tripWise.tvalue]),
        criticalAlarms: this.criticalAlaramsArray.filter(item => item.isSelected == true).map(criticalAlarm => criticalAlarm.alarmName) ,
        nonCriticalAlarms:  this.nonCriticalAlarmsArray.filter(item => item.isSelected == true).map(nonCriticalAlarm => nonCriticalAlarm.alarmName)
      },
      isOtherScreenEnabled: this.isApplyOtherScreens,
      currentScreen: this.currentScreen
    }
    this.loadingService.show();
    this.apiService.saveFiltersDetails(filterObj).
    pipe(
      finalize(() => {
        this.isLoading = false;
        this.loadingService.hide();
        this.pop.hide();
        this.getFiltersData();
        this.filtersService.filterApplied(true);
      })
    ).
    subscribe();
  }

  getCurrentScreen() {
    if(this.router.url.includes("dashboard")) {
      return FilterScreenNames.Dashboard;
    }
    else if(this.router.url.includes("containerstatus")) {
      return FilterScreenNames.ContainerStatus
    }
    else if(this.router.url.includes("devices")) {
      return FilterScreenNames.Devices
    }
    else if(this.router.url.includes("containers")) {
      return FilterScreenNames.Containers
    }
  }

  getDataSourceList() {
    switch(this.selectedFilterItem) {
      case "f_fl":
        return this.fleetListforFilter;
      case "f_Geo":
        return this.geoItems;
      case "f_pwr":
        return this.powerStateArray;
      case "f_tri":
        return this.tripwiseArray;
      case "f_ca":
        return this.criticalAlaramsArray;
      case "f_nca":
        return this.nonCriticalAlarmsArray;
    }
  }

  getFieldNames() {
    switch(this.selectedFilterItem) {
      case "f_fl":
        return "FleetName";
      case "f_Geo":
        return "text";
      case "f_pwr":
        return "value";
      case "f_tri":
        return "tvalue";
      case "f_ca":
        return "alarmName";
      case "f_nca":
        return "alarmName";
    }
  }

  getDataSourceLength() {
    switch(this.selectedFilterItem) {
      case "f_fl":
        return this.fleetListforFilter?.length === 0; 
      case "f_Geo":
        return this.geoItems?.length === 0 ;
      case "f_pwr":
        return this.powerStateArray?.length === 0 ;
      case "f_tri":
        return this.tripwiseArray?.length === 0 ;
      case "f_ca":
        return this.criticalAlaramsArray?.length === 0;
      case "f_nca":
        return this.nonCriticalAlarmsArray?.length === 0 ;
    }
  }

  isFiltersSelected() {
    return this.fleetListforFilter?.some(fleet => fleet?.isSelected == true) ||
    this.geoItems?.some(geofence => geofence?.isSelected == true) ||
    this.powerStateArray?.some(item => item?.isSelected == true) ||
    this.tripwiseArray?.some(item => item?.isSelected == true) ||
    this.criticalAlaramsArray?.some(item => item?.isSelected == true) ||
    this.nonCriticalAlarmsArray?.some(item => item?.isSelected == true)
  }

  public toolbarClick(args: ClickEventArgs): void {
    if (args.item.tooltipText === 'Search') {
      const displayedColumns = [];
      this.filtersDataGrid.columns.forEach((val) => {
        if (val.visible === true) {
          displayedColumns.push(val.field);
        }
      });
      this.searchOptions = { fields: displayedColumns, operator: 'contains', ignoreCase: true };
    }
  }

  public created(args?) {
    var gridElement = this.filtersDataGrid.element;
    var span = document.createElement("span");
    span.className = "e-clear-icon";
    span.id = gridElement.id + "clear";
    span.onclick = this.cancelBtnClick.bind(this);
    gridElement.querySelector(".e-toolbar-item .e-input-group").appendChild(span);
  }

  public cancelBtnClick(args) {
    this.filtersDataGrid.searchSettings.key = "";
    (this.filtersDataGrid.element.querySelector(".e-input-group.e-search .e-input") as any).value = "";
  }

  dataBound(args) {
    var toolbarLeftNodes  = document.getElementsByClassName('e-toolbar-right')[0].childNodes;  
    document.getElementsByClassName('e-toolbar-right')[0].remove(); 
    var length =toolbarLeftNodes.length 

    for(var i=0;i<length;i++){ 
        document.getElementsByClassName('e-toolbar-left')[0].appendChild(toolbarLeftNodes[0]); 
    }
  }

  getSelectedFilters(item) {
    switch(item) {
      case "f_fl":
        return this.fleetListforFilter?.filter(item => item?.isSelected == true).length; 
      case "f_Geo":
        return this.geoItems?.filter(item => item?.isSelected == true).length;
      case "f_pwr":
        return this.powerStateArray?.filter(item => item?.isSelected == true).length ;
      case "f_tri":
        return this.tripwiseArray?.filter(item => item?.isSelected == true).length;
      case "f_ca":
        return this.criticalAlaramsArray?.filter(item => item?.isSelected == true).length;
      case "f_nca":
        return this.nonCriticalAlarmsArray?.filter(item => item?.isSelected == true).length;
    }
  }

  filterPopoverClick(event) {
    event.stopPropagation();
    if(this.pop.isOpen) {
      this.pop.hide()
    } else{
      this.pop.show();
      if(!this.router.url.includes(this.filterScreenNames[this.currentScreen]?.toLowerCase()) && !this.isApplyOtherScreens) {
        this.clearfilter();
       }
       else{
        this.setSelectedFilterOptions();
       }
      this.isAllSelected(this.selectedFilterItem);
    }
  }
}


