import { Injectable } from '@angular/core';
import { ApiService } from '../Services/api.service';
import { gridsettings } from '../../assets/data/GridSettingsID';

@Injectable({providedIn: 'root'})
export class GridStatePreferencesService {
    gridSettingsFetched = false;
    gridGensetSettingsFetched = false;
    constructor(private apiService: ApiService) {
    }
    getStatePersistence() {
        const getGridData = {
            'userID': JSON.parse(sessionStorage.getItem('UserInfo')).UserID,
            'gridID': gridsettings.containerStatus
        };
        this.apiService.getGridPreference(getGridData).subscribe(res => {
            if (res.Status === true ) {
              window.localStorage.setItem(gridsettings.containerStatus , (res.GridSettings));
              window.localStorage.setItem('toolbargrid_1569890792_0_toolbarItems', JSON.stringify({}));
              window.localStorage.setItem('contextmenugrid_1569890792_0_cmenu', '');
            } else {
                // window.localStorage.setItem(gridsettings.containerStatus , '');
                localStorage.removeItem('gridContainerStatus');
                window.localStorage.setItem('toolbargrid_1569890792_0_toolbarItems', JSON.stringify({}));
                window.localStorage.setItem('contextmenugrid_1569890792_0_cmenu', '');
            }
            this.gridSettingsFetched = true; 
        });
    }

    getGensetStatePersistence() {
        const getGridData = {
            'userID': JSON.parse(sessionStorage.getItem('UserInfo')).UserID,
            'gridID': 'gensetStatusID'
        };
        this.apiService.getGridPreference(getGridData).subscribe(res => {
            if (res.Status === true ) {
              window.localStorage.setItem('gridGensetStatus', (res.GridSettings));
            //  window.localStorage.setItem('toolbargrid_1569890792_0_toolbarItems', JSON.stringify({}));
            //  window.localStorage.setItem('contextmenugrid_1569890792_0_cmenu', '');
            } else {
                // window.localStorage.setItem(gridsettings.containerStatus , '');
                localStorage.removeItem('gridGensetStatus');
               // window.localStorage.setItem('toolbargrid_1569890792_0_toolbarItems', JSON.stringify({}));
               // window.localStorage.setItem('contextmenugrid_1569890792_0_cmenu', '');
            }
            this.gridGensetSettingsFetched = true; 
        });
    }

    getGensetGridSettingsFetchedStatus() {
        return this.gridGensetSettingsFetched;
    }

    getGridSettingsFetchedStatus() {
        return this.gridSettingsFetched;
    }
}

