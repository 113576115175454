import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CONFIG } from '../Core/config';
import { ServerService } from '../Core/server.service';
@Injectable()
export class ApiService {
    constructor(private serverService: ServerService, private http: HttpClient) {
    }
    SupportedOptions = [];
    getTenants() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.tenants
        }) || of('');
    }

   
    getfleetnames(companyid) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getFleet, encodeURIComponent(companyid))
        }) || of('');
    }
    getGeofences(companyid) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getGeofences, encodeURIComponent(companyid))
        }) || of('');
    }
    getPermissions() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.getPermissions
        }) || of('');
    }
    getUserPermissions(userId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getUserPermissions, encodeURIComponent(userId))
        }) || of('');
    }
    saveRolewithPermission(rolePermission) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.rolePermissionAdd
        }, rolePermission) || of('');
    }
    updateRolewithPermission(rolePermission) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.rolePermissionUpdate
        }, rolePermission) || of('');
    }
    addTenant(tenant) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.tenantAdd
        }, tenant) || of('');
    }
    saveGridPreference(gridData) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.gridAdd
        }, gridData) || of('');
    }
    resetGridPreference(gridData) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.gridReset
        }, gridData) || of('');
    }
    deleteGridPreference(gridData) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.gridDelete
        }, gridData) || of('');
    }
    getGridPreference(gridData) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.gridGet
        }, gridData) || of('');
    }
    addCompany(company) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.companyAdd
        }, company) || of('');
    }
    addCargoOwner(cargoowner) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.cargoOwnerAdd
        }, cargoowner) || of('');
    }
    updateCompany(company) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.companyUpdate
        }, company) || of('');
    }
    updateCargoOwner(cargoOwner) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.cargoOwnerUpdate
        }, cargoOwner) || of('');
    }
    changeInputPassword(input) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.changePassword
        }, input) || of('');
    }
    addTwoWayList(twoway) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.twoWayAdd
        }, twoway) || of('');
    }

    addCommandsforContainers(twoway) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.commandsforMultipleContainers
        }, twoway) || of('');
    }

    getAssetCommandsList(model) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getAssetCommands
        }, model) || of('');
    }

    getContainersCommandsList(model) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getContainerCommands
        }, model) || of('');
    }

    gettenantsbyUserID(userid) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getalltenantsbyUserID, encodeURIComponent(userid))
        }) || of('');
    }
    getUsersbyTenantID(tenantid) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getallusersbyTenantID, encodeURIComponent(tenantid))
        }) || of('');
    }
    getUsers() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.getusersList
        }) || of('');
    }

    getUsersbasedonCompany(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getusersListbyCompany, encodeURIComponent(companyID))
        }) || of('');
    }
    deleteUser(userid) {
        return this.serverService.postQuery<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.deleteUserbyID, encodeURIComponent(userid))
        }) || of('');
    }

    getRolesbyCompanyID(companyid) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getRolesListbyCompanyID, encodeURIComponent(companyid))
        }) || of('');
    }

    deleteRole(roleid) {
        return this.serverService.postQuery<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.removeRole, encodeURIComponent(roleid))
        }) || of('');
    }

    getPermissionsbyRoleID(roleID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getPermissionsbyRoleID, encodeURIComponent(roleID))
        }) || of('');
    }
    addTenantUser(tenant: string, user) {
        return this.serverService.postAndQuery<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.tenantAddUser, encodeURIComponent(tenant))
        }, user) || of('');
    }

    removeTenantUser(tenant: string, user: string) {
        return this.serverService.postQuery<any>({
            api: CONFIG.api.user,
            //   url: this.fillParam(CONFIG.url.tenantRemoveUser, encodeURIComponent(tenant))
            url: this.fillParam(this.fillParam(CONFIG.url.tenantRemoveUser, tenant), encodeURIComponent(user))
        }) || of('');
    }

    updateUser(User) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.userUpdate
        }, User) || of('');
    }
    updateUserPreferences(User) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.userUpdatePreferences
        }, User) || of('');
    }

    updateUserEulaDate(User) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.updateEulaDate
        }, User) || of('');
    }

    getVersionsHistory() {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.getVerions
        }) || of('');
    }

    getAssetList(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getAssetList, encodeURIComponent(companyID))
        }) || of('');
    }
    getTripsList(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getTripsList, encodeURIComponent(companyID))
        }) || of('');
    }
    getTripsDetailsByTripID(tripId) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getTripDetailsByID, encodeURIComponent(tripId))
        }) || of('');
    }
    submitTripsDetails(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.submitTrip
        }, reqObj) || of('');
    }
    submitBulkAssociation(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.bulkassociation
        }, reqObj) || of('');
    }
    submitBulkDisassociation(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.bulkdisassociation
        }, reqObj) || of('');
    }
    


    updateTripDetails(reqObj, tripId) {
        return this.serverService.put<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.updateTrip, encodeURIComponent(tripId))
        }, reqObj) || of('');
    }

    deleteTrip(tripIds) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.deleteTrip
        }, tripIds) || of('');
    }
    getCumulativeHours(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getcumulativehours
        }, reqObj) || of('');
    }
    getISOCompanyDataAPI(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getISOCompanyData
        }, reqObj) || of('');
    }
    getTwoWayHistory(assetID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getTwowayHistory, encodeURIComponent(assetID))
        }) || of('');
    }
    getAllAssets(companyID, cargoOwnerID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(this.fillParam(CONFIG.url.getAllAssetList, companyID), encodeURIComponent(cargoOwnerID))
        }) || of('');
    }
    getAllSharableAssets(companyID, cargoOwnerID, startdate, enddate) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getAllSharableAssetList, companyID),
                encodeURIComponent(cargoOwnerID)), startdate), enddate)
        }) || of('');
    }
    ShareAssetstoCargoOwner(cargoOwnerID, startdate, enddate, containers) {

        return this.serverService.postAndQuery<any>({
            api: CONFIG.api.user,
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.shareAssets, cargoOwnerID), startdate), enddate)
        }, containers) || of('');

    }
    UpdateShareAssetstoCargoOwner(cargoOwnerID, startdate, enddate, containers) {
        return this.serverService.postAndQuery<any>({
            api: CONFIG.api.user,
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.updateShareAssets, cargoOwnerID), startdate), enddate)
        }, containers) || of('');
    }
    getSharedAssetsCargoOwners(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getSharedAssets, encodeURIComponent(companyID))
        }) || of('');
    }
    getDeviceList(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getDeviceListData, encodeURIComponent(companyID))
        }) || of('');
    }
    getDevice(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getDevice, encodeURIComponent(companyID))
        }) || of('');
    }

    updateCompanyForDevices(devices) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.updateDeviceCompany
        }, devices) || of('');
    }
    updateContinaerPolling(devices) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.updateContainerPolling
        }, devices) || of('');
    }
    addAsset(asset) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.assetAdd
        }, asset) || of('');
    }
    updateAsset(asset) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.assetUpdate
        }, asset) || of('');
    }
    // deleteAsset(asset) {
    //     return this.serverService.postQuery<any>({
    //         api: CONFIG.api.asset,
    //         url: this.fillParam(CONFIG.url.deleteAsset, encodeURIComponent(asset))
    //     }) || of('');
    // }
    deleteAsset(asset) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.deleteAsset
        },asset) || of('');
    }
    updateDevice(device) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.deviceUpdate
        }, device) || of('');
    }
    addDevice(device) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.deviceAdd
        }, device) || of('');
    }
    getDeviceModels(){
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getdevicemodels
        }) || of('');
    }
    getAllDeviceFirmware(model) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getalldevicefirmware, encodeURIComponent(model))
        }) || of('');
    }
    getAllControllerFirmware(controllerType, controllerSwRev) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(CONFIG.url.getallcontrollerfirmware, controllerType), encodeURIComponent(controllerSwRev))
        }) || of('');
    }
    getAllDeviceFirmwareUpdateHistory(ID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getalldevicefirmwareupdatehistory, encodeURIComponent(ID))
        }) || of('');
    }
    getAllControllerSoftwareUpdateHistory(ID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getallcontrollersoftwareupdatehistory, encodeURIComponent(ID))
        }) || of('');
    }

    getDeviceConfigByID(deviceID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getdeviceconfigurationbyid, encodeURIComponent(deviceID))
        }) || of('');
    }

    getDeviceCurrentConfigByID(deviceID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getdevicecurrentconfigurationbyid, encodeURIComponent(deviceID))
        }) || of('');
    }

    getDeviceServerConfigByID(deviceID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getdeviceserverconfigurationbyid, encodeURIComponent(deviceID))
        }) || of('');
    }

    upgradeDeviceFirmware(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.upgradeDeviceFirmware
        }, data) || of('');
    }
    getRolebyUser(userID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getroleByUser, encodeURIComponent(userID))
        }) || of('');
    }
    saveDeviceConfiguration(deviceConfig) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.updatedeviceconfiguration
        }, deviceConfig) || of('');

    }

    addDeviceFirmware(devicefirmware) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.addDeviceFirmware
        }, devicefirmware) || of('');
    }

    updatedevicefirmware(devicefirmware) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.updateDeviceFirmware
        }, devicefirmware) || of('');
    }

    deleteDeviceFirmware(deviceFirmwareId) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.deleteDeviceFirmware, encodeURIComponent(deviceFirmwareId))
        }, deviceFirmwareId) || of('');
    }

    addControllerSoftware(controllerSoftware) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.addControllerSoftware
        }, controllerSoftware) || of('');
    }

    addModelConfigOptions(modelConfigOptionsData) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.addModelConfigOptions
        }, modelConfigOptionsData) || of('');
    }

    getAllModelConfigOptions() {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getAllModelConfigOptions
        }) || of('');
    }

    updateModelConfigOptions(modelConfigOptionsData) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.bulkUpdateModelConfigOptions
        }, modelConfigOptionsData) || of('');
    }


    updateControllerSoftware(controllersoftware) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.updateControllerSoftware
        }, controllersoftware) || of('');
    }

    deleteControllerSoftware(controllersoftwareId) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.deleteControllerSoftware, encodeURIComponent(controllersoftwareId))
        }, controllersoftwareId) || of('');
    }

    getPendingRegistration(approvedeviceId){
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getPendingRegistration
        }, approvedeviceId) || of('');
    }

    addEnrollAsset(approvedeviceId){
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.addEnrollAsset
        }, approvedeviceId) || of('');
    }

    // deleteApproveDevice(approvedeviceId){
    //     return this.serverService.post<any>({
    //         api: CONFIG.api.asset,
    //         url: CONFIG.url.deleteApproveDevice
    //     }, approvedeviceId) || of('');
    // }

    getuserlistbyRoleID(roleID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getuserslistbyroleID, encodeURIComponent(roleID))
        }) || of('');
    }

    getFleetListByCount(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getFleetListByCount, encodeURIComponent(companyID))
        }) || of('');
    }
    getbyidwithusersandassets(fleetId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getbyidwithusersandassets, encodeURIComponent(fleetId))
        }) || of('');
    }
    getallusersbyfleetid(fleetId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getallusersbyfleetid, encodeURIComponent(fleetId))
        }) || of('');
    }
    getallassetbyfleetid(fleetId) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getallassetbyfleetid, encodeURIComponent(fleetId))
        }) || of('');
    }
    addFleet(fleet) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.addFleet
        }, fleet) || of('');
    }
    updateFleet(fleet) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.updateFleet
        }, fleet) || of('');
    }

    getallusersbycompany(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getallusersbycompany, encodeURIComponent(companyID))
        }) || of('');
    }
    deleteFleet(fleetID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.deleteFleet, encodeURIComponent(fleetID))
        }) || of('');
    }

    getUser(user: string) {
        if (user === null || user === undefined) {
            return;
        }
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.user, encodeURIComponent(user))
        }) || of('');
    }
    getRoles(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getRolesList, encodeURIComponent(CompanyID))
        }) || of('');
    }

    getUserrequestData() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.getUserRequest
        }) || of('');
    }

    getUserrequestDatabyCompany(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getUserRequestbyCompany, encodeURIComponent(companyID))
        }) || of('');
    }

    getCompanyList() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.company
        }) || of('');
    }

    getCompanyListbyOrganization(CompanyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.companybyOrganization, encodeURIComponent(CompanyID))
        }) || of('');
    }

    getMixedFleetOwnerId(CompanyID){
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.mixedFleetOwner, encodeURIComponent(CompanyID))
        }) || of('');
    }

    getMixedFleetSave(MixedFleetSaveObject){
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.mixedFleetSave
        }, MixedFleetSaveObject) || of('');
    }
    getAllNotifications(userid) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getallNotifications, encodeURIComponent(userid))
        }) || of('');
    }

    getAllNotificationSettings(userid) {
        return this.serverService.postQuery<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getallNotificationSettings, encodeURIComponent(userid))
        }) || of('');
    }

    saveNotificationSettings(notificationSettingsObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.saveNotificationSettings
        }, notificationSettingsObject) || of('');
    }

    saveNotificationTemplate(template) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.saveTemplate
        }, template) || of('');
    }

    updateNotificationTemplate(template) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.updateTemplate
        }, template) || of('');
    }

    deleteNotificationTemplate(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.deleteTemplate, encodeURIComponent(data))
        }, data) || of('');
    }

// Added notification Email Configuration -- Start

    getEmailConfigList(configList){
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.getEmailConfigList
        },configList) || of('');
    }

// Added Notification Email Configuration -- End

    deleteTemplateWithSetup(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.deleteTemplate_Setup, encodeURIComponent(data))
        }, data) || of('');
    }

    getAllMySetups(userID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getMySetups, encodeURIComponent(userID))
        }) || of('');
    } 

    saveMySetup(setup) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.editMySubscription
        }, setup) || of('');
    }

    getAllNotificationTemplates(companyId) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getNotificationTemplate, encodeURIComponent(companyId))
        }) || of('');
    } 
    getNotificationTemplateByID(ID) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getTemplateByID, encodeURIComponent(ID))
        }) || of('');
    }

    getAllNotificationSetups(companyId) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getNotificationsSetups, encodeURIComponent(companyId))
        }) || of('');
    }

    saveNotificationSetup(setup) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.createNotificationSetup
        }, setup) || of('');
    }

    updateNotificationSetup(setup) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.updateNotificationSetup
        }, setup) || of('');
    }

    deleteNotificationSetup(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.deleteNotificationSetup, encodeURIComponent(data))
        }, data) || of('');
    }


    markAsReadNotifications(UserRequestData) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.markasReadNotification
        }, UserRequestData) || of('');
    }

    markAllAsReadNotification(UserRequestData) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.markallasReadNotification
        }, UserRequestData) || of('');
    }

    getCargoOwnerList() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.cargoOwner
        }) || of('');
    }
    getCompanyNamesList() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.companyNames
        }) || of('');
    }
    getUserLoggedInfo(UserId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.userLoggedInfoByEmail, encodeURIComponent(UserId))
        }) || of('');
    }

    getUserCompanies(UserId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getCompaniesList, encodeURIComponent(UserId))
        }) || of('');
    }
    addUserRequest(UserRequestData) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.addrequest
        }, UserRequestData) || of('');
    }

    SendRegistrationLinkDataRequest(SendRegistrationLinkData) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.sendregistrationLink
        }, SendRegistrationLinkData) || of('');
    }

    ApproveUserRequest(UserRequest) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.approveUserList
        }, UserRequest) || of('');
    }

    getassetDetails(requestObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.containerStatus
        }, requestObject) || of('');
    }

    getassetDetailsByPage(requestObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getallassetByPage
        }, requestObject) || of('');
    }

    getunreadNotificationscount(userid) {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: this.fillParam(CONFIG.url.getunreadcount, encodeURIComponent(userid))
        }) || of('');
    }

    getassetHistoryDetails(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistory, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    // GetAssetStatus By Container id irrespecitve of company -- Start

    getassetstatusbycontainerid(containerId: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getassetstatusbycontainerid, containerId)
        }) || of(false);
    }
    // GetAssetStatus By Container id irrespecitve of company -- End

    getActHistoryDetails(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryActDetails, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    getassetHistoryDeviceDetails(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryDeviceDetails, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }
    getmixedFleetptiResults(containerId: string, deviceId: string, startdate: string, endDate: string){
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryMixedFleetDetails, containerId),encodeURIComponent(deviceId)),startdate),endDate)
        }) || of(false);
    }
    getHealthAnalyticsAPI(containerId: string, companyID: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getHealthAnalytics, containerId),
                encodeURIComponent(companyID)), startdate), endDate)
        }) || of(false);
    }
    getISOVoltageDetailsListAPI(containerId: string, companyID: string, startdate: string, endDate: string, frequency: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getISOVoltageDetailsList, containerId),
            encodeURIComponent(companyID)), startdate), endDate), frequency)
        }) || of(false);
    }
    
    getassetHistoryLocationDetails(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryLocationDetails, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    //Added for genset location history -- Start
    getGensetHistoryLocationDetails(gensetID,startdate,endDate) {
        return this.serverService.get<any>({
            api: CONFIG.api.gensetAPI,
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferGensetHistoryLocationDetails, gensetID),
                  startdate), endDate)
            //url:CONFIG.url.reeferGensetHistoryLocationDetails
        }) || of(false);
    }
    //Added for genset location history -- End

    //Added for genset location history -- Start
    getGensetHistoryDetails(gensetHistoryRequestDetails) {
        return this.serverService.post<any>({
            api: CONFIG.api.gensetAPI,
            // url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferGensetHistoryDetails, gensetID),
            //     startdate), endDate)
            url:CONFIG.url.reeferGensetHistoryDetails
        },gensetHistoryRequestDetails) || of(false);
    }
        //Added for genset location history -- End
    
    getassetHistoryAnalogInputs(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryAnalogInput, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }
    getassetHistoryPTIResults(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryPTIResults, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    getassetHistoryCAPTResults(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryCAPTResults, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    getassetHistoryControllerInformation(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryControllerInformation, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    getassetHistoryControllerParameters(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryControllerParameters, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    getassetHistorySensorDetails(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistorySensorDetails, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }

    getassetHistoryOperatingModes(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryOperatingModes, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }
    getassetHistoryGetAlarms(containerId: string, deviceId: string, startdate: string, endDate: string, modelNo: string, firmVer: string, controllerType: string, reeferClass: string, deviceModel: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(this.fillParam(this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryAlarms, containerId),
                encodeURIComponent(deviceId)), startdate), endDate), modelNo), firmVer), controllerType), reeferClass), deviceModel)
        }) || of(false);
    }
    getassetHistoryGetTripWise(containerId: string, deviceId: string, startdate: string, endDate: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.reeferHistoryTripwise, containerId),
                encodeURIComponent(deviceId)), startdate), endDate)
        }) || of(false);
    }
    getassetStatus() {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.assetStatus
        }) || of('');
    }
    getTemperatureData(input) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getTemperatureChartData
        }, input) || of('');
    }

    getassetStatusData(containerId: string, deviceId: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(CONFIG.url.assetstatusdata, containerId), encodeURIComponent(deviceId))
        }) || of('');
    }
    getGensetStatusData(gensetId: string) {
        return this.serverService.get<any>({
            api: CONFIG.api.gensetAPI,
            url:this.fillParam(CONFIG.url.getgensetstatusdetails, encodeURIComponent(gensetId))
        }) || of('');
    }

    fillParam(template: string, param: any): string {
        if (Array.isArray(param)) {
            param.forEach(p => {
                template = this.fillParam(template, p);
            });
            return template;
        } else {
            // return param ? template.replace('${}', param) : template;
            return template.replace('${}', param || '');
        }
    }

    getAssetOperationsMonth(requestObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getAssetOperationsMonth
        }, requestObject) || of('');
    }

    getAssetOperationsDay(requestObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getAssetOperationsDay
        }, requestObject) || of('');
    }

    getGeofenceItem(geofenceid) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getGeofence, encodeURIComponent(geofenceid))
        }) || of('');
    }

    getGeofenceList(companyID) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getGeofenceList, encodeURIComponent(companyID))
        }) || of('');
    }

    addGeofence(addedGeofence) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.addGeofence
        }, addedGeofence) || of('');
    }
    updateGeofence(geofenceID, updatededGeofence) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.updateGeofence, encodeURIComponent(geofenceID))
        }, updatededGeofence) || of('');
    }
    deleteGeofence(deletetedGeofence) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.deleteGeofence, encodeURIComponent(deletetedGeofence))
        }, deletetedGeofence) || of('');
    }

    getTargettedSoftwareVersion(modelNumber) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getTargetedSoftwareVersion, encodeURIComponent(modelNumber))
        }) || of('');
    }

    upgradeControllerSoftware(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.upgradeControllerSoftware
        }, data) || of('');
    }

    upgradeControllerSoftwares(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.upgradeControllerSoftwares
        }, data) || of('');
    }
    enableDisableTwowayCommand(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.changetwowaycommandflag
        }, data) || of('');
    }

    getDCXDownloadHistory(deviceid, containerid, UnitModelNumber) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getDCXDownloadHistory, deviceid), encodeURIComponent(containerid)), UnitModelNumber)
        }) || of('');
    }

    downloadDCX(requestObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.downloadDCX
        }, requestObject) || of('');
    }

    getDCXFile(requestObject) {
        return this.serverService.downloadDCXFile<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getDCXFile
        }, requestObject) || of('');
    }

    getDeviceLogFileList(deviceId) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.deviceLogFilesListAPI, encodeURIComponent(deviceId))
        }) || of('');
    }

    getDeviceFile(fileName) {
        return this.serverService.downloadDeviceFile<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.deviceDownloadFileAPI, encodeURIComponent(fileName))
        }) || of('');
    }

    postSendLogRequestCmd(requestObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.deviceSendLogRequestCommand
        }, requestObject) || of('');
    }
    getBreadCrumbTrailAPI(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getbreadcrumbtrailAPI
        }, reqObj) || of('');
    }
    getGensetBreadCrumbTrailAPI(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.gensetAPI,
            url: CONFIG.url.getgensetbreadcrumbtrail
        }, reqObj) || of('');
    }
    
    getContainerInfoByTimeAPI(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getContainerInfoByTimeAPI
        }, reqObj) || of('');
    }
    onActivateUserList(reactivateObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.activateuserlist
        }, reactivateObj) || of('');
    }
    getLanguagesList() {
        return this.serverService.get<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.getLanguagesList
        }) || of('');
    }
    getLanguageStrings(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.dashboardAPI,
            url: CONFIG.url.getLanguageStrings
        }, reqObj) || of('');
    }

    getAllVessels(companyId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.getVessels, encodeURIComponent(companyId))
        }) || of('');
    }

    getVesselCode(vesselId) {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.generateVesselCode, encodeURIComponent(vesselId))
        }) || of('');
    }

    createVessel(reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.createVessel
        }, reqObj) || of('');
    }
    updateVessels(deviceId,reqObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.updateVessel,
        }, reqObj) || of('');
    }

    deleteVessels(vesselId) {
        return this.serverService.post<any>({
            api: CONFIG.api.user,
            url: this.fillParam(CONFIG.url.deleteVessel, encodeURIComponent(vesselId))
        }, vesselId) || of('');
    }

    getBaplieContainers(vesselId) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.baplieContainers, vesselId)
        }, {}) || of('');
    }

    importBaplie(vesselId , file) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(CONFIG.url.importBaplie, vesselId), file)
        }, {}) || of('');
    }

    getLastBaplieInfo(vesselId) {
        return this.serverService.post<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.baplieHistory, vesselId)
        }, {}) || of('');
    }

    getVesselDetails(vesselId) {
        return this.serverService.get<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.vesselDetails, vesselId)
            }) || of('');
    }

    vesselDetailsWithFilter(vesselId, start, end, mininterval) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(this.fillParam(CONFIG.url.vesselDetailsWithFilter, vesselId),
                encodeURIComponent(start)), end), mininterval)
        }) || of('');
    }


     getContainersByCompany(companyid) {
        return this.serverService.post<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.getContainersfromCompany, companyid)
        }, {}) || of('');
    }
    getDevicesByCompany(companyid) {
        return this.serverService.post<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.getDevicesfromCompany, companyid)
        }, {}) || of('');
    }
    getFleetsByCompany(companyid) {
        return this.serverService.post<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.getFleetsfromCompany, companyid)
        }, {}) || of('');
    }
    getConfigurationsByDevices(DeviceIDs) {
        return this.serverService.post<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.getConfigurationsByDevices, DeviceIDs)
        }, {}) || of('');
    }
    getConfigurationsByContainers(DeviceIDs) {
        return this.serverService.post<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.getConfigurationsByContainers, DeviceIDs)
        }, {}) || of('');
    }
    getConfigurationsByFleets(DeviceIDs) {
        return this.serverService.post<any>({
                api: CONFIG.api.asset,
                url: this.fillParam(CONFIG.url.getConfigurationsByFleets, DeviceIDs)
        }, {}) || of('');
    }
    getSIMConfigurationsenvs() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.getSIMConfigurationsenvs
        }) || of('');
    }
    setSIMConfigurations(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.setSIMConfiguration
        }, data) || of('');
    }
    getSIMDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getSIMDetails
        }, data) || of('');
    }
    getSessionDetails(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.getSessionDetails
        }, data) || of('');
    }
    getDeviceConfigurationDetails() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.getDeviceConfigDetails
        }) || of('');
    }
    setAPN(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.setapn
        }, data) || of('');
    }
    setUDP(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.setudp
        }, data) || of('');
    }
    setFTP(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.setftp
        }, data) || of('');
    }
    getAPN(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.pollapn
        }, data) || of('');
    }
    getUDP(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.polludp
        }, data) || of('');
    }
    getFTP(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.pollftp
        }, data) || of('');
    }
    pollDeviceInfo(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.polldeviceinfo
        }, data) || of('');
    }
    pollContainerId(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.pollcontainerid
        }, data) || of('');
    }
    pollStatusMessage(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.pollstatusmessages
        }, data) || of('');
    }
    pollTripwiseReports(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.polltripwisereports
        }, data) || of('');
    }
    pollPTIreports(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.pollptireports
        }, data) || of('');
    }
    setLoraCredentials(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.setloracredentials
        }, data) || of('');
    }
    setLoraBand(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.setloraband
        }, data) || of('');
    }
    setLoraMode(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.setloramode
        }, data) || of('');
    }
    pollLoraCredentials(data) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.pollloracredentials
        }, data) || of('');
    }
    autoGenerateLoraCredentials() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.autogenerateloracredentials
        }) || of('');
    }
    getBandandModes() {
        return this.serverService.get<any>({
            api: CONFIG.api.user,
            url: CONFIG.url.getbandandmodes
        }) || of('');
    }
    
    getScoredContainers(fleetID, healthType) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(CONFIG.url.getScoredContainersList, fleetID), encodeURIComponent(healthType))
        }) || of('');
        
    }

    getGensetStatus(requestObject) {
        return this.serverService.post<any>({
            api: CONFIG.api.gensetAPI,
            url: CONFIG.url.getGensetStatus
        }, requestObject) || of('');
    }

    getTripwiseReport(containerID, fleetID, dateT) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getTripwiseReport, containerID), fleetID), dateT)
        })
    }

    getPtiReport(containerID, fleetID, dateT) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getPtiReport, containerID), fleetID), dateT)
        })
    }

    deleteCompany(companyId, reason){
        return this.serverService.delete<any>({
            api: CONFIG.api.user,
            url: this.fillParam(this.fillParam(CONFIG.url.deleteCompanyById, companyId), reason)
        })
    }

    saveFiltersDetails(filterObj) {
        return this.serverService.post<any>({
            api: CONFIG.api.filtersApi,
            url: CONFIG.url.saveFilterDetails
        }, filterObj)
    }

    getFiltersDetails(companyId) {
        return this.serverService.get<any>({
            api: CONFIG.api.filtersApi,
            url: this.fillParam(CONFIG.url.getFilterDetails, encodeURIComponent(companyId))
        }) || of('');
    }

    getAlarmsFilters() {
        return this.serverService.get<any>({
            api: CONFIG.api.filtersApi,
            url: CONFIG.url.getAlarmsFilters
        }) || of('');
    }

    getAllCommandNotification(companyId, startDate, endDate) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,  
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getAllCommands, companyId), startDate), endDate)
        }) || of('');
    }

    getMyCommandNotification(userID, startDate, endDate) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,    
            url: this.fillParam(this.fillParam(this.fillParam(CONFIG.url.getMyCommands, userID), startDate), endDate)
        }) || of('');
    }

    cancelCommandNotification(cancelCommandConfig) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.cancelCommandConfig
        }, cancelCommandConfig) || of('');
    }

    createNewCommandConfig(commandConfig) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.createNewCommandConfig
        }, commandConfig) || of('');
    }

    createCommandNotificationConfig(commandNotificationConfig) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.createCommandNotificationConfig
        }, commandNotificationConfig) || of('');
    }

    getCommandNotificationConfig(companyId) {
        return this.serverService.get<any>({
            api: CONFIG.api.asset,
            url: this.fillParam(CONFIG.url.getCommandNotificationConfig, companyId)
        }) || of('');
    }

    updateCommandNotificationConfig(commandNotificationConfig) {
        return this.serverService.post<any>({
            api: CONFIG.api.asset,
            url: CONFIG.url.updateCommandNotificationConfig
        }, commandNotificationConfig) || of('');
    }
}
