import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { Browser } from '@syncfusion/ej2-base';
import { UnitConverterService } from '../../../Services/unit-converter.service';

@Component({
  selector: 'tl-mixedfleetptiresults-dialog',
  templateUrl: './mixedfleet-ptiresults-dialog.component.html',
  styleUrls: ['./mixedfleet-ptiresults-dialog.component.scss']
})
export class MixedFleetPTIResultsDialogComponent implements OnInit {

  @Input() MixedFleetPTIResultsInfo: any;
  data: any;
  public confirmCloseIcon: Boolean = true;
  public hidden: Boolean = true;
  public visible: Boolean = false;
  public animationSettings: Object = { effect: 'None' };
  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;
  public targetElement: HTMLElement;
  public target = '.control-section';
  public isModal: Boolean = true;
  public dateFormat: string;

  // Create element reference for dialog target element.
  @ViewChild('container',  {static: false}) container: ElementRef;
  @ViewChild('MixedFleetPTIResultDialog', {static: false}) public MixedFleetPTIResultDialog: DialogComponent;

  constructor(public unitConverterService: UnitConverterService) {

   }
 // Initialize the Dialog component's target element.
 public initilaizeTarget: EmitType<object> = () => {
  this.targetElement = this.container.nativeElement.parentElement;
}
public oncreated(): void {
    this.MixedFleetPTIResultDialog.show(Browser.isDevice ? true : false);
}

  ngOnInit() {
    this.dateFormat = this.unitConverterService.getDateFormat();
    let data = JSON.parse(JSON.stringify(this.MixedFleetPTIResultsInfo));
    if (data.mixedFleetPTIResultData) {
      data.mixedFleetPTIResultData.CompletedOn = this.unitConverterService.convertTimeZone(this.MixedFleetPTIResultsInfo.mixedFleetPTIResultData.CompletedOn);
    }
    this.data = data.mixedFleetPTIResultData;

  }

}
