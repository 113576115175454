import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { SearchComponent } from './search/search.component';
import { MatButtonModule, MatInputModule, MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TextBoxModule} from '@syncfusion/ej2-angular-inputs';
import { TRANSLATION_PROVIDERS } from 'src/language-translation';
import { SharedModule } from 'src/app/shared.module';
import { TranslationModule } from 'src/language-translation/translation.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    TextBoxModule,
    SharedModule,
    TranslationModule
  ],
  declarations: [
    SearchComponent,
  ],
  exports: [SearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [TRANSLATION_PROVIDERS]
})
export class UiModule { }
