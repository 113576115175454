import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safeHtml/safeHtml.pipe';
import { TempConverterPipe } from './temp-convertor.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SafeHtmlPipe,
        TempConverterPipe
    ],
    exports: [
        SafeHtmlPipe,
        TempConverterPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class CustomPipesModule { }
