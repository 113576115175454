import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../Services/loading.service';

@Component({
  selector: 'tl-privacyagreement',
  templateUrl: './privacyagreement.component.html',
  styleUrls: ['./privacyagreement.component.scss']
})
export class PrivacyagreementComponent implements OnInit {

  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.hide();

  }

}
