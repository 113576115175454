// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const protectedResourceMap: [string, string[]][] = [
    ['https://buildtodoservice.azurewebsites.net/api/todolist',  ["https://triplinkuat.onmicrosoft.com/triplinkapiuat/read"]],
    ['https://graph.microsoft.com/v1.0/me',  ["https://triplinkuat.onmicrosoft.com/triplinkapiuat/read"]]
  ];
export const environment = {
    production: true,
    hmr: false,
    tripLinkConfig: {
        tenant: 'triplinkuat.onmicrosoft.com',
        clientId: 'cfe58fc5-39dd-4777-9d8a-d27bb6796828', 
        usersApi: 'https://triplinkusermgmtapiuat.azurewebsites.net/',
        genSetApi:'https://triplinkgensetapiuat.azurewebsites.net',
        assetApi: 'https://triplinkreeferapiuat.azurewebsites.net',
        filtersApi: 'https://triplinkfilterapiuat.azurewebsites.net',
        authority: "https://triplinkuat.b2clogin.com/tfp/triplinkuat.onmicrosoft.com/B2C_1_triplinkuatsignupin",
        forgotPasswordAuthority: "https://triplinkuat.b2clogin.com/tfp/triplinkuat.onmicrosoft.com/B2C_1_triplinkuatresetpwd",
        forgotPasswordScope: "B2C_1_triplinkuatresetpwd",
		redirectUri: 'https://staging.container.lynxfleet.carrier.com/',
        cacheLocation: 'localStorage',
		postLogoutRedirectUri: 'https://staging.container.lynxfleet.carrier.com/',
        GoogleMapapiKey: 'AIzaSyDsxKU0-SzjC2CGoggy324T3EBUqJpT_uo',
        validateAuthority: false,
        extraQueryParameters: "",
        navigateToLoginRequestUrl: true,
        popUp: false,
        consentScopes: ["https://triplinkuat.onmicrosoft.com/triplinkapiuat/read"],
        unprotectedResources: ["https://www.microsoft.com/en-us/"],
        correlationId: 'B1FA',   
        piiLoggingEnabled: true,
        storeAuthStateInCookie: false, // dynamically set to true when IE11
        loadFrameTimeout : 6,
        dashboardAPI: 'https://triplinkuiapiuat.azurewebsites.net',
        versionNumber : "v 7.3.5",
        protectedResourceMap : protectedResourceMap,
        version: "0.0.0"
    },
    appInsights: {   
     // instrumentationKey : '1f374804-d29f-434c-86be-7a6175629758'
      instrumentationKey : '2a95ddab-c560-4739-9dfe-dc8749de8e57'
      
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
