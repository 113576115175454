import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PageSettingsModel, ToolbarItems, GridComponent, FilterSettingsModel, PdfExportProperties, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { DatePipe } from '@angular/common';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { Router } from '@angular/router';
import { CONFIG } from '../../../Core/config';

import { DataUtil } from '@syncfusion/ej2-data';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CustomSyncFusionGridService } from '../../Services/customSyncfusionGrid.service';
import { UserInfoService } from '../../../Services/userInfo.service';
import { UnitConverterService } from '../../../Services/unit-converter.service';
import { LoadingService } from '../../../Services/loading.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { TranslateService } from 'src/language-translation';

@Component({
  selector: 'tl-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
// export enum TripwiseStatusEnum {
//   Off = 0,
//   Expired = 1,
//   Check = 2,
//   Pass = 3,
//   // Analyzing = 255
// }
export class ModalInfoComponent implements OnInit {
  totalCount: number;
  name: string;
  ModalResponseData: any[];
  TabName: any[];
  Group: any[];
  public tripwisePopupShowData: Boolean = false;

  public filterOptions: FilterSettingsModel;
  public pageSettings: PageSettingsModel;
  public formatOptions: object;
  public decimalpoint: any;
  public activeAlarmData: Object[] = [];
  public criticalAlarmData: Object[] = [];
  public toolbarOptions: ToolbarItems[] | object;

  public PluggedGridData: any;
  public UnPluggedGridData: any;
  public PassGridData: any;
  public CheckGridData: any;
  public offGridData: any;
  public ExpiredGridData: any;
  public data;

  public visible: Boolean = true;
  public hidden: Boolean = true;
  public animationSettings: Object = { effect: 'None' };
  public confirmHeader;
  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;
  // public position: object = { X: 600, Y: 200 };
  public target = '.control-section';
  public isModal: Boolean = true;
  public measurementUnit = 'Metric';
  public tempUnit = 'C';
  public confirmCloseIcon: Boolean = true;
  public dateFormat: string;

  @ViewChild('modalDialog', { static: false }) modalDialog: DialogComponent;
  @ViewChild('Unitstab', { static: false }) public Unitstab: TabComponent;
  @ViewChild('TripWisetab', { static: false }) public TripWisetab: TabComponent;
  @Output() modalDialogClose = new EventEmitter();

  @Input() ModalInfo: any;
  @ViewChild('PluggedGrid', { static: false }) public PluggedGrid: GridComponent;
  @ViewChild('UnPluggedGrid', { static: false }) public UnPluggedGrid: GridComponent;
  @ViewChild('PassGrid', { static: false }) public PassGrid: GridComponent;
  @ViewChild('CheckGrid', { static: false }) public CheckGrid: GridComponent;
  @ViewChild('offGrid', { static: false }) public offGrid: GridComponent;
  @ViewChild('ExpiredGrid', { static: false }) public ExpiredGrid: GridComponent;




  public heading = [{ name: 'Container ID' }, { name: 'Device ID' }, { name: 'Set Point' },
  { name: 'Tripwise' }, { name: 'AmbientAirTemp' }, { name: 'RTS' }, { name: 'STS' }, { name: 'LastUpdated' }];
  showUnitsContainerId = true;
  showTripwiseContainerId = true;
  showLocationContainerId = true;
  constructor(
    public datepipe: DatePipe, public router: Router, public customSyncFusionGridService: CustomSyncFusionGridService, private _dashboardService: DashboardService
    , private userInfoService: UserInfoService, private _translate: TranslateService, public UnitConverterService: UnitConverterService, private LoadingService: LoadingService
  ) {
    this.filterOptions = {
      type: 'Excel'
    };
    this.pageSettings = { pageSize: 10 };
    this.dateFormat = this.UnitConverterService.getDateFormat();
    this.formatOptions = { type: 'dateTime', format: this.dateFormat + ' hh:mm:ss a' };
    this.decimalpoint =  'N2' ; // { format: 'N2' };
    this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
    this.measurementUnit = this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.UnitConversionType
      : ((sessionStorage.getItem('UserInfo')) ? JSON.parse(sessionStorage.getItem('UserInfo')).UnitConversionType : '');
    if (this.measurementUnit == 'Imperial') {
      this.tempUnit = 'F';
    }
  }
  dataBound(type) {
    if (this.ModalInfo.name === 'Units') {
      switch (type) {
        case 'plugged':
          this.customSyncFusionGridService.showNoRecordstoView(this.PluggedGrid, this.PluggedGridData, this.customSyncFusionGridService.lengthGreatreThanZero);
          this.PluggedGrid.autoFitColumns();
          let toolObjPlugged;
          let isPluggedhide;
          toolObjPlugged = this.PluggedGrid.toolbarModule.toolbar;
          isPluggedhide = this.PluggedGrid.currentViewData.length ? false : true;
          toolObjPlugged.hideItem(toolObjPlugged.items.findIndex(c => c.text === 'PDF Export'), isPluggedhide);
          toolObjPlugged.hideItem(toolObjPlugged.items.findIndex(c => c.text === 'Excel Export'), isPluggedhide);
          break;

        case 'unplugged':
          this.customSyncFusionGridService.showNoRecordstoView(this.UnPluggedGrid, this.UnPluggedGridData, this.customSyncFusionGridService.lengthGreatreThanZero);

          this.UnPluggedGrid.autoFitColumns();
          let toolObjUnPlugged;
          let isUnPluggedhide;
          toolObjUnPlugged = this.UnPluggedGrid.toolbarModule.toolbar;
          isUnPluggedhide = this.UnPluggedGrid.currentViewData.length ? false : true;
          toolObjUnPlugged.hideItem(toolObjUnPlugged.items.findIndex(c => c.text === 'PDF Export'), isUnPluggedhide);
          toolObjUnPlugged.hideItem(toolObjUnPlugged.items.findIndex(c => c.text === 'Excel Export'), isUnPluggedhide);
          break;

        default:
          break;
      }
    }
    if (this.ModalInfo.name === 'Tripwise') {

      switch (type) {
        case 'pass':
          this.customSyncFusionGridService.showNoRecordstoView(this.PassGrid, this.PassGridData, this.customSyncFusionGridService.lengthGreatreThanZero);
          this.PassGrid.autoFitColumns();
          let toolObjPass;
          let isPasshide;
          toolObjPass = this.PassGrid.toolbarModule.toolbar;
          isPasshide = this.PassGrid.currentViewData.length ? false : true;
          toolObjPass.hideItem(toolObjPass.items.findIndex(c => c.text === 'PDF Export'), isPasshide);
          toolObjPass.hideItem(toolObjPass.items.findIndex(c => c.text === 'Excel Export'), isPasshide);
          break;
        case 'check':
          this.customSyncFusionGridService.showNoRecordstoView(this.CheckGrid, this.CheckGridData, this.customSyncFusionGridService.lengthGreatreThanZero);
          this.CheckGrid.autoFitColumns();
          let toolObjCheck;
          let isCheckhide;
          toolObjCheck = this.CheckGrid.toolbarModule.toolbar;
          isCheckhide = this.CheckGrid.currentViewData.length ? false : true;
          toolObjCheck.hideItem(toolObjCheck.items.findIndex(c => c.text === 'PDF Export'), isCheckhide);
          toolObjCheck.hideItem(toolObjCheck.items.findIndex(c => c.text === 'Excel Export'), isCheckhide);
          break;
        case 'off':
          this.customSyncFusionGridService.showNoRecordstoView(this.offGrid, this.offGridData, this.customSyncFusionGridService.lengthGreatreThanZero);
          this.offGrid.autoFitColumns();
          let toolObjOff;
          let isOffhide;
          toolObjOff = this.offGrid.toolbarModule.toolbar;
          isOffhide = this.offGrid.currentViewData.length ? false : true;
          toolObjOff.hideItem(toolObjOff.items.findIndex(c => c.text === 'PDF Export'), isOffhide);
          toolObjOff.hideItem(toolObjOff.items.findIndex(c => c.text === 'Excel Export'), isOffhide);
          break;
        case 'expired':
          this.customSyncFusionGridService.showNoRecordstoView(this.ExpiredGrid, this.ExpiredGridData, this.customSyncFusionGridService.lengthGreatreThanZero);
          this.ExpiredGrid.autoFitColumns();
          let toolObjExpire;
          let isExpiredhide;
          toolObjExpire = this.ExpiredGrid.toolbarModule.toolbar;
          isExpiredhide = this.ExpiredGrid.currentViewData.length ? false : true;
          toolObjExpire.hideItem(toolObjExpire.items.findIndex(c => c.text === 'PDF Export'), isExpiredhide);
          toolObjExpire.hideItem(toolObjExpire.items.findIndex(c => c.text === 'Excel Export'), isExpiredhide);
          break;
        default:
          break;
      }




    }



  }

  public unitsTabRendered(): void {
    this.Unitstab.selectedItem = this.ModalInfo.activeTab;
    // args.selectedIndex = this.data.activeTab;

  }
  public tripwiseTabCreated(): void {
    this.TripWisetab.selectedItem = this.ModalInfo.activeTab;
    // args.selectedIndex = this.data.activeTab;

  }

  ngOnInit() {
    if (this.ModalInfo) {
      this.LoadingService.show();
      this._dashboardService.getTripwiseInfoGeofenceDetails(this.ModalInfo.ModalData.apiObject).subscribe(response => {
        this.data = response;
        this.filldata();
        this.tripwisePopupShowData = true;
        this.LoadingService.hide();
      });
    }


  }

  filldata() {
    if (this.data) {
      if (this.ModalInfo.name === 'Units') {
        this.totalCount = this.data.PluggedInContainersInfo.length
          + this.data.NotPluggedInContainersInfo.length;
        this.name = this.ModalInfo.name;
        this.PluggedGridData = DataUtil.parse.parseJson(this.data.PluggedInContainersInfo);
        // this.PluggedGridData = JSON.stringify(this.PluggedGridData);
        this.UnPluggedGridData = DataUtil.parse.parseJson(this.data.NotPluggedInContainersInfo);
        this.confirmHeader = this.name + ' ' + '(' + ((this.totalCount) ? this.totalCount : '') + ')';
        this.showUnitsContainerId = (this.ModalInfo.fromHistorypage) ? this.ModalInfo.fromHistorypage : false;

      }
      if (this.ModalInfo.name === 'Tripwise') {
        this.totalCount = this.data.TotalTripwiseCount;
        this.name = this.ModalInfo.name;
        if (this.data.TripwiseCheckContainersInfo) {
          this.data.TripwiseCheckContainersInfo.map((item) => {
            item.LastUpdated = this.UnitConverterService.convertTimeZone(item.LastUpdated);
            if (this.tempUnit === 'F') {
              item.AmbientAirTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientAirTemp);
            } else if (this.tempUnit === 'C') {
              item.AmbientAirTemp = this.UnitConverterService.temptoFixed(item.AmbientAirTemp);
            }
          });
        }
        if (this.data.TripwiseExpiredContainersInfo) {
          this.data.TripwiseExpiredContainersInfo.map((item) => {
            item.LastUpdated = this.UnitConverterService.convertTimeZone(item.LastUpdated);
            if (this.tempUnit === 'F') {
              item.AmbientAirTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientAirTemp);
            } else if (this.tempUnit === 'C') {
              item.AmbientAirTemp = this.UnitConverterService.temptoFixed(item.AmbientAirTemp);
            }
          });
        }
        if (this.data.TripwiseOffContainersInfo) {
          this.data.TripwiseOffContainersInfo.map((item) => {
            item.LastUpdated = this.UnitConverterService.convertTimeZone(item.LastUpdated);
            if (this.tempUnit === 'F') {
              item.AmbientAirTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientAirTemp);
            } else if (this.tempUnit === 'C') {
              item.AmbientAirTemp = this.UnitConverterService.temptoFixed(item.AmbientAirTemp);
            }
          });
        }
        if (this.data.TripwisePassContainersInfo) {
          this.data.TripwisePassContainersInfo.map((item) => {
            item.LastUpdated = this.UnitConverterService.convertTimeZone(item.LastUpdated);
            if (this.tempUnit === 'F') {
              item.AmbientAirTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientAirTemp);
            } else if (this.tempUnit === 'C') {
              item.AmbientAirTemp = this.UnitConverterService.temptoFixed(item.AmbientAirTemp);
            }
          });
        }
        this.PassGridData = DataUtil.parse.parseJson(this.data.TripwisePassContainersInfo);
        this.CheckGridData = DataUtil.parse.parseJson(this.data.TripwiseCheckContainersInfo);
        this.offGridData = DataUtil.parse.parseJson(this.data.TripwiseOffContainersInfo);
        this.ExpiredGridData = DataUtil.parse.parseJson(this.data.TripwiseExpiredContainersInfo);
        this.confirmHeader = this._translate.translate(this.name) + '(' + ((this.totalCount) ? this.totalCount : '') + ')';
        this.showTripwiseContainerId = (this.ModalInfo.fromHistorypage) ? this.ModalInfo.fromHistorypage : false;

      }
      if (this.ModalInfo.name === 'Location') {
        this.totalCount = this.data.GeofenceCount + this.data.LandCount +
          this.data.VesselCount;
        this.name = this.ModalInfo.name;
        this.confirmHeader = this.name + ' ' + '(' + ((this.totalCount) ? this.totalCount : '') + ')';
      }
      this.showLocationContainerId = (this.ModalInfo.fromHistorypage) ? this.ModalInfo.fromHistorypage : false;

    }
  }

  toolbarClick(args: ClickEventArgs, type): void {
    let invNum;
    const pdfExportProperties: PdfExportProperties = {
      pageOrientation: 'Landscape',
      fileName: 'TripWise.pdf'
    };
    switch (type) {
      case 'plugged':
        invNum = this.PluggedGrid;
        break;
      case 'unplugged':
        invNum = this.UnPluggedGrid;
        break;
      case 'pass':
        invNum = this.PassGrid;
        break;
      case 'check':
        invNum = this.CheckGrid;
        break;
      case 'off':
        invNum = this.offGrid;
        break;
      case 'expired':
        invNum = this.ExpiredGrid;
        break;
      default:
        break;
    }

    switch (args.item.text) {
      case 'PDF Export':
        type === 'critical' ? invNum.pdfExport(pdfExportProperties) : invNum.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        const excelExportProperties: ExcelExportProperties = {
          fileName: 'TripWise.xlsx'
      };
        type === 'critical' ? invNum.excelExport(excelExportProperties) : invNum.excelExport(excelExportProperties);
        break;
      case 'CSV Export':
        type === 'critical' ? invNum.csvExport() : invNum.csvExport();
        break;
    }
  }
  navigateAssetHistory(invNumData, type) {
    let invNum;
    switch (type) {
      case 'plugged':
        invNum = this.PluggedGrid.getRowInfo(invNumData.target).rowData;
        break;
      case 'unplugged':
        invNum = this.UnPluggedGrid.getRowInfo(invNumData.target).rowData;
        break;
      case 'pass':
        invNum = this.PassGrid.getRowInfo(invNumData.target).rowData;
        break;
      case 'check':
        invNum = this.CheckGrid.getRowInfo(invNumData.target).rowData;
        break;
      case 'off':
        invNum = this.offGrid.getRowInfo(invNumData.target).rowData;
        break;
      case 'expired':
        invNum = this.ExpiredGrid.getRowInfo(invNumData.target).rowData;
        break;
      default:
        break;
    }
    const modaldata = {
      'startdate': this.datepipe.transform(invNum['LastUpdated'], this.dateFormat),
      'endDate': this.datepipe.transform(invNum['LastUpdated'], this.dateFormat),
    };
    const data = { type: 'dashboard', data: modaldata };
    this.LoadingService.show();
    this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceId'], invNum['ContainerId']], { state: { data } });

  }

  closedialog() {
    // this.dialogRef.close();
  }

  modalDialogClosed() {
    this.modalDialogClose.emit(0);
  }

}
