import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tl-troubleshoot',
  templateUrl: './troubleshoot.component.html',
  styleUrls: ['./troubleshoot.component.scss']
})
export class TroubleshootComponent implements OnInit {
  public width = '55%';
  public position: object = { Y: 120 };
  showDialog = false;
  @Input() public alarmData: any;
  constructor() { }

  ngOnInit() {
    setTimeout( () => { this.showDialog = true; }, 100);
  }

}
