import { environment } from '../../environments/environment';

const triplinkConfig = environment.tripLinkConfig;

export let CONFIG = {
    api: {
        user: triplinkConfig.usersApi,
        asset: triplinkConfig.assetApi,
        dashboardAPI: triplinkConfig.dashboardAPI,
        gensetAPI:triplinkConfig.genSetApi,
        filtersApi: triplinkConfig.filtersApi
    },
    commonRegexValidations: {
        emailValidator: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$',
        emailValidWithOutString: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    },
    url: {
        tenants: 'tenant/getall',
        getEmailConfigList:"notificationsettings/getemailconfig",
        getCompaniesList: 'user/getcompaniesbyuseremail?userEmail=${}',
        getGeofence: 'geofence/get?geofenceId=${}',
        getGeofenceList: 'geofence/getall?companyID=${}',
        addGeofence: 'geofence/add',
        updateGeofence: 'geofence/update?geofenceId=${}',
        deleteGeofence: 'geofence/delete?geofenceId=${}',
        getPermissions: 'role/getallpermissions',
        getUserPermissions: 'user/permissions?userId=${}',
        getalltenantsbyUserID: 'tenant/gettenantsbyuser?userId=${}',
        getallusersbyTenantID: 'tenant/getusersbytenant?tenantid=${}',
        getassetstatusbycontainerid:'reefer/getassetstatusbycontainerid?containerId=${}',// Getting the record based on container id irrespective of compnay provided
        gridAdd: 'gridsettings/add',
        gridReset: 'gridsettings/delete',
        twoWayAdd: 'asset/sendtwowaycmd',
        commandsforMultipleContainers: 'asset/sendmultipletwowaycmd',
        gridGet: 'gridsettings/get',
        gridDelete: 'gridsettings/delete',
        tenantAdd: 'tenant/add',
        rolePermissionAdd: 'role/addrolewithpermissions',
        rolePermissionUpdate: 'role/UpdateRoleWithPermissions',
        companyAdd: 'company/add',
        shipperAdd: 'shipper/add',
        cargoOwnerAdd: 'cargoowner/add',
        companyUpdate: 'company/update',
        cargoOwnerUpdate: 'cargoowner/update',
        changePassword: 'user/updatepwd',
        deviceUpdate: 'device/update',
        deviceAdd: 'device/add',
        getdevicemodels: 'device/getalldevicemodels',
        getalldevicefirmware: 'device/getalldevicefirmware?deviceModel=${}',
        getalldevicefirmwareupdatehistory: 'device/getalldevicefirmwareupdatehistory?deviceId=${}',
        getallcontrollersoftwareupdatehistory: 'device/getallcontrollersoftwareupdatehistory?containerId=${}',
        upgradeControllerSoftwares: 'device/upgradecontrollerssoftware',
        changetwowaycommandflag:'asset/changetwowaycommandflag',
        getallcontrollerfirmware: 'device/getallcontrollerfirmware?controllerType=${}&ControllerSwRev=${}',
        updatedeviceconfiguration: 'device/updatedeviceconfiguration',
        getdeviceconfigurationbyid: 'device/getdeviceconfigurationbyid?deviceID=${}',
        getdevicecurrentconfigurationbyid: 'device/getdevicecurrentconfigurationbyid?deviceID=${}',
        getdeviceserverconfigurationbyid: 'device/getdeviceserverconfigurationbyid?deviceID=${}',
        upgradeDeviceFirmware: 'device/upgradedevicefirmware',
        getroleByUser: 'role/getbyuser?userid=${}',
        getAssetList: 'asset/getallasset?companyID=${}',
        getTripsList: 'trip?companyid=${}',
        getTripDetailsByID: 'trip/${}',
        submitTrip:'trip',
        bulkassociation:'trip/createtripsv1',
        bulkdisassociation:'trip/bulkdisassociation',
        updateTrip: 'trip/${}',
        deleteTrip: 'trip/delete',
        getTwowayHistory: 'asset/gettwowaycmdstatuslist?assetID=${}',
        getAllAssetList: 'containershare/getallassets?companyID=${}&cargoOwnerID=${}',
        getAllSharableAssetList: 'containershare/getallshareableassets?companyID=${}&cargoOwnerID=${}&startDate=${}&endDate=${}',
        getSharedAssets: 'containershare/getallcargosharecounts?companyID=${}',
        addModelConfigOptions:'ModelConfigOptions/add',
        getAllModelConfigOptions:'ModelConfigOptions/getall',
        bulkUpdateModelConfigOptions:'ModelConfigOptions/update',
        assetAdd: 'asset/addasset',
        assetUpdate: 'asset/updateasset',
        // deleteAsset: 'asset/deleteasset?assetID=${}',
        deleteAsset: 'asset/deleteasset',
        getDeviceListData: 'device/getallforasset?companyID=${}',
        getDevice: 'device/getall?companyID=${}',
        updateDeviceCompany: 'device/updatecompany',
        updateContainerPolling:'device/pollcontainerinfo',
        getallusersbycompany: 'user/getallusersbycompany?companyID=${}',
        getFleetListByCount: 'fleet/getfleetswithuserandassetCount?companyID=${}',
        getbyidwithusersandassets: 'fleet/getbyidwithusersandassets?fleetId=${}',
        getuserslistbyroleID: 'user/getallusersbyroleid?roleId=${}',
        getallassetbyfleetid: 'asset/getallassetbyfleet?fleetId=${}',
        getallusersbyfleetid: 'user/getallusersbyfleetid?fleetId=${}',
        addFleet: 'fleet/add',
        updateFleet: 'fleet/update',
        deleteFleet: 'fleet/delete?fleetID=${}',
        tenantAddUser: 'tenant/addusers?tenantid=${}&userids=${}',
        tenantRemoveUser: 'tenant/deleteuserfromtenant?tenantid=${}&userid=${}',
        removeRole: 'role/removerolebyid?roleid=${}',
        containerStatus: 'reefer/getallassetstatusfilters',
        getallassetByPage: 'reefer/getallassetstatusfiltersbypage',
        getAssetCommands: 'asset/getsupportedcommands',
        getContainerCommands: 'asset/getmergedsupportedcommands',
        getunreadcount: 'notifications/getunreadcount?userid=${}',
        assetStatus: 'reefer/getallasset',
        getTemperatureChartData: 'reefer/gettemperaturechartdata',
        company: 'company/getall',
        mixedFleetOwner: 'mixedfleetowner/getbyid?companyID=${}',
        mixedFleetSave: 'mixedfleetowner/save',
        companybyOrganization: 'company/getallbycompany?companyID=${}',
        getallNotifications: 'notifications/getall?userid=${}',
        getallNotificationsNew : 'notifications/getallv1?userid=${}',
        getallNotificationSettings: 'notificationsettings/get?userId=${}',
        cargoOwner: 'cargoOwner/getall',
        updateShareAssets: 'containershare/update?cargoOwnerID=${}&startDate=${}&endDate=${}',
        shareAssets: 'containershare/addcargoshares?cargoOwnerID=${}&startDate=${}&endDate=${}',
        companyNames: 'company/getallnames',
        userLoggedInfo: 'user/getuserloggedininfo?userid=${}',
        userLoggedInfoByEmail: 'user/getuserloggedininfobymail?userMail=${}',
        addrequest: 'userrequest/add',
        markasReadNotification: 'notifications/markasread',
        markallasReadNotification: 'notifications/markallread',
        saveNotificationSettings: 'notificationsettings/update',
        getUserRequest: 'userrequest/getall',
        getUserRequestbyCompany : 'userrequest/getassignedforcompany?companyID=${}',
        reeferHistory: 'reefer/gettelemetryhistorydata?containerId=${}&deviceId=${}&startdate=${}&enddate=${}',
        reeferHistoryDeviceDetails: 'reefer/getdevicedetails?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryActDetails: 'reefer/reeferactdata?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryMixedFleetDetails: 'reefer/mixedfleetptiresults?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryLocationDetails: 'reefer/getlocationdetails?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferGensetHistoryLocationDetails: 'Genset/getgensetlocationhistory?gensetID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',// Added for genset location history
        reeferGensetHistoryDetails: 'Genset/getgensethistoryinfo',// Added for genset history details
        reeferHistoryControllerInformation: 'reefer/getcontrollerinformation?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryControllerParameters: 'reefer/getcontrollerparameters?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistorySensorDetails: 'reefer/getsensordetails?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryOperatingModes: 'reefer/getoperatingmodes?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryAlarms: 'reefer/getalarms?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}&unitModelNumber=${}&controllerSWVersion=${}&controllerType=${}&reeferClass=${}&deviceModel=${}',
        reeferHistoryTripwise: 'reefer/gettripwise?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryAnalogInput: 'reefer/analoginputs?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryPTIResults: 'reefer/ptitestresults?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        reeferHistoryCAPTResults: 'reefer/capttestresults?containerID=${}&deviceID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        getusersList: 'user/getall',
        getusersListbyCompany: 'user/getassignedforcompany?CompanyId=${}',
        deleteUserbyID: 'user/delete?userid=${}',
        userUpdate: 'user/updatewithrole',
        userUpdatePreferences: 'user/updatepreferences',
        updateEulaDate: 'user/updateeuladate',
        user: 'user/getwithrole?userid=${}',
        getRolesList: 'role/getroles?companyId=${}',
        getRolesListbyCompanyID: 'role/getroleswithusercount?companyId=${}',
        getFleet: 'fleet/getall?companyID=${}',
        getGeofences: 'geofence/getall?companyID=${}',
        getFleetNames: 'fleet/getall?companyID=${}',
        getPermissionsbyRoleID: 'role/getpermissionsbyroleid?roleid=${}',
        sendregistrationLink: 'user/registerlist',
        dashboardAPI: 'ui/dashboardinfo',
        alarmInfoAPI: 'ui/alarminfo?companyID=${}',
        pluggedInfoAPI: 'ui/pluggedinfo?companyID=${}',
        tripwiseInfoAPI: 'ui/tripwiseinfo?companyID=${}',
        locationInfoAPI: 'ui/locationinfo?companyID=${}',
        getgensetlocationinfo:'Genset/getgensetlocationinfo',
        approveUserList: 'user/approveuserrequests',
        assetstatusdata: 'reefer/getassetstatusdata?containerId=${}&deviceId=${}',
        getgensetstatusdetails: 'Genset/getgensetstatusdetails?gensetId=${}',
        markerInfoAPI: 'ui/getdashboardlocationinfo?companyID=${}',
        vesselInfoAPI: 'ui/getdashboardvesselinfo?vesselId=${}&companyId=${}',
        containerInfoAPI: 'ui/containerinfo?containerId=${}&deviceId=${}',
        getgensetlocationdetails:'Genset/getgensetlocationdetails?gensetId=${}',
        getAssetOperationsMonth: 'reefer/getassetworkingdaysstatus',
        getAssetOperationsDay: 'reefer/getallassetworkinghoursstatus',
        setPointInfoAPI: 'ui/setpointinfo?companyID=${}',
        controllerfwInfoAPI: 'ui/controllerfwinfo?companyID=${}',
        assetModelInfoAPI: 'ui/assetmodelinfo?companyID=${}',
        devicefwInfoAPI: 'ui/devicefwinfo?companyID=${}',
        getAssetNotReportingStatusInfoAPI: 'ui/assetnotreportingstatus?companyID=${}',
        getAssetPowerStatusInfoAPI: 'ui/assetpowerstatus?companyID=${}',
        saveFirmwareFile : 'device/savefirmwarefile',
        removeFirmwareFile : 'device/removefirmwarefile',
        addDeviceFirmware: 'device/adddevicefirmware',
        updateDeviceFirmware: 'device/updatedevicefirmware',
        deleteDeviceFirmware: 'device/deletedevicefirmware?deviceFirmwareId=${}',
        addControllerSoftware: 'device/addcontrollerfirmware',
        updateControllerSoftware: 'device/updatecontrollerfirmware',
        deleteControllerSoftware: 'device/deletecontrollerfirmware?controllerFirmwareId=${}',
        getPendingRegistration: 'asset/getpendingregistrations',
        addEnrollAsset: 'asset/enrollasset',
        //deleteApproveDevice: 'device/deleteapprovedevice?approvedeviceID=${}',
        getTargetedSoftwareVersion: 'device/gettargettedsoftwareversion?modelNumber=${}',
        upgradeControllerSoftware: 'device/upgradecontrollersoftware',
        getDCXDownloadHistory: 'asset/getdcxdownloadhistory?deviceid=${}&containerid=${}&modelNumber=${}',
        downloadDCX: 'asset/downloaddcx',
        getDCXFile: 'asset/getdcxfile',
        dashboardLocationGeoInfo: 'ui/getdashboardlocationinfogeofence',
        getlocationinfogeofenceInfo: 'ui/getlocationinfogeofence',
        alarmGeofenceInfoAPI: 'ui/alarminfogeofence',
        setPointGeofenceInfoAPI: 'ui/setpointinfogeofence',
        setPointGeofenceSummaryAPI: 'ui/setpointinfogeofencesummary',
        assetModelGeofenceInfoAPI: 'ui/assetmodelinfogeofence',
        assetModelGeofenceSummaryAPI: 'ui/assetmodelinfogeofencesummary',
        controllerfwGeofenceInfoAPI: 'ui/controllerfwinfogeofence',
        controllerfwGeofenceSummaryAPI: 'ui/controllerfwinfogeofencesummary',
        devicefwGeofenceInfoAPI: 'ui/devicefwinfogeofence',
        devicefwGeofenceSummaryAPI: 'ui/devicefwinfogeofencesummary',
        getAssetNotReportingStatusGeofenceInfoAPI: 'ui/assetnotreportingstatusgeofence',
        getAssetPowerStatusGeofenceInfoAPI: 'ui/assetpowerstatusgeofence',
        tripwiseGeofenceInfoAPI: 'ui/tripwiseinfogeofence',
        deviceLogFilesListAPI: 'device/listlogfiles?deviceId=${}',
        deviceDownloadFileAPI: 'device/downloadfile?fileName=${}',
        deviceSendLogRequestCommand: 'device/sendlogrequestcommand',
        alarmInfoGeofenceSummaryAPI: 'ui/alarminfogeofencesummary',
        alarmInfoGeofenceDetailsAPI: 'ui/alarminfogeofencedetails',
        tripwiseInfoGeofenceSummaryAPI : 'ui/tripwiseinfogeofencesummary',
        tripwiseInfoGeofenceDetailsAPI : 'ui/tripwiseinfogeofencedetails',
        assetPowerStatusGeofenceSummaryAPI : 'ui/assetpowerstatusgeofencesummary',
        assetPowerStatusGeofenceDetailsAPI : 'ui/assetpowerstatusgeofencedetails',
        reeferModelInfoGeofenceDetailsAPI: 'ui/assetmodelinfogeofencedetails',
        setPointInfoGeofenceDetailsAPI: 'ui/setpointinfogeofencedetails',
        deviceInfoGeofenceDetailsAPI: 'ui/devicefwinfogeofencedetails',
        controllerInfoGeofenceDetailsAPI: 'ui/controllerfwinfogeofencedetails',
        assetNotReportingStatusGeofenceDetailsAPI : 'ui/assetnotreportingstatusgeofencedetails',
        gensetNotReportingStatusGeofenceDetailsAPI : 'Genset/getreportingstatusdetails?companyId=${}',
        assetNotReportingStatusGeofenceSummaryAPI: 'ui/assetnotreportingstatusgeofencesummary',
        gensetNotReportingStatusGeofenceSummaryAPI: 'Genset/getreportingstatussummary?companyId=${}',
        tripwiseExpirySummaryGeofenceAPI: 'ui/tripwiseexpirysummarygeofence',
        tripwiseExpiryInfoGeofenceAPI : 'ui/tripwiseexpiryinfogeofence',
        getwidgetexpandpreferenceAPI: 'user/getwidgetpreferencesbyuseremail?userEmail=${}',
        updateexpandcollapseAPI: 'user/updatewidgetpreferences',
        getbreadcrumbtrailAPI : 'asset/getbreadcrumbtrail',
        getgensetbreadcrumbtrail:'Genset/getgensetbreadcrumbtrail',
        getContainerInfoByTimeAPI : 'asset/getcontainerinfobytime',
        activateuserlist: 'user/activateuserlist',
        getLanguagesList : '/localization/getlanguages',
        getLanguageStrings : '/localization/getlanguagestrings',
        getVerions: 'version/getall',
        getcumulativehours : 'asset/getcumulativehours',
        getVessels: 'vessel/getall?CompanyID=${}',
        createVessel: 'vessel/addvessel',
        updateVessel: 'vessel/updatevessel',
        deleteVessel: 'vessel/deletevessel?vesselID=${}',
        generateVesselCode: 'vessel/generatevesselcode?vesselID=${}',
        baplieContainers: 'reefer/baplie/lastbapliefilecontainers?vesselId=${}',
        saveBaplie: 'reefer/baplie/save',
        importBaplie: 'reefer/baplie/import?vesselId=${}&fileName=${}',
        removeBaplie: 'reefer/baplie/remove',
        baplieHistory: 'reefer/baplie/filehistory?vesselId=${}',
        vesselDetails: 'reefer/getassetstatusforvessel?vesselId=${}',
        vesselDetailsWithFilter: 'reefer/getassetstatusforvesselwithfilters?vesselId=${}&startDate=${}&endDate=${}&SamplingInterval=${}',
        getISOCompanyData: 'reefer/getisovoltageCompanydata',
        getHealthAnalytics: 'reefer/gethealthanalyticsdetails?containerID=${}&companyID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}',
        getISOVoltageDetailsList: 'reefer/getisovoltagedetails?containerID=${}&companyID=${}&eventTimeUTCStart=${}&eventTimeUTCEnd=${}&frequency=${}',
  		getContainersfromCompany: 'diagnosis/getcontainersbycompany',
        getDevicesfromCompany: 'diagnosis/getdevicesbycompany',
        getFleetsfromCompany: 'diagnosis/getfleetsbycompany',
        getConfigurationsByDevices: 'diagnosis/getconfigsbydevice',
        getConfigurationsByContainers: 'diagnosis/getconfigsbycontainer',
        getConfigurationsByFleets: 'diagnosis/getconfigsbyfleet',  
        getSIMConfigurationsenvs: 'diagnosis/getsimconfigenvs',
        setSIMConfiguration: 'diagnosis/setsimconfig',
        getSIMDetails:'diagnosis/pollsimdetails',
        getSessionDetails:'diagnosis/pollsessiondetails',
        getDeviceConfigDetails: 'diagnosis/getdeviceconfgenvs',
        setapn: 'diagnosis/setapn',
        setudp: 'diagnosis/setudp',
        setftp: 'diagnosis/setftp',
        pollapn: 'diagnosis/pollapn',
        polludp: 'diagnosis/polludp',
        pollftp: 'diagnosis/pollftp',
        polldeviceinfo: 'diagnosis/polldeviceinfo',
        pollcontainerid: 'diagnosis/pollcontainerid',
        pollstatusmessages: 'diagnosis/pollstatusmessages',
        polltripwisereports: 'diagnosis/polltripwisereports',
        pollptireports: 'diagnosis/pollptireports',
        setloracredentials: 'diagnosis/setloracredentials',
        setloraband: 'diagnosis/setloraband',
        setloramode :'diagnosis/setloramode',
        pollloracredentials: 'diagnosis/pollloracredentials',
        autogenerateloracredentials: 'diagnosis/autogenerateloracredentials',
        getbandandmodes: 'diagnosis/getbandandmodes',
        assetmanufacturerinfogeofencedetails: 'ui/assetmanufacturerinfogeofencedetails',
        assetmanufacturerinfogeofencesummaryAPI: 'ui/assetmanufacturerinfogeofencesummary?companyID=${}',
        getNotificationTemplate: 'notificationsettings/getalltemplate?companyID=${}',
        saveTemplate: 'notificationsettings/addtemplate',
        updateTemplate: 'notificationsettings/updatetemplate',
        deleteTemplate: 'notificationsettings/deletetemplate?TemplateID=${}',
        deleteTemplate_Setup: 'notificationsettings/deletetemplatewithsetup?TemplateID=${}',
        getTemplateByID: 'notificationsettings/gettemplate?TemplateID=${}',
        getNotificationsSetups: 'notificationsettings/getallnotificationsetup?companyID=${}',
        createNotificationSetup:'notificationsettings/addnotificationsetup',
        updateNotificationSetup: 'notificationsettings/updatenotificationsetup',
        deleteNotificationSetup: 'notificationsettings/deletenotificationsetup?NotificationID=${}',
        getMySetups: 'notificationsettings/getnotificationsetupbyuserid?UserID=${}',
        editMySubscription: 'notificationsettings/updateusernotificationpreference', 
        getContainerLiveHealth: 'api/analytics/container/v1/overall-health?containerID=${}',
        getLiveFleetHealth: 'api/analytics/fleet/v1/overall-health?fleetID=${}',
        getHealthTrend: 'api/analytics/fleet/v1/fleet-health-trend?fleetID=${}',
        getUnitsListAPI: 'api/analytics/fleet/v1/poor-units?fleetID=${}&healthType=${}',
        getGoodUnitsListAPI: 'api/analytics/fleet/v1/good-units?fleetID=${}&healthType=${}',
        getFairUnitsListAPI: 'api/analytics/fleet/v1/fair-units?fleetID=${}&healthType=${}',
        getMapViewUnitsData: 'api/analytics/fleet/v1/map-view?fleetID=${}&healthType=${}&healthClass=${}',
        getContainerMapdata:'asset/get-current-params?containerID=${}',
        getContainerTimeSeries: 'api/analytics/container/v1/time-series-day?containerID=${}&startDate=${}&endDate=${}',
        getScoredContainersList: 'api/analytics/fleet/v1/scored-containers?fleetID=${}&healthType=${}',
        getGensetStatus: 'Genset/getgensetstatus',
        getTripwiseReport: 'reefer/gettripwisereport?containerID=${}&deviceID=${}&eventTimeUTC=${}',
        getPtiReport: 'reefer/getptireport?containerID=${}&deviceID=${}&eventTimeUTC=${}',
        deleteCompanyById: 'company/delete?CompanyID=${}&Reason=${}',
        saveFilterDetails: 'filter/filtersetup',
        getFilterDetails: 'filter/getfilter?companyId=${}',
        getAlarmsFilters: 'filter/getalarms',
        getAllCommands: 'newcommands/getallcommands?CompanyID=${}&StartDate=${}&EndDate=${}',
        getMyCommands: 'newcommands/getmycommands?UserID=${}&StartDate=${}&EndDate=${}',
        createNewCommandConfig: 'newcommands/createnewcommandconfig',
        createCommandNotificationConfig: 'asset/AddCommandNotificationConfig',
        updateCommandNotificationConfig: 'asset/UpdateCommandNotificationConfig',
        getCommandNotificationConfig: 'asset/getCommandNotificationconfig?companyId=${}',
        cancelCommandConfig: 'asset/CancelTwoWayCommands'
    },

    ROUTE_URLS: {
        AssetDetails: 'app/pages/containerdetails',
        gensetdetails:'app/pages/gensetdetails',
        AlarmPopup: 'app/pages/alarmInfo',
        UserDetails: 'app/usermanagement/users/userdetails',
        ContainerStatus: 'app/pages/containerstatus',
        company: 'app/usermanagement/company',
        cargoOwner: 'app/pages/cargoowner',
        role: 'app/usermanagement/roles',
        companyDetails: 'app/usermanagement/company/companydetails',
        assetoperationshourly: 'app/pages/assetoperations/assetoperationshourly',
        assetSharingDetails: 'app/pages/assetsharing/assetsharingdetails',
        cargoOwnerDetails: 'app/pages/cargoowner/cargoownerdetails',
        roledetails: 'app/usermanagement/roles/roledetails',
        privacy: 'privacy',
        termsconditions: 'terms',
        releasenotes: 'releasenotes',
        unauthorized: 'app/pages/unauthorized',
        Users: 'app/usermanagement/users',
        Assets : 'app/containermanagement/containers',
        homepage: 'app/dashboard',
        addDevices: 'app/containermanagement/devices/adddevices',
        upgradeFirmware: 'app/containermanagement/devices/updatefirmware',
        device: 'app/containermanagement/devices',
        deviceConfiguration: 'app/containermanagement/devices/deviceconfiguration',
        deviceFirmware: 'app/containermanagement/devicefirmware',
        addDeviceFirmware: 'app/containermanagement/devicefirmware/devicefirmwaredetails',
        controllerSoftware: 'app/containermanagement/controllersoftware',
        addControllerSoftware: 'app/containermanagement/controllersoftware/controllersoftwaredetails',
        approveDevices: 'app/containermanagement/approvedevices',
        addApproveDevices: 'app/containermanagement/addapprovedevices',
        modelConfigOptions: 'app/containermanagement/modelconfig',
        addModelConfigOptions: 'app/containermanagement/modelconfig/modelconfigdetails',
        upgradehistory: 'app/pages/upgradehistory',
        loghistory: 'app/containermanagement/devices/loghistory',
        controllerupgrade: 'app/pages/controllerupgrade',
        notificationSettings: 'app/notifications/notificationsettings',
        mySetup: 'app/notifications/mysetup',
        createTemplate:'app/notifications/createtemplate',
        edittemplate: 'app/notifications/template/',
        notificationTemplate: 'app/notifications/notificationtemplate',
        notificationSetup: 'app/notifications/notificationsetup',
        createNSetup: 'app/notifications/createnotificationsetup',
        editNSetup: 'app/notifications/notificationsetup/',
        vesselmanagement: 'app/vesselmanagement/vessels',
        addVesselManagement: 'app/vesselmanagement/vessels/addvessel',
        diagnostics: 'app/pages/diagnostics',
        newTrip:'app/trips/Trip'
    },
};

