import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import {
    PageSettingsModel,  ToolbarItems,
    GridComponent, FilterSettingsModel, PdfExportProperties, ExcelExportProperties
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CONFIG } from '../../../Core/config';
import {  TabComponent } from '@syncfusion/ej2-angular-navigations';
import { CustomSyncFusionGridService } from '../../Services/customSyncfusionGrid.service';
import { UserInfoService } from '../../../Services/userInfo.service';
import { UnitConverterService } from '../../../Services/unit-converter.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { LoadingService } from '../../../Services/loading.service';
import { TranslateService } from 'src/language-translation';

DataUtil.serverTimezoneOffset = 2.5;

@Component({
  selector: 'app-power-status-popup',
  templateUrl: './power-status-popup.component.html',
  styleUrls: ['./power-status-popup.component.scss'],
})
export class PowerStatusPopupComponent implements OnInit {

  @Input() PowerStatusInfo: any;
  @ViewChild('tab', { static: false }) tabObj: TabComponent;
  @ViewChild('powerStatusDialog', { static: false })
  public powerStatusDialog: DialogComponent;
  @ViewChild('powerOnGrid', { static: false })
  public powerOnGrid: GridComponent;
  @ViewChild('bucket1Grid', { static: false })
  public bucket1Grid: GridComponent;
  @ViewChild('bucket2Grid', { static: false })
  public bucket2Grid: GridComponent;
  @ViewChild('bucket3Grid', { static: false })
  public bucket3Grid: GridComponent;
  @ViewChild('bucket4Grid', { static: false })
  public bucket4Grid: GridComponent;
  @Output() powerDialogClose = new EventEmitter();

  public data: any;
  public powerStatusCount: number;
  public powerOnData: Object[] = [];
  public bucket1Data: Object[] = [];
  public bucket2Data: Object[] = [];
  public bucket3Data: Object[] = [];
  public bucket4Data: Object[] = [];
  public showContainerId = true;
  public bucket1Name: String = '<2 Hrs';
  public measurementUnit = 'Metric';
  public tempUnit = 'C';

  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;
  public confirmHeader;
  public visible: Boolean = true;
  public hidden: Boolean = true;
  public toolbarOptions: ToolbarItems[] | object;
  public filterOptions: FilterSettingsModel;
  public pageSettings: PageSettingsModel;
  public formatOptions: object;
  public decimalpoint: any;
  public target = '.control-section';
  // public position: object = { X: 600, Y: 200 };
  public animationSettings: Object = { effect: 'None' };
  public confirmCloseIcon: Boolean = true;
  public isModal: Boolean = true;
  public enablePowerStatus: Boolean = false;
  public dateFormat: string;

  constructor(
    public datepipe: DatePipe,
    public customSyncFusionGridService: CustomSyncFusionGridService,
    public router: Router,
    private userInfoService: UserInfoService,
    public UnitConverterService: UnitConverterService,
    private LoadingService: LoadingService,
    private _dashboardService: DashboardService,
    private _translate: TranslateService
  ) {
    this.filterOptions = {
      type: 'Excel',
    };
    // const passseddata = this.router.getCurrentNavigation() != null ?
    // this.router.getCurrentNavigation().extras.state.data : null;
    // this.confirmDialog.show();
    this.pageSettings = { pageSize: 10 };
    this.dateFormat = this.UnitConverterService.getDateFormat();
    this.formatOptions = {
      type: 'dateTime',
      format: this.dateFormat + ' hh:mm:ss a',
    };
    this.decimalpoint =  'N2' ; // { format: 'N2' };
    this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
    this.measurementUnit = this.userInfoService.loggedinUserData
      ? this.userInfoService.loggedinUserData.UnitConversionType
      : sessionStorage.getItem('UserInfo')
      ? JSON.parse(sessionStorage.getItem('UserInfo')).UnitConversionType
      : '';
    if (this.measurementUnit == 'Imperial') {
      this.tempUnit = 'F';
    }
  }

  filldata() {
    if (this.data) {
      if (this.data.PowerOnList) {
        this.data['PowerOnList'] = this.data.PowerOnList.map((item) => {
          item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
          item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
          if (this.tempUnit === 'F') {
            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.tempConverttoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.tempConverttoFixed(item.RTS);
            item.STS = this.UnitConverterService.tempConverttoFixed(item.STS);
          } else if (this.tempUnit === 'C') {
            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.temptoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.temptoFixed(item.RTS);
            item.STS = this.UnitConverterService.temptoFixed(item.STS);
          }
          if (item.PowerStatus == 1) { item['PowerStatus'] = 'ON'; }
          else if (item.PowerStatus == 0) { item['PowerStatus'] = 'OFF'; }
          else if (item.PowerStatus == null) { item['PowerStatus'] = 'unknown'; }
          return item;
        });
      }
      if (this.data.Bucket1_OffList) {
        this.data['Bucket1_OffList'] = this.data.Bucket1_OffList.map((item) => {
          item.EventTimeUTC = this.UnitConverterService.convertTimeZone(
            item.EventTimeUTC
          );
          item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
          if (this.tempUnit === 'F') {
            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.tempConverttoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.tempConverttoFixed(item.RTS);
            item.STS = this.UnitConverterService.tempConverttoFixed(item.STS);
          } else if (this.tempUnit === 'C') {
            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.temptoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.temptoFixed(item.RTS);
            item.STS = this.UnitConverterService.temptoFixed(item.STS);
          }
          if (item.PowerStatus == 1) { item['PowerStatus'] = 'ON'; }
          else if (item.PowerStatus == 0) { item['PowerStatus'] = 'OFF (< 2 Hrs)'; }
          else if (item.PowerStatus == null) { item['PowerStatus'] = 'unknown'; }
          return item;
        });
      }
      if (this.data.Bucket2_OffList) {
        this.data['Bucket2_OffList'] = this.data.Bucket2_OffList.map((item) => {
          item.EventTimeUTC = this.UnitConverterService.convertTimeZone(
            item.EventTimeUTC
          );
          item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
          if (this.tempUnit === 'F') {
            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.tempConverttoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.tempConverttoFixed(item.RTS);
            item.STS = this.UnitConverterService.tempConverttoFixed(item.STS);
          } else if (this.tempUnit === 'C') {
            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.temptoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.temptoFixed(item.RTS);
            item.STS = this.UnitConverterService.temptoFixed(item.STS);
          }
          if (item.PowerStatus == 1) { item['PowerStatus'] = 'ON'; }
          else if (item.PowerStatus == 0) { item['PowerStatus'] = 'OFF (2 - 4 Hrs)'; }
          else if (item.PowerStatus == null) { item['PowerStatus'] = 'unknown'; }
          return item;
        });
      }
      if (this.data.Bucket3_OffList) {
        this.data['Bucket3_OffList'] = this.data.Bucket3_OffList.map((item) => {
          item.EventTimeUTC = this.UnitConverterService.convertTimeZone(
            item.EventTimeUTC
          );
          item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
          if (this.tempUnit === 'F') {
            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.tempConverttoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.tempConverttoFixed(item.RTS);
            item.STS = this.UnitConverterService.tempConverttoFixed(item.STS);
          } else if (this.tempUnit === 'C') {
            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.temptoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.temptoFixed(item.RTS);
            item.STS = this.UnitConverterService.temptoFixed(item.STS);

          }
          if (item.PowerStatus == 1) { item['PowerStatus'] = 'ON'; }
          else if (item.PowerStatus == 0) { item['PowerStatus'] = 'OFF (> 4 Hrs)'; }
          else if (item.PowerStatus == null) { item['PowerStatus'] = 'unknown'; }
          return item;
        });
      }
      if (this.data.Bucket4_OnList) {
        this.data['Bucket4_OnList'] = this.data.Bucket4_OnList.map((item) => {
          item.EventTimeUTC = this.UnitConverterService.convertTimeZone(
            item.EventTimeUTC
          );
          item.PowerStatusTimeUTC = this.datepipe.transform(this.UnitConverterService.convertTimeZone(item.PowerStatusTimeUTC), this.dateFormat + ' hh:mm:ss a');
          if (this.tempUnit === 'F') {
            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.tempConverttoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.tempConverttoFixed(item.RTS);
            item.STS = this.UnitConverterService.tempConverttoFixed(item.STS);
          } else if (this.tempUnit === 'C') {
            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
            item.Setpoint = this.UnitConverterService.temptoFixed(item.Setpoint);
            item.RTS = this.UnitConverterService.temptoFixed(item.RTS);
            item.STS = this.UnitConverterService.temptoFixed(item.STS);

          }
          if (item.PowerStatus == 1) { item['PowerStatus'] = 'NOT REPORTING (> 2 Hrs)'; }
          else if (item.PowerStatus == null) { item['PowerStatus'] = 'unknown'; }
          return item;
        });
      }
      this.data = DataUtil.parse.parseJson(this.data);

      this.powerStatusCount =
        this.data.PowerOnCount +
        this.data.Bucket1_OffCount +
        this.data.Bucket2_OffCount +
        this.data.Bucket4_OnCount  +
        this.data.Bucket3_OffCount;
      this.powerOnData = DataUtil.parse.parseJson(this.data.PowerOnList);
      this.bucket1Data = DataUtil.parse.parseJson(this.data.Bucket1_OffList);
      this.bucket2Data = DataUtil.parse.parseJson(this.data.Bucket2_OffList);
      this.bucket3Data = DataUtil.parse.parseJson(this.data.Bucket3_OffList);
      this.bucket4Data = DataUtil.parse.parseJson(this.data.Bucket4_OnList);
      this.confirmHeader =
        this._translate.translate('Power') + ' (' + this.powerStatusCount + ')';

      this.showContainerId = this.data.fromHistorypage
        ? this.data.fromHistorypage
        : false;
      // this.tabObj.select(this.data.activeTab);
    }
  }

  ngOnInit() {
    if (this.PowerStatusInfo) {
      this.LoadingService.show();
      this._dashboardService
        .getAssetPowerStatusGeofenceDetails(
          this.PowerStatusInfo.powerStatusData.apiObject
        )
        .subscribe((response) => {
          this.data = response;
          this.filldata();
          this.enablePowerStatus = true;
          this.LoadingService.hide();
        });
    }
  }

  toolbarClick(args: ClickEventArgs, type): void {
    let checkNum;
    const pdfExportProperties: PdfExportProperties = {
      pageOrientation: 'Landscape',
      fileName: 'PowerStatus.pdf'
    };
    switch (type) {
      case 'poweron':
        checkNum = this.powerOnGrid;
        break;
      case 'bucket1':
        checkNum = this.bucket1Grid;
        break;
      case 'bucket2':
        checkNum = this.bucket2Grid;
        break;
      case 'bucket3':
        checkNum = this.bucket3Grid;
        break;
      case 'bucket4':
        checkNum = this.bucket4Grid;
          break;
      default:
        break;
    }
    switch (args.item.text) {
      case 'PDF Export':
        checkNum.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        const excelExportProperties: ExcelExportProperties = {
          fileName: 'PowerStatus.xlsx'
      };
        checkNum.excelExport(excelExportProperties);
        break;
      case 'CSV Export':
        checkNum.csvExport();
        break;
    }
  }

  dataBound(type) {
    if (type === 'poweron') {
      this.customSyncFusionGridService.showNoRecordstoView(
        this.powerOnGrid,
        this.powerOnData,
        this.customSyncFusionGridService.lengthGreatreThanZero
      );
      this.powerOnGrid.autoFitColumns();
      let toolObjCritical;
      let iscriticalhide;
      toolObjCritical = this.powerOnGrid.toolbarModule.toolbar;
      iscriticalhide = this.powerOnGrid.currentViewData.length ? false : true;
      toolObjCritical.hideItem(
        toolObjCritical.items.findIndex((c) => c.text === 'PDF Export'),
        iscriticalhide
      );
      toolObjCritical.hideItem(
        toolObjCritical.items.findIndex((c) => c.text === 'Excel Export'),
        iscriticalhide
      );
    }
    if (type === 'bucket1') {
      this.customSyncFusionGridService.showNoRecordstoView(
        this.bucket1Grid,
        this.bucket1Data,
        this.customSyncFusionGridService.lengthGreatreThanZero
      );
      this.bucket1Grid.autoFitColumns();
      let toolObjActive;
      let isactivehide;
      toolObjActive = this.bucket1Grid.toolbarModule.toolbar;
      isactivehide = this.bucket1Grid.currentViewData.length ? false : true;
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'PDF Export'),
        isactivehide
      );
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'Excel Export'),
        isactivehide
      );
    }
    if (type === 'bucket2') {
      this.customSyncFusionGridService.showNoRecordstoView(
        this.bucket2Grid,
        this.bucket2Data,
        this.customSyncFusionGridService.lengthGreatreThanZero
      );
      this.bucket2Grid.autoFitColumns();
      let toolObjActive;
      let isactivehide;
      toolObjActive = this.bucket2Grid.toolbarModule.toolbar;
      isactivehide = this.bucket2Grid.currentViewData.length ? false : true;
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'PDF Export'),
        isactivehide
      );
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'Excel Export'),
        isactivehide
      );
    }
    if (type === 'bucket3') {
      this.customSyncFusionGridService.showNoRecordstoView(
        this.bucket3Grid,
        this.bucket3Data,
        this.customSyncFusionGridService.lengthGreatreThanZero
      );
      this.bucket3Grid.autoFitColumns();
      let toolObjActive;
      let isactivehide;
      toolObjActive = this.bucket3Grid.toolbarModule.toolbar;
      isactivehide = this.bucket3Grid.currentViewData.length ? false : true;
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'PDF Export'),
        isactivehide
      );
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'Excel Export'),
        isactivehide
      );
    }
    if (type === 'bucket4') {
      this.customSyncFusionGridService.showNoRecordstoView(
        this.bucket4Grid,
        this.bucket4Data,
        this.customSyncFusionGridService.lengthGreatreThanZero
      );
      this.bucket4Grid.autoFitColumns();
      let toolObjActive;
      let isactivehide;
      toolObjActive = this.bucket4Grid.toolbarModule.toolbar;
      isactivehide = this.bucket4Grid.currentViewData.length ? false : true;
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'PDF Export'),
        isactivehide
      );
      toolObjActive.hideItem(
        toolObjActive.items.findIndex((c) => c.text === 'Excel Export'),
        isactivehide
      );
    }
  }

  public tabCreated(): void {
    this.tabObj.selectedItem = this.PowerStatusInfo.activeTab;
  }

  navigateAssetHistory(invNumData, type) {
    this.powerDialogClosed();
    let invNum;
    switch (type) {
      case 'poweron':
        invNum = this.powerOnGrid.getRowInfo(invNumData.target).rowData;
        break;
      case 'bucket1':
        invNum = this.bucket1Grid.getRowInfo(invNumData.target).rowData;
        break;
      case 'bucket2':
        invNum = this.bucket2Grid.getRowInfo(invNumData.target).rowData;
        break;
      case 'bucket3':
        invNum = this.bucket3Grid.getRowInfo(invNumData.target).rowData;
        break;
      case 'bucket4':
        invNum = this.bucket4Grid.getRowInfo(invNumData.target).rowData;
          break;
      default:
        break;
    }
    const powerdata = {
      startdate: this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
      endDate: this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
    };
    const data = { type: 'dashboard', data: powerdata };
    this.LoadingService.show();
    this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });
  }

  powerDialogClosed() {
    this.powerDialogClose.emit(0);
  }
}
