import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  PageSettingsModel, ToolbarItems,
  GridComponent, FilterSettingsModel, PdfExportProperties, GroupSettingsModel, ExcelExportProperties
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CONFIG } from '../../../Core/config';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { CustomSyncFusionGridService } from '../../Services/customSyncfusionGrid.service';
import { permissionList } from '../../../../assets/data/permissions';
import { UserInfoService } from '../../../Services/userInfo.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { LoadingService } from '../../../Services/loading.service';
import { UnitConverterService } from '../../../Services/unit-converter.service';
import { TranslateService } from 'src/language-translation';

@Component({
  selector: 'tl-alarm-popup',
  templateUrl: './alarm-popup.component.html',
  styleUrls: ['./alarm-popup.component.scss']
})
export class AlarmPopupComponent implements OnInit {
 

  alarmsCount: number;
  public filterOptions: FilterSettingsModel;
  public pageSettings: PageSettingsModel;
  public formatOptions: object;
  public decimalpoint: any;
  public confirmCloseIcon: Boolean = true;
  public confirmInnerCloseIcon: Boolean = true;
  public activeAlarmData: Object[] = [];
  public criticalAlarmData: Object[] = [];
  public data: any;
  public visible: Boolean = true;
  public hidden: Boolean = true;
  public animationSettings: Object = { effect: 'None' };
  public confirmHeader;
  public assetShow: Boolean = false;
  public alarmPopupShowData: Boolean = false;
  public showContainerId = true;
  public target = '.Alarm-control-section';
  public position: object = { X: 'center', Y: 'center' };
  public innerPosition: Object = { X: 20, Y: 90 };
  public groupOptions: GroupSettingsModel;

  @ViewChild('alarmTab', { static: false }) tabObj: TabComponent;
  @ViewChild('ejDialog', { static: false }) ejDialog: DialogComponent;
  @Input() AlarmInfo: any;
  @Input('screen') screen: any;
  @ViewChild('alarmDialog', { static: false }) public alarmDialog: DialogComponent;
  @ViewChild('criticalAlarmGrid', { static: false }) public criticalAlarmGrid: GridComponent;
  @ViewChild('activeAlarmGrid', { static: false }) public activeAlarmGrid: GridComponent;
  @Output() alarmDialogClose = new EventEmitter();
  @Input() alarmData: any;
  // alarmCriticalData:any
  public width = '55%';
  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;

  public toolbarOptions: ToolbarItems[] | object;
  activeAlarmsCount: any = 0;
  criticalAlarmsCount: any = 0;
  public enableAlarmTroubleShoot: Boolean = false;
  public isModal: Boolean = true;
  public dateFormat: string;
  allowGroup: any;

  constructor(public datepipe: DatePipe, public router: Router, public customSyncFusionGridService: CustomSyncFusionGridService
    , private userInfoService: UserInfoService,
    private _translate: TranslateService,
    private LoadingService: LoadingService, private _dashboardService: DashboardService,
    public unitConverterService: UnitConverterService) {
    this.filterOptions = {
      type: 'Excel'
    };

    this.pageSettings = { pageSize: 5 };
    this.dateFormat = this.unitConverterService.getDateFormat();
    this.formatOptions = { type: 'dateTime', format: this.dateFormat + ' hh:mm:ss a' };
    this.decimalpoint =  'N2' ; // { format: 'N2' };
    this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
    const permissionData = this.userInfoService.permissionList ? this.userInfoService.permissionList
      : JSON.parse(sessionStorage.getItem('PermissionList'));
    if (permissionData.includes(permissionList.Alarm.AlarmTroubleshootSteps)) {
      this.enableAlarmTroubleShoot = true;
    }
  }


  getTroubleStatus(alarm) {
    return alarm.AlarmAction ? Object.values(alarm.AlarmAction).every(o => o === null) : true;
  }
  public hideDialog: EmitType<object> = () => {
    this.ejDialog.hide();
    // this.ezDialog.hide();
    this.assetShow = false

  }

  // Enables the footer buttons
  public buttons: Object = [
    {
      'click': this.hideDialog.bind(this),
      // Accessing button component properties by buttonModel property
      buttonModel: {
        content: 'OK',
        // Enables the primary button
        isPrimary: true
      }
    }
  ];
  dataBound(type) {
    if (type === 'critical') {
      this.criticalAlarmGrid.autoFitColumns();
      let toolObjCritical;
      let iscriticalhide;
      toolObjCritical = this.criticalAlarmGrid.toolbarModule.toolbar;
      iscriticalhide = this.criticalAlarmGrid.currentViewData.length ? false : true;
      toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
      toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
    }
    if (type === 'active') {
      this.customSyncFusionGridService.showNoRecordstoView(this.activeAlarmGrid, this.activeAlarmData, this.customSyncFusionGridService.lengthGreatreThanZero);
      this.activeAlarmGrid.autoFitColumns();
      let toolObjActive;
      let isactivehide;
      toolObjActive = this.activeAlarmGrid.toolbarModule.toolbar;
      isactivehide = this.activeAlarmGrid.currentViewData.length ? false : true;
      toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'PDF Export'), isactivehide);
      toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'Excel Export'), isactivehide);
    }
  }
  toolbarClick(args: ClickEventArgs, type): void {
    switch (args.item.text) {
      case 'PDF Export':

        if (type === 'critical') {
          const pdfExportProperties: PdfExportProperties = {
            pageOrientation: 'Landscape',
            fileName: 'CriticalAlarms.pdf'
          };
          let colLength: number = this.criticalAlarmGrid.getColumns().length;
          let cols: any = this.criticalAlarmGrid.getColumns();
          for (let i: number = 0; i < colLength; i++) {
            if (cols[i].field == "Action") {
              cols[i].visible = false;
              this.criticalAlarmGrid.pdfExport(pdfExportProperties).then(((e) => cols[i].visible = true));
            }
          }
        } else {
          const pdfExportProperties: PdfExportProperties = {
            pageOrientation: 'Landscape',
            fileName: 'NonCriticalAlarms.pdf'
          };
          let colLength: number = this.activeAlarmGrid.getColumns().length;
          let cols: any = this.activeAlarmGrid.getColumns();
          for (let i: number = 0; i < colLength; i++) {
            if (cols[i].field == "Action") {
              cols[i].visible = false;
              this.activeAlarmGrid.pdfExport(pdfExportProperties).then(((e) => cols[i].visible = true));
            }
          }
        }
        // type === 'critical' ? this.criticalAlarmGrid.pdfExport(pdfExportProperties) : this.activeAlarmGrid.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        if (type === 'critical') {
          const excelExportProperties: ExcelExportProperties = {
            fileName: 'CriticalAlarms.xlsx'
          };
          let colLength: number = this.criticalAlarmGrid.getColumns().length;
          let cols: any = this.criticalAlarmGrid.getColumns();
          for (let i: number = 0; i < colLength; i++) {
            if (cols[i].field == "Action") {
              cols[i].visible = false;
              this.criticalAlarmGrid.excelExport(excelExportProperties).then(((e) => cols[i].visible = true));
            }
          }
        } else {
          const excelExportProperties: ExcelExportProperties = {
            fileName: 'NonCriticalAlarms.xlsx'
          };
          let colLength: number = this.activeAlarmGrid.getColumns().length;
          let cols: any = this.activeAlarmGrid.getColumns();
          for (let i: number = 0; i < colLength; i++) {
            if (cols[i].field == "Action") {
              cols[i].visible = false;
              this.activeAlarmGrid.excelExport(excelExportProperties).then(((e) => cols[i].visible = true));
            }
          }
        }
        break;
      case 'CSV Export':
        type === 'critical' ? this.criticalAlarmGrid.csvExport() : this.activeAlarmGrid.csvExport();
        break;
    }
  }

  // Sample level code to handle the button click action
  public navigateTrouble(event: any, name: any): void {
    // Call the show method to open the Dialog
    this.assetShow = false;
    if (name.id == 'active') {
      this.assetShow = true;
      this.alarmData = this.activeAlarmGrid.getRowInfo(event.target).rowData;
      // this.ejDialog.show();

    }
    if (name.id == 'critical') {
      this.assetShow = true;
      this.alarmData = this.criticalAlarmGrid.getRowInfo(event.target).rowData;
      //  this.ejDialog.show();
    }
  }
  navigateAssetHistory(invNumData, type) {
    const invNum = type === 'critical' ? this.criticalAlarmGrid.getRowInfo(invNumData.target).rowData :
      this.activeAlarmGrid.getRowInfo(invNumData.target).rowData;
    const alarmdata = {
      'startdate': this.datepipe.transform(invNum['AlarmTimeStamp'], this.dateFormat),
      'endDate': this.datepipe.transform(invNum['AlarmTimeStamp'], this.dateFormat),
    };
    const data = { type: 'dashboard', data: alarmdata };
    this.LoadingService.show();
    this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });

  }
  closedialog() {
    // this.dialogRef.close();
  }
  public tabCreated(): void {
    this.tabObj.selectedItem = 1;
    this.tabObj.selectedItem = this.AlarmInfo.activeTab == 0 ? 0 : 1;
  }

  filldata() {
    if (this.data) {
      if (this.data.ActiveAlarmsWithDescription) {
        this.data.ActiveAlarmsWithDescription.map((item: any) => {
          item.AlarmTimeStamp = this.unitConverterService.convertTimeZone(item.AlarmTimeStamp);
        });
      }
      if (this.data.CriticalAlarmsWithDescription) {
        this.data.CriticalAlarmsWithDescription.map((item: any) => {
          item.AlarmTimeStamp = this.unitConverterService.convertTimeZone(item.AlarmTimeStamp);
        });
      }
      this.alarmsCount = (this.data.ActiveAlarmsCount || this.data.CriticalAlarmsCount)
        ? (((this.data.ActiveAlarmsCount) ? this.data.ActiveAlarmsCount : 0)
          + ((this.data.CriticalAlarmsCount) ? this.data.CriticalAlarmsCount : 0)
        ) : (((this.data.ActiveAlarmsWithDescription) ? this.data.ActiveAlarmsWithDescription.length :
          0)
          // this.data.ActiveAlarms.length)
          + ((this.data.CriticalAlarmsWithDescription) ? this.data.CriticalAlarmsWithDescription.length :

            0
            // this.data.CriticalAlarms.length
          )
        );
      this.activeAlarmsCount =
        (this.data.ActiveAlarmsCount) ? this.data.ActiveAlarmsCount
          : ((this.data.ActiveAlarmsWithDescription) ? this.data.ActiveAlarmsWithDescription.length :
            // this.data.ActiveAlarms.length  );

            0);
      this.criticalAlarmsCount = (this.data.CriticalAlarmsCount) ? this.data.CriticalAlarmsCount
        : ((this.data.CriticalAlarmsWithDescription) ? this.data.CriticalAlarmsWithDescription.length :
          0
          // this.data.CriticalAlarms.length
        );


      this.activeAlarmData = DataUtil.parse.parseJson(
        (this.data.ActiveAlarmsWithDescription) ?
          this.data.ActiveAlarmsWithDescription : this.data.ActiveAlarms);
      this.criticalAlarmData = DataUtil.parse.parseJson(
        (this.data.CriticalAlarmsWithDescription) ?
          this.data.CriticalAlarmsWithDescription :
          this.data.CriticalAlarms

      );
      this.confirmHeader = this._translate.translate('Alarms') + '(' + this.alarmsCount + ')';
      this.showContainerId = (this.data.fromHistorypage) ? this.data.fromHistorypage : false;
      // this.tabObj.select(this.data.activeTab);
    }

  }
  ngOnInit() {
    if (this.AlarmInfo) {
      if (this.AlarmInfo.source == 'dashboard') {
        this.LoadingService.show();
        this._dashboardService.getAlarmGeofenceDetails(this.AlarmInfo.alarmData).subscribe(response => {
          this.data = JSON.parse(JSON.stringify(response));
          const alarmData = {
            ActiveAlarmsWithDescription: response.ActiveAlarmsWithDescription,
            CriticalAlarmsWithDescription: response.CriticalAlarmsWithDescription
          };
          // Storing Alarm Data in Service to launch the Alarm Pop up on click of Notifications tab..
          // this._dashboardService.setAlarmData(alarmData);
          this.filldata();
          this.alarmPopupShowData = true;
          this.LoadingService.hide();
          // if (this.AlarmInfo) {
          //   this.data = this.AlarmInfo;
          // }
        });
      } else {
        this.data = JSON.parse(JSON.stringify(this.AlarmInfo.alarmData));
        this.filldata();
        this.alarmPopupShowData = true;

      }

    }
    // this.alarmDialog.show();
    this.groupOptions = this.screen != 'refeer' ? { showDropArea: false, columns: ['ContainerID'] } : {showDropArea: false};
  }

  alarmDialogClosed() {
    // this.alarmDialog.hide();
    this.alarmDialogClose.emit(0);
  }

}
