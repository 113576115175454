import {
  ChangeDetectorRef,
  Component, Directive, ElementRef, EventEmitter, HostListener, Inject,
  Input, OnInit, Output, PLATFORM_ID, ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isNumber } from 'lodash';
declare const window: any;
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { MobileNumberCOdes } from '../../../../assets/data/CountryCodes';

const defaultUtilScript = 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.1/js/utils.js';
@Component({
  selector: 'tl-phonenumberwithcontrycode',
  templateUrl: './phonenumberwithcontrycode.component.html',
  styleUrls: ['./phonenumberwithcontrycode.component.scss']
})
export class PhonenumberwithcontrycodeComponent implements OnInit {
  @Input('ng2TelInputOptions') ng2TelInputOptions: any = {};
  @Output('hasError') hasError: EventEmitter<boolean> = new EventEmitter();
  @Output('ng2TelOutput') ng2TelOutput: EventEmitter<any> = new EventEmitter();
  @Output('countryChange') countryChange: EventEmitter<any> = new EventEmitter();
  @Output('intlTelInputObject') intlTelInputObject: EventEmitter<any> = new EventEmitter();
  @Output('changedCountryCode') changedCountryCode: EventEmitter<any> = new EventEmitter();
  @Output() messageEvent = new EventEmitter<string>();
  @Output() phoneNumberInput = new EventEmitter<string>();
  ngTelInput: any;
  @ViewChild('telInput') telInput;
  number: any;
  input: any;
  countryCode: (obj: any) => void;
  @Input() retrieveContactNumber: string;
  @Input() retrieveContactCountryCode: any;
  @Input() bindCountryCode:any;
  public errorMap = ["Invalid number", "Invalid country code",
    "Contact Number must be 10 digits",
    "Contact Number must be 10 digits",
    "Invalid number"];

  separateDialCode = true;
  selectedCountryCode: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  constructor(private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string, private cd: ChangeDetectorRef) {
    this.number = (this.retrieveContactNumber) ? this.retrieveContactNumber : '';


  }
  get f() { return this.phoneForm.controls; }

  ngOnInit() {
    // if (isPlatformBrowser(this.platformId)) {
    // this.input = document.querySelector('#p_no');
    // this.ng2TelInputOptions = {
    //   ...this.ng2TelInputOptions,
    //   utilsScript: this.getUtilsScript(this.ng2TelInputOptions)
    // };
    // this.ngTelInput = window.intlTelInput(this.input, {
    //   ...this.ng2TelInputOptions
    // });


    // this.el.nativeElement.addEventListener('ContactNumber', () => {
    //   this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
    // });
    // // this.intlTelInputObject.emit(this.ngTelInput);
    // // }
    this.number = (this.retrieveContactNumber) ? this.retrieveContactNumber : '';


  }
  ngAfterViewInit() {
    if (this.retrieveContactNumber) {
      if(!isNaN(this.retrieveContactCountryCode)){
      //   this.retrieveContactCountryCode = this.bindCountryCode;
      // }
      // else
      // {
        if(this.retrieveContactCountryCode == '' || this.retrieveContactCountryCode == null || this.retrieveContactCountryCode == undefined){
          this.retrieveContactCountryCode = 'US';
        }else{
          if (!this.retrieveContactCountryCode.includes('+') && !isNaN(this.retrieveContactCountryCode)) {
            this.retrieveContactCountryCode = '+' + this.retrieveContactCountryCode;
          }
          MobileNumberCOdes.forEach(el => {
            if (el['code'] == this.bindCountryCode.toUpperCase()) {
              this.retrieveContactCountryCode = '';
              this.retrieveContactCountryCode = el['code'].toUpperCase();
              return;
            }
          });
        }
        
      }
      
      this.selectedCountryCode = this.retrieveContactCountryCode.toUpperCase();
      if(this.selectedCountryCode == ''){
        this.selectedCountryCode = 'US';
      }
      this.phoneForm.controls.phone.setValue(`${this.retrieveContactNumber}`);
      this.cd.detectChanges();
      this.phoneForm.controls.phone.dirty;
      this.phoneForm.controls.phone.touched;

    }else{
      this.selectedCountryCode = 'US';
    }

  }
  // @HostListener('focusout') onBlur() {
  //   const isInputValid: boolean = this.phoneForm.controls['phone'].valid;
  //   if (isInputValid) {
  //     const telOutput = this.telInput.value.trim();
  //     // this.hasError.emit(isInputValid);
  //     this.hasError.emit(true);
  //     this.ng2TelOutput.emit(this.telInput.value);
  //   } else {
  //     // this.hasError.emit(isInputValid);
  //     this.hasError.emit(false);
  //   }
  // }

  public changecountryCodebasedonCountry(args) {
    //  window.intlTelInputGlobals.instances[0].setCountry(args);
    // let input =   (window.intlTelInputGlobals.instances) as object[];
    for (let i = 0; i <= Object.keys(window.intlTelInputGlobals.instances).length; i++) {
      if (window.intlTelInputGlobals.instances[i] && args) {
        window.intlTelInputGlobals.instances[i].setCountry(args);
      }
      // if (window.intlTelInputGlobals.instances[i].telInput === phoneInputObject) {
      //     // return window.intlTelInputGlobals.instances[i];
    }

  }
  getNumber(args) {
    const yy = this.number;
  }
  telInputObject(obj) {

    (this.retrieveContactCountryCode) ?
      (obj.p.map(y => y.iso2).includes(this.retrieveContactCountryCode) ?
        obj.setCountry(this.retrieveContactCountryCode) : obj.setCountry('us'))
      :
      obj.setCountry('us');

    // this.countryCode = obj;
    // this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
  }
  // telInputObject(args)
  // {
  //   const yy = this.telInputObject;
  // }
  onCountryChange(args) {
    this.countryCode = args.dialCode;
    this.changedCountryCode.emit(args);
    this.phonenumberchange('');
  }
  isInputValid(): boolean {
    return this.ngTelInput.isValidNumber();
  }

  setCountry(country: any) {
    this.ngTelInput.setCountry(country);
  }

  getUtilsScript(options: any) {
    return options.utilsScript || defaultUtilScript;
  }

  phonenumberchange(args: any) {
    let errorCode;
    // const isInputValid: boolean = this.phoneForm.controls['phone'].valid;
    // if (isInputValid) {
    //   const telOutput = this.telInput.value.trim();
    //   // this.hasError.emit(isInputValid);
    //   this.hasError.emit(true);
    //   this.ng2TelOutput.emit(this.telInput.value);
    // } else {
    //   // this.hasError.emit(isInputValid);
    //   this.hasError.emit(false);
    // }
    this.phoneNumberInput.emit(this.telInput.value);
    if (this.telInput.value.trim()) {
      if (this.phoneForm.controls['phone'].valid) {
        this.phoneNumberInput.emit('')
      } else if (this.telInput.value.length == 0) {
        this.hasError.emit(false);
        this.phoneNumberInput.emit(this.errorMap[0]);
      } else if (this.telInput.value.length > 0) {
        this.hasError.emit(false);
        this.phoneNumberInput.emit(this.errorMap[1]);
      }
    }
    this.cd.detectChanges();
    this.phoneForm.controls.phone.dirty;
    this.phoneForm.controls.phone.touched;
    //const input = args.currentTarget.value;
    // if (this.telInput.value && this.telInput.value != '') {
    //   this.phoneNumberInput.emit(this.errorMap[errorCode]);
    // }
  }

}
