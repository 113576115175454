export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
       // side navigation menu
        'Account': 'Account',
        'Dashboard': 'Dashboard',
        'Container Status': 'Container Status',
        'Notifications': 'Notifications',
        'Geofence': 'Geofence',
        'Reports': 'Reports',
        'User Management': 'User Management',
        'Company': 'Company',
        'Users': 'Users',
        'Roles': 'Roles',
        'Container Management': 'Container Management',
        'Devices': 'Devices',
        'Containers': 'Containers',
        'Fleets': 'Fleets',
        'Device Firmware': 'Device Firmware',
        'Controller Software': 'Controller Software',
        'Approve Devices': 'Approve Devices',
        "Model Config options": "Model Config options",
        'Settings': 'Settings',
        'Preferences': 'Preferences',
        'Change Password': 'Change Password',
        'Vessels': 'Vessels',
        'Upload Baplie': 'Upload Baplie',
        'Widget Settings': 'Widget Settings',
        'Alarm Settings': 'Alarm Settings',
        'Logout': 'Logout',
        'Welcome': 'Welcome',
        'Home': 'Home',
        'Device Id': 'Device Id',
        'Device': 'Device',
         // Dashboard screen strings
        'Power': 'Power (On/Off)',
        'ON': 'ON',
        'OFF': 'OFF',
        '2 Hrs': '2 Hrs',
        '2 - 4 Hrs': '2 - 4 Hrs',
        '4 Hrs': '4 Hrs',
        'Alarms': 'Alarms',
        'Critical Alarms': 'Critical Alarms',
        'Non Critical Alarms': 'Non Critical Alarms',
        'Total Alarms': 'Total Alarms',
        'Reporting Status': 'Reporting Status',
        '1 Day': '1 Day',
        '1 Day-1 Wk': '1 Day-1 Wk',
        '1 Wk-1 Mon': '1 Wk-1 Mon',
        '1 Month': '1 Month',
        'TripWise': 'TripWise',
        'Pass': 'Pass',
        'Off': 'Off',
        'Check': 'Check',
        'Expired': 'Expired',
        'Cargo_Type': 'Cargo Type',
        'Frozen': 'Frozen',
        'Perishable': 'Perishable',
        'Container_Model_Type': 'Container Model Type',
        'PrimeLINE': 'PrimeLINE',
        'ThinLINE': 'ThinLINE',
        'NaturaLINE': 'NaturaLINE',
        'EliteLINE': 'EliteLINE',
        'Controller_Software': 'Controller Software',
        'Up-to-date': 'Up-to-date',
        'Out-of-date': 'Out-of-date',
        'TripLINK_Device_Firmware': 'Lynx Fleet Device Firmware',
        'TripLINK_Expiry': 'Tripwise Expiring',
        'days' : ' days',
        'Expiry': 'Tripwise Expiry in',
        'Expiry_Count' : 'Expiry Count',
        'Container ID': 'Container ID',
        'Container_ID': 'Container ID',
        'Genset_ID': 'Genset ID',
        'Device ID': 'Device ID',
        'Device_ID': 'Device ID',
        'Mixed': 'Mixed',
        'Manufacturer': 'Manufacturer',
        'Event Time': 'Event Time',
        'Setpoint': 'Setpoint',
        'Power Status': 'Power Status',
        'Ambient Temp': 'Ambient Temp',
        'Ambient': 'Ambient',
        'RTS': 'RTS',
        'STS': 'STS',
        'Alarm Time': 'Alarm Time',
        'Last Updated': 'Last Updated',
        'Last_updated': 'Last Updated',
        'Last Location Time': 'Last Location Time',
        'Last_Location_Time': 'Last Location Time',
        'Ambient Air Temp': 'Ambient Air Temp',
        'Plugged': 'Plugged',
        'UnPlugged': 'UnPlugged',
        'Current SW Rev': 'Current SW Rev',
        'Software Status': 'Software Status',
        'Model Type': 'Model Type',
        'Alarm Number': 'Alarm Number',
        'Alarm Description': 'Alarm Description',
        'Action': 'Action',
        'Setpoint Status': 'Setpoint Status',
        'Cargo Type': 'Cargo Type',
        'Target SW Rev': 'Target SW Rev',
        'Container_Details': 'Container Details',
        'Genset_Details': 'Genset Details',
        'Vessel': 'Vessel',
        'List of Containers': 'List of Containers',
        'Model': 'Model',
         // user profile screen strings
        'User Details': 'User Details',
        'First Name is required': 'First Name is required',
        'First Name': 'First Name',
        'First Name should contain alphabets and special characters like ., -_ only': 'First Name should contain alphabets and special characters like ., -_ only',
        'Last Name': 'Last Name',
        'Last Name is required': 'Last Name is required',
        'Last Name should contain alphabets and special characters like ., -_ only': 'Last Name should contain alphabets and special characters like ., -_ only',
        'Email': 'Email',
        'UserName': 'User Name',
        'Status': 'Status',
        'Contact Number': 'Contact Number',
        'Contact Number must be 10 digits': 'Contact Number must be 10 digits',
        'Contact Number must be numeric': 'Contact Number must be numeric',
        'Contact Number do not start with 0': 'Contact Number do not start with 0',
        'Contact Number is not valid' : 'Contact Number is not valid',
        'Date Format': 'Date Format',
        'Language': 'Language',
        'Unit system': 'Unit system',
        'EULA Acceptance Date': 'EULA Acceptance Date',
        'Temperature Format': 'Temperature Format',
        'Time Zone': 'Time Zone',
        'Distance Format': 'Distance Format',
        'Company is required': 'Company is required',
        'Company Name': 'Company Name',
        'Pressure Format': 'Pressure Format',
        'Role': 'Role',
        'Role is required': 'Role is required',
        'Save': 'Save',
        'Cancel': 'Cancel',
        'Are you sure you want to change your current role?': 'Are you sure you want to change your current role?'
};

