import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { Browser } from '@syncfusion/ej2-base';
import { UnitConverterService } from '../../../Services/unit-converter.service';

@Component({
  selector: 'tl-captresults-dialog',
  templateUrl: './captresults-dialog.component.html',
  styleUrls: ['./captresults-dialog.component.scss']
})
export class CAPTResultsDialogComponent implements OnInit {

  @Input() CAPTResultsInfo: any;
  data: any;
  public confirmCloseIcon: Boolean = true;
  public hidden: Boolean = true;
  public visible: Boolean = false;
  public animationSettings: Object = { effect: 'None' };
  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;
 // public position: object = {  X: 400, Y: 200 };
  public targetElement: HTMLElement;
  public target = '.control-section';
  public isModal: Boolean = true;
  public dateFormat: string;
  StartTime;

  // Create element reference for dialog target element.
  @ViewChild('container',  {static: false}) container: ElementRef;
  @ViewChild('CAPTResultDialog', {static: false}) public CAPTResultDialog: DialogComponent;

  constructor(public unitConverterService: UnitConverterService) {
this.StartTime = null;
   }
 // Initialize the Dialog component's target element.
 public initilaizeTarget: EmitType<object> = () => {
  this.targetElement = this.container.nativeElement.parentElement;
}
public oncreated(): void {
    this.CAPTResultDialog.show(Browser.isDevice ? true : false);
}
  ngOnInit() {
    this.dateFormat = this.unitConverterService.getDateFormat();
    let data = JSON.parse(JSON.stringify(this.CAPTResultsInfo))
    if (data.captResultData) {
      data.captResultData.EventTimeUTC = this.unitConverterService.convertTimeZone(this.CAPTResultsInfo.captResultData.EventTimeUTC);
      data.captResultData.StartTime = this.unitConverterService.convertTimeZone(this.CAPTResultsInfo.captResultData.StartTime);
    }
    this.data = data.captResultData;

  }

}
