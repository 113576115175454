
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router, NavigationEnd, Route, ActivationEnd, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UserInfoService } from './userInfo.service';

@Injectable()
export class AppInsightService {
  spentTimeStart: Date;
  spendTimeEnd: Date;
  appInsights: ApplicationInsights;
  constructor(public router: Router, public activatedRoute: ActivatedRoute, private userInfoService: UserInfoService
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: false, // option to log all route changes
        enableCorsCorrelation: true,
        autoTrackPageVisitTime: true,
        correlationHeaderExcludedDomains: ['*.googleapis.com']
      }
    });
    this.appInsights.loadAppInsights();
    this.createRouterSubscription();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    let email = this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.EmailAddress
    : JSON.parse(sessionStorage.getItem('UserInfo')).EmailAddress;
    
    this.appInsights.trackEvent({ name: email }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
  minutesToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    let hours1 = (hours < 10) ? "0" + hours : hours;
    let minutes1 = (minutes < 10) ? "0" + minutes : minutes;
    let seconds1 = (seconds < 10) ? "0" + seconds : seconds;

    return hours1 + ":" + minutes1 + ":" + seconds1 + "." + milliseconds;
  }
  private createRouterSubscription() {
    let component = ''
    this.router.events.pipe(
      // identify navigation end
      filter((event) => event instanceof NavigationEnd),
      // now query the activated route
      map(() => this.rootRoute(this.activatedRoute)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
    ).subscribe((route: ActivatedRoute) => {
      if (route?.component) {
        component = route?.component['name'] || 'No Component';
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let email = this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.EmailAddress
        : JSON.parse(sessionStorage.getItem('UserInfo')).EmailAddress;
      if (email && component) {
        component = email;
      }
      this.logPageView(component, event.urlAfterRedirects);

    });
  }
  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

}
