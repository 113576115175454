import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap, catchError, finalize } from 'rxjs/operators';
import { CONFIG } from './config';
import { ExceptionService } from './exception.service';
import { environment } from '../../environments/environment';
import { AuthenticationResult } from '../Services/AuthenticationResult';
import { MSALError } from '../Services/MSALError';
import { LoadingService } from '../Services/loading.service';
import { AuthenticationParameters, ClientAuthError, Configuration, UserAgentApplication } from 'msal';
import { AccessTokenCacheItem } from 'msal/lib-commonjs/cache/AccessTokenCacheItem';
import { AuthCache } from 'msal/lib-commonjs/cache/AuthCache';


const triplinkConfig = environment.tripLinkConfig;

const msalConfig: Configuration = {
  auth: {
    clientId: triplinkConfig.clientId,
    authority: triplinkConfig.authority,
    validateAuthority: triplinkConfig.validateAuthority,
    redirectUri: triplinkConfig.redirectUri,
    postLogoutRedirectUri: triplinkConfig.postLogoutRedirectUri,
    navigateToLoginRequestUrl: triplinkConfig.navigateToLoginRequestUrl,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true  // set to true for IE 11
  }
}
@Injectable({providedIn: 'root'})
export class ServerService {
  public cacheStorage: AuthCache;

  isObjectEmpty(obj: Object) {
    return Object.keys(obj).length === 0;
  }
  getToken(url: string) : string  { 
    const headers: Headers = new Headers();
    let headers123: UserAgentApplication;
    let token;
    if (url) {
      //   this.authService.update(triplinkConfig.consentScopes);
      let tokenStored;
      //  headers123.red
      //     UserAgentApplication ua = new UserAgentApplication();
      //  let rr =  UserAgentApplication.redirectResponse
      //  if(this.authService.redirectResponse)
      //  {        
      //  }

      //  let msalConfig : Configuration ={};

      // const msalInstance = new UserAgentApplication(msalConfig);

      // let yy = (new UserAgentApplication(msalConfig));
      // let yyzz = this.authService.redirectResponse;

      //     if (!this.authService._oauthData.isAuthenticated &&
      //       !this.isObjectEmpty(this.authService._oauthData.idToken)) {
      //         this.loadingService.show();
      //      this.authService.acquireTokenSilent(triplinkConfig.consentScopes)
      //         .then((token: any) => {
      //             if (token) {
      //                 this.authService._oauthData.isAuthenticated = true;
      //                 const authenticationResult = new AuthenticationResult(token );
      //                 sessionStorage.setItem('accessToken', token);
      //                 tokenStored  = sessionStorage.getItem('accessToken');
      //                 if (tokenStored) {
      //                   token =  'Bearer ' + tokenStored;
      //                 }
      //        this.loadingService.hide();
      //             }

      //         }, (error: any) => {
      //             const errorParts = error.split('|');
      //             const msalError = new MSALError(errorParts[0], errorParts[1], '');
      //         });
      // } else {


      // let abc = 

      // let cons: TokenCacheItem = null;


      // let accessTokenCacheItem: AccessTokenCacheItem = null;
      // const tokenCacheItems = this.authService.
      // (this.clientId, account ? account.homeAccountIdentifier : null);


      // var tokenCacheItems = this.cacheStorage.getAllAccessTokens(triplinkConfig.clientId ,
      //    null);
      //    let cons = this.authService['evaluateTokenExpiration'](sessionStorage.getItem('accessToken'));

     if(!this.authService['evaluateTokenExpiration']
     (this.authService['cacheStorage']['getAllAccessTokens'](triplinkConfig.clientId, this.authService.getAccount().homeAccountIdentifier)[0]))
     {
       if(confirm('Your session has expired! please login to continue'))
       {
        sessionStorage.clear();
        this.authService.logout();
       }
      // const authparameters: AuthenticationParameters = {};
      // authparameters.scopes = triplinkConfig.consentScopes;

    //   this.authService.acquireTokenSilent(authparameters).then((token : any ) => {
    //     if (token) {
    //       // this._oauthData.isAuthenticated = true;
    //       sessionStorage.setItem('accessToken', token.accessToken);
    //       // this.loadingService.hideloadingtext();
    //       // this.broadcastService.broadcast('msal:acquireTokenSuccess',  authenticationResult);
    //       // return true;
    //       // tokenStored  = sessionStorage.getItem('accessToken');
    //       if (token.accessToken) {
    //         token = 'Bearer ' + token.accessToken;
    //       }
    //       this.loadingService.hide();
    //       return token;
    //     }
    //  });
    // }
    //  if (this.authService['redirectResponse']) {
    //     if (this.authService['redirectResponse'].expiresOn < new Date()) {
    //       const authparameters: AuthenticationParameters = {};
    //       authparameters.scopes = triplinkConfig.consentScopes;
    //      this.authService.acquireTokenPopup(authparameters).then((token : any ) => {
    //           if (token) {
    //             // this._oauthData.isAuthenticated = true;
    //             sessionStorage.setItem('accessToken', token.accessToken);
    //             // this.loadingService.hideloadingtext();
    //             // this.broadcastService.broadcast('msal:acquireTokenSuccess',  authenticationResult);
    //             // return true;
    //             // tokenStored  = sessionStorage.getItem('accessToken');
    //             if (token.accessToken) {
    //               token = 'Bearer ' + token.accessToken;
    //             }
    //             this.loadingService.hide();
    //             return token;

    //           }
        //     }, (error: any) => {
        //       debugger;
        //       console.error('Token renewal error executed');
        //         if (error instanceof ClientAuthError) {
        //             this.authService.acquireTokenSilent(authparameters);
        //             // return true;
        //        }
        //       //   if (error instanceof InteractionRequiredAuthError) {
        //       //       this.authService.acquireTokenRedirect(authparameters);
        //       //       return true;
        //       //  }
        //     });
        // }
    //     else
    //     {
    //       tokenStored = sessionStorage.getItem('accessToken');
    //       if (tokenStored) {
    //         token = 'Bearer ' + tokenStored;  
    //       }
    //       return token;
    //     }
       }
      else {
        tokenStored = sessionStorage.getItem('accessToken');
        if (tokenStored) {
          token = 'Bearer ' + tokenStored;
        }
        return token;
      }
    }
     }
  get<T>(config: any) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 
      'Authorization': this.getToken(resourceUrl), }),
      observe: 'response' as 'response'
    };
    return this.http.get<any>(this.getUrl(config), httpOptions).pipe(
      map(res => {
        return this.extractData<T>(res, config);
      }
      ),
      catchError(this.exceptionService.catchBadResponse),
      finalize(() => this.showProcess(false)));
  }

  downloadDeviceFile<T>(config: any, entity?: T) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;

    // var options: RequestOptionsArgs = {};
    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'application/octet-stream');
    // options.responseType = ResponseContentType.Blob;
    // options.headers = headers;
    // let body = JSON.stringify(entity);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream',
        'Authorization': this.getToken(resourceUrl),
      },
      ),
      responseType: 'blob' as 'blob',
      observe: 'response' as 'response'
    };

    return this.http
      .get(this.getUrl(config), httpOptions).pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  download(config: any) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': config.accept,
        'Authorization': this.getToken(resourceUrl)
      },
      ),
      responseType: 'arraybuffer' as 'arraybuffer',
      observe: 'response' as 'response'
    };
    return this.http
      .get(this.getUrl(config), httpOptions).pipe(
        map(res => {
          if (res['status'] < 200 || res['status'] >= 300) {
            throw new Error('Bad response status: ' + res['status']);
          }
          const r: any = res;
          return r._body;
        }),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  downloadPDF<T>(config: any) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': config.accept,
        'Authorization': this.getToken(resourceUrl),
      },
      ),
      observe: 'response' as 'response',
      responseType: 'blob' as 'blob'
    };
    return this.http
      .get(this.getUrl(config), httpOptions).pipe(
        map((res: any) => {
          return res.blob();
        }),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }
  downloadDCXFile<T>(config: any, entity: T) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream',
        'Authorization': this.getToken(resourceUrl)
      }),
      responseType: 'blob' as 'blob',
      observe: 'response' as 'response'
    };
    const body = JSON.stringify(entity);
    return this.http
      .post(this.getUrl(config), body, httpOptions).pipe(
        map((res: any) =>
          this.extractMessage<T>(res)
        ),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false))
      );
  }

  post<T>(config: any, entity: T) {
    config.api = config.api;
    const body = JSON.stringify(entity);
    this.showProcess(true);
    const resourceUrl = config.api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(resourceUrl),
      }),
      observe: 'response' as 'response'
    };

    return <Observable<T>>this.http
      .post(this.getUrl(config), body, httpOptions).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  put<T>(config: any, entity: T) {
    config.api = config.api;
    const body = JSON.stringify(entity);
    this.showProcess(true);
    const resourceUrl = config.api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(resourceUrl),
      }),
      observe: 'response' as 'response'
    };

    return <Observable<T>>this.http
      .put(this.getUrl(config), body, httpOptions).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  postOpenCase<T>(config: any, entity: T) {
    config.api = config.api;
    const body = JSON.stringify(entity);
    this.showProcess(true);
    const resourceUrl = config.api;
    const headers = new Headers();
    // return this.getToken(resourceUrl).mergeMap((headers: any) => {
    // var options: RequestOptionsArgs = {};
    // headers.append('Content-Type', 'application/json');
    // options.headers = headers;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // inputHeaders
      }),
      observe: 'response' as 'response'
    };
    return <Observable<T>>this.http
      .post(config.url, body, httpOptions).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));

    // });
  }


  upload<T>(config: any, entity: any) {
    config.api = config.api;
    const body = entity;
    this.showProcess(true);
    const resourceUrl = config.api;
    // var options: RequestOptionsArgs = {};
    // headers.append('Content-Type', 'multipart/form-data');
    // options.headers = headers;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': this.getToken(resourceUrl),
      }),
      observe: 'response' as 'response'
    };

    return <Observable<T>>this.http
      .post(this.getUrl(config), body).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  uploadData<T>(config: any, entity: any) {
    config.api = config.api;
    const body = entity;
    this.showProcess(true);
    const resourceUrl = config.api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.getToken(resourceUrl),
      }),
      observe: 'response' as 'response'
    };

    return <Observable<T>>this.http
      .post(this.getUrl(config), body, httpOptions).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }
  postQuery<T>(config: any, entity?: T) {
    if (entity) {
      config.query = Object.getOwnPropertyNames(entity).map(key => key + '=' + entity[key]).join('&');
    }
    this.showProcess(true);
    const resourceUrl = config.api;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.getToken(resourceUrl),
      }),
      observe: 'response' as 'response'
    };


    return <Observable<T>>this.http
      .post(this.getUrl(config), '', httpOptions).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  postAndQuery<T>(config: any, entity: T, queryEntity?: T) {
    if (queryEntity) {
      config.query = Object.getOwnPropertyNames(queryEntity).map(key => key + '=' + queryEntity[key]).join('&');
    }
    config.api = config.api;
    const body = JSON.stringify(entity);
    this.showProcess(true);
    const resourceUrl = config.api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(resourceUrl)
      }),
      observe: 'response' as 'response'
    };

    return <Observable<T>>this.http
      .post(this.getUrl(config), body, httpOptions).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }


  delete<T>(config: any) {
    this.showProcess(true);
    const resourceUrl = config.api;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.getToken(resourceUrl),

      }),
      observe: 'response' as 'response'
    };

    return <Observable<T>>this.http
      .delete(this.getUrl(config), httpOptions).pipe(
        map(res => this.extractMessage<T>(res)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  update<T>(config: any, entity: T, id: any) {
    config.id = id;
    const body = JSON.stringify(entity);
    this.showProcess(true);

    return <Observable<T>>this.http
      .put(this.getUrl(config), body).pipe(map(res => this.extractData<T>(res)), catchError(this.exceptionService.catchBadResponse), finalize(() => this.showProcess(false)));

  }

  constructor(
    private http: HttpClient,
    // private msalService: AdalService,
    private loadingService: LoadingService,
    private exceptionService: ExceptionService, private authService: MsalService
    // ,private spinnerService: SpinnerService
  ) {

  }

  private getUrl(config: any) {
    const api = config.id ? `${config.api}/${config.id}` : config.api,
      url = `${api}/${config.query ? config.url + config.query : config.url}`;

    // return CONFIG.api.subscriptionKey ? `${url}${url.indexOf('?') < 0 ? '?' : '&'}subscription-key=${CONFIG.api.subscriptionKey}` : url;
    return url;
  }
  getConfigResponse(config?: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      this.getUrl(config), { observe: 'response' });
  }
  private extractData<T>(res: any, config?: any) {
    if (res.status < 200 || res.status >= 300) {
      throw {
        type: 'Bad response',
        data: res
      };
    }
    const response = this.getConfigResponse(config);
    const r: any = res;
    let body: any = r;
    if (!body) {
      body = null;
    } else {
      // let first = body.substr(0, 1);
      // if ((first == '{' || first == '[') && res.body.json) {
      //  body={};
      // body.responseData = res.json();
      // if (body.json) {
      body = res.body;
      body.httpstatus = res.status;
      body.httpok = res.ok;
      body.httpstatusText = res.statusText;
      body.httptype = res.type;
      // }
    }
    if (!body) {
      body = [];
      return <T>body;
    }
    if (config && config.extractData) {
      return <T>config.extractData(body);
    }
    return body;
  }

  private extractMessage<T>(res: any, config?: any) {
    if (res.status < 200 || res.status >= 300) {
      throw {
        type: 'Bad response',
        data: res
      };
    }
    const r: any = res;
    let body: any = r;
    if (!body) {
      body = null;
    } else {
      // let first = body.substr(0, 1);
      // if ((first == '{' || first == '[') && res.json) {
      //  body={};
      // body.responseData = res.json();
      // body = res.json();
      body = res.body;
      body.httpstatus = res.status;
      body.httpok = res.ok;
      body.httpstatusText = res.statusText;
      body.httptype = res.type;
      // }
    }
    if (!body) {
      body = [];
      return <T>body;
    }
    if (config && config.extractData) {
      return <T>config.extractData(body);
    }
    return <T>body;
  }

  private showProcess(show: Boolean) {
    if (show) {
      // this.spinnerService.show();
    } else {
      // this.spinnerService.hide();
    }
  }

  getblob<T>(config: any) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.getToken(resourceUrl),
      }),
      observe: 'response' as 'response'
    };

    return this.http
      .get(this.getblobUrl(config), httpOptions).pipe(
        map(res => this.extractData<T>(res, config)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  getReports<T>(config: any) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.getToken(resourceUrl),

      }),
      observe: 'response' as 'response'
    };

    return this.http
      .get(this.getUrl(config), httpOptions).pipe(
        map(res => this.extractData<T>(res, config)),
        catchError(this.exceptionService.catchBadResponse),
        finalize(() => this.showProcess(false)));
  }

  private getblobUrl(config: any) {
    const api = config.id ? `${config.api}/${config.id}` : config.api,
      url = `${config.query ? config.url + config.query : config.url}`;
    return url;
  }

  getMultipleDaysblob<T>(config: any) {
    config.api = config.api;
    this.showProcess(true);
    const resourceUrl = config.api;

    // var options: RequestOptionsArgs = {};

    const blobURLS = [];

    const responseData = [];

    config.url.forEach((url) => {
      const urlPath = this.http.get(url);
      blobURLS.push(urlPath);
    });

    return forkJoin(blobURLS).pipe(map((res: any[]) => {
      res.forEach((response) => {
        const responseBody = this.extractData<T>(response, config);
        responseData.push(responseBody);
      });
      return responseData;
    }), catchError(this.exceptionService.catchBadResponse), finalize(() => this.showProcess(false)));
  }
}