import { Injectable } from '@angular/core';
import { menuItems } from './menu';
import { filtermenuItems } from './menu';
import { Observer, Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class MenuService {

  companyLogo$ = new BehaviorSubject<any>(null);

  public getMenuItems(): Array<Object> {
    return menuItems;
  }
  public getFilterMenuItems(): Array<Object> {
    return filtermenuItems;
  }

  public setCompanyLogo(company) {
     this.companyLogo$.next(company);
  }

  public getCompanyLogo(): Observable<any> {
    return this.companyLogo$.asObservable();
  }

}
