
import { Injectable } from '@angular/core';
import { LoadingService } from '../../Services/loading.service';
import { ToastrService} from 'ngx-toastr';

@Injectable()
export class SuccessFailureService {
    constructor(public loadingService: LoadingService, public toastr: ToastrService) {
    }

    public successFailure(response, msg?): void {
        if (response.Status) {
            this.loadingService.hide();
            if (msg) {
                this.toastr.success(msg.success, '',
                    { timeOut: 2000 });
            }
        } else {
            this.loadingService.hide();
            if (msg) {
                this.toastr.warning(msg.failed, '',
                    { timeOut: 2000 });
            }
        }
    }
    public successFailureReturn(response, msg? , increase?): boolean {
        switch (response.httpstatus) {
            case 200:
                if (msg) {
                    this.toastr.success(msg, '',
                        { timeOut: increase ? increase : 2000  });
                    this.loadingService.hide();
                    return true;
                } else {
                    this.loadingService.hide();
                    return true;
                }
            case 404: 
                this.loadingService.hide();
                return true;
            default:
                if (response.messages && response.messages.length > 0) {
                    if (JSON.parse(response.messages[0]).Message) {
                        this.toastr.warning(JSON.parse(response.messages[0]).Message, '',
                            { timeOut: 2000 });
                        this.loadingService.hide();
                        return false;
                    }
                }  else if (response.message && response.message.Message)  {
                    this.toastr.warning(response.message.Message, '',
                        { timeOut: 2000 });
                    this.loadingService.hide();
                    return false;
                } else if (typeof response.message === 'string' ) {
                    this.toastr.warning(response.message, '',
                    { timeOut: 2000 });
                    this.loadingService.hide();
                    return false;
                }
                else {
                    this.toastr.warning('Unable to process the request', '',
                        { timeOut: 2000 });
                    this.loadingService.hide();
                    return false;
                }
        }
    }

}
