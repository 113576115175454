import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../../Services/loading.service';

@Component({
  selector: 'tl-error',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './error.component.html'
})
export class ErrorComponent {
    router: Router;

    constructor(router: Router, loadingService: LoadingService) {
        this.router = router;
        loadingService.hide();
    }

    searchResult(): void {
        this.router.navigate(['app/pages/search']);
    }
    previous() {
      this.router.navigate(['app/dashboard']);
    }
}
