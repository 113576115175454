// lang-zh.ts

export const LANG_DE_NAME = 'ru';

export const LANG_RU_TRANS = {
        // side navigation menu
        'Account': 'учетная запись',
        'Dashboard': 'Панель приборов',
        'Container Status': 'Состояние контейнера',
        'Notifications': 'Уведомления',
        'Geofence': 'геозоны',
        'Reports': 'Отчеты',
        'User Management': 'Управление пользователями',
        'Company': 'Компания',
        'Users': 'пользователей',
        'Roles': 'Роли',
        'Container Management': 'Управление контейнером',
        'Devices': 'приборы',
        'Containers': 'Контейнеры',
        'Fleets': 'Флоты',
        'Device Firmware': 'Прошивка устройства',
        'Controller Software': 'Программное обеспечение контроллера',
        'Approve Devices': 'Утвердить устройства',
        'Settings': 'настройки',
        'Preferences': 'предпочтения',
        'Change Password': 'Сменить пароль',
        'Vessels': 'сосуды',
        'Upload Baplie': 'Загрузить Baplie',
        'Widget Settings': 'Настройки виджета',
        'Alarm Settings': 'Настройки будильника',
        'Logout': 'Выйти',
        'Welcome': 'желанный',
        'Home': 'Домой',
        'Device Id': 'Идентификатор устройства',
        'Device': 'устройство',
        // Dashboard screen strings
        'Power': 'Питание (вкл / выкл)',
        'ON': 'вкл',
        'OFF': 'выкл',
        '2 Hrs': '2 часа',
        '2 - 4 Hrs': '2 - 4 часа',
        '4 Hrs': '4 часа',
        'Alarms': 'сигнализация',
        'Critical Alarms': 'Критические тревоги',
        'Non Critical Alarms': 'Некритические тревоги',
        'Total Alarms': 'Всего сигналов',
        'Reporting Status': 'Статус отчетности',
        '1 Day': '1 день',
        '1 Day-1 Wk': '1 день-1 нед.',
        '1 Wk-1 Mon': '1 нед-1 пн',
        '1 Month': '1 месяц',
        'TripWise': 'Мудрое путешествие',
        'Pass': 'Проходить',
        'Off': 'выкл',
        'Check': 'Проверьте',
        'Expired': 'Истекший',
        'Cargo_Type': 'Тип груза',
        'Frozen': 'замороженный',
        'Perishable': 'скоропортящийся',
        'Container_Model_Type': 'Тип модели контейнера',
        'PrimeLINE': 'главная линия',
        'ThinLINE': 'Тонкая линия',
        'NaturaLINE': 'Натуральная ЛИНИЯ',
        'EliteLINE': 'Элит Лайн',
        'Controller_Software': 'Программное обеспечение контроллера',
        'Up-to-date': 'До настоящего времени',
        'Out-of-date': 'Устаревший',
        'TripLINK_Device_Firmware': 'Прошивка устройства Lynx Fleet',
        'Container ID': 'ID контейнера',
        'Container_ID': 'ID контейнера',
        'Genset_ID': 'Genset ID',
        'Device ID': 'Идентификатор устройства',
        'Device_ID': 'Идентификатор устройства',
        'Event Time': 'Время события',
        'Setpoint': 'Заданная',
        'Power Status': 'Состояние питания',
        'Ambient Temp': 'Температура окружающей среды',
        'Ambient': 'окружающей среды',
        'RTS': 'RТS',
        'STS': 'STS',
        'Alarm Time': 'Время будильника',
        'Last Updated': 'Последнее обновление',
        'Last_updated': 'Последнее обновление',
        'Last Location Time': 'Last Location Time',
        'Last_Location_Time': 'Last Location Time',
        'Ambient Air Temp': 'Температура окружающего воздуха',
        'Plugged': 'Сменная',
        'UnPlugged': 'UnPlugged',
        'Current SW Rev': 'Текущий SW Rev',
        'Software Status': 'Состояние программного обеспечения',
        'Model Type': 'Тип модели',
        'Alarm Number': 'Номер тревоги',
        'Alarm Description': 'Описание тревоги',
        'Action': 'действие',
        'Setpoint Status': 'Состояние заданного значения',
         'Cargo Type':  'Тип груза',
        'Target SW Rev': 'Целевое программное обеспечение rev',
        'Container_Details': 'Подробности контейнера',
        'Genset_Details': 'Сведения о генераторной установке',
        'Vessel': 'судно',
        'List of Containers': 'Список контейнеров',
        'Model': 'Модель',
        // user profile screen strings
        'User Details': 'Данные пользователя',
        'First Name is required': 'Имя обязательно',
        'First Name': 'Имя',
        'First Name should contain alphabets and special characters like ., -_ only': 'Имя должно содержать алфавиты и специальные символы, такие как., -_ только',
        'Last Name': 'Фамилия',
        'Last Name is required': 'Фамилия обязательна',
        'Last Name should contain alphabets and special characters like ., -_ only': 'Фамилия должна содержать алфавиты и специальные символы, такие как., Только -_',
        'Email': 'Эл. адрес',
        'UserName': 'имя пользователя',
        'Status': 'Положение дел',
        'Contact Number': 'Контактный телефон',
        'Contact Number must be 10 digits': 'Контактный номер должен быть 10 цифр',
        'Contact Number must be numeric': 'Контактный номер должен быть числовым',
        'Contact Number do not start with 0': 'Контактный номер не начинается с 0',
        'Contact Number is not valid': 'Kонтактный номер недействителен',
        'Date Format': 'Формат даты',
        'Language': 'язык',
        'Unit system': 'Система единиц',
        'EULA Acceptance Date': 'Дата принятия EULA',
        'Temperature Format': 'Формат температуры',
        'Time Zone': 'Часовой пояс',
        'Distance Format': 'Формат расстояния',
        'Company is required': 'Компания требуется',
        'Company Name': 'название компании',
        'Pressure Format': 'Формат давления',
        'Role': 'Роль',
        'Role is required': 'Роль обязательна',
        'Save': 'Сохранить',
        'Cancel': 'Отмена',
        'Are you sure you want to change your current role?': 'Вы уверены, что хотите изменить свою текущую роль?'
};

