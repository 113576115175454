import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private _filterApplied = new BehaviorSubject(false);
  private _getFiltersCompChanged = new BehaviorSubject({isCompChanged: false , currentCompany: ""});
  private _refreshApplied = new BehaviorSubject(false);

  constructor() { }

  get isFilterApplied() {
    return this._filterApplied.asObservable();
  }

  filterApplied(value: boolean) {
    this._filterApplied.next(value);
  }

  get isFiltersCompChanged() {
    return this._getFiltersCompChanged.asObservable();
  }

  filtersCompChanged(value: any) {
    this._getFiltersCompChanged.next(value);
  }

  refreshApplied(value: boolean) {
    this._refreshApplied.next(value);
  }

  get isRefreshApplied() {
    return this._refreshApplied.asObservable();
  }
}
