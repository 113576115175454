import { Component, OnInit, OnDestroy, ViewChildren } from '@angular/core';
import { SearchService } from './search.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'tl-search',
  styleUrls: ['./search.component.scss'],
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, OnDestroy {
  containerID = '';
  searchTitle = 'Search';
  searchSubcription: Subscription;
  containerSearchSubscribe: Subscription;
  mapLoadedSubscription: Subscription;
  clearSubcribe: Subscription;
  searchTitleSubcribe: Subscription;
  enableSearch = false;
  gensetSearchSubscribe: Subscription;

  @ViewChildren('txtContainerID') txtContainerID;

  constructor(public searchSrvc: SearchService, public router: Router) {
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd && e.url === '/app/pages/containerstatus') {
        this.enableSearch = true;
      }
    });
  }

  ngOnInit() {
    this.searchTitleSubcribe = this.searchSrvc.searchTitleBehavior$
      .subscribe((title: string) => {
        if (title) {
          this.searchTitle = title;
        }
      });
    this.searchSrvc.searchBehavior$.subscribe((searchedContainerID: string) => {
      this.containerID = searchedContainerID;
    });

    this.containerSearchSubscribe = this.searchSrvc.containerSearchBehavior$.subscribe((searchedContainerID: string) => {
      this.containerID = searchedContainerID;
    });

    this.gensetSearchSubscribe = this.searchSrvc.gensetSearchBehavior$.subscribe((searchedContainerID: string) => {
      this.containerID = searchedContainerID;
    });

    this.mapLoadedSubscription = this.searchSrvc.mapLoadedBehaviour$.subscribe((data: string) => {
      if(this.router.url == '/app/dashboard'){
        this.enableSearch = false;
        if(data === 'loaded'){
         this.enableSearch = true;
        }
      } else {
        this.enableSearch = true;
      }
     
    });
  }

  clearContainerSearch() {
    this.containerID = '';
    if (this.router.url == '/app/pages/containerstatus') {
      this.searchSrvc.containerStatus(this.containerID);
    } else if (this.router.url == '/app/dashboard') {
      this.searchSrvc.updateSearchTerm(this.containerID);
    } else if (this.router.url == '/app/pages/gensetstatus') {
      this.searchSrvc.gensetStatus(this.containerID)
    }
  }

  searchByContainerID(evt) {
   // let searchedContainerId = this.containerID !== '' ? this.containerID : evt.target.value;
    let searchedContainerId = evt?.target?.value;
    if (this.router.url == '/app/pages/containerstatus') {
      this.searchSrvc.containerStatus(searchedContainerId);
    } else if (this.router.url == '/app/dashboard') {
      this.searchSrvc.updateSearchTerm(searchedContainerId);
    } else if (this.router.url == '/app/pages/gensetstatus') {
      this.searchSrvc.gensetStatus(searchedContainerId)
    }
    else if (this.router.url == '/app/trips/tripsStatus') {
      this.searchSrvc.TripNameSearch(searchedContainerId)
    }
  }


  searchInput(e) {
    if (!e.value) {
      if (this.router.url == '/app/pages/containerstatus') {
        this.containerID = '';
        this.searchSrvc.containerStatus('');
      } else if (this.router.url == '/app/dashboard') {
        this.containerID = '';
        this.searchSrvc.updateSearchTerm('');
      }
      else if (this.router.url == '/app/pages/gensetstatus') {
        this.containerID = '';
        this.searchSrvc.gensetStatus('');
      }
    }
  }

  ngOnDestroy() {
    if (this.containerSearchSubscribe) {
      this.containerSearchSubscribe.unsubscribe();
    }
    if (this.searchSubcription) {
      this.searchSubcription.unsubscribe();
    }
    if (this.searchTitleSubcribe) {
      this.searchTitleSubcribe.unsubscribe();
    }
    if (this.clearSubcribe) {
      this.clearSubcribe.unsubscribe();
    }
    if(this.mapLoadedSubscription){
      this.mapLoadedSubscription.unsubscribe();
    }
    if(this.gensetSearchSubscribe) {
      this.gensetSearchSubscribe.unsubscribe();
    }
  }
}
