import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../Services/loading.service';
import { Router } from '@angular/router';
import { ApiService } from '../Services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CONFIG } from '../Core/config';
import { DatePipe } from '@angular/common';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'tl-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.scss']
})
export class TermsofuseComponent implements OnInit {
  showButtons = false;
  userInfo: any;
  constructor(private loadingService: LoadingService, private router: Router, private authService: MsalService,
    private datepipe: DatePipe,
    private apiService: ApiService, public toastrService: ToastrService) {
    this.userInfo = this.router.getCurrentNavigation() != null ?
      (this.router.getCurrentNavigation().extras.state) ? this.router.getCurrentNavigation().extras.state.data : '' : null;
    if (this.userInfo) {
      this.showButtons = true;
    }
  }

  ngOnInit() {
    if (this.userInfo) {
      this.showButtons = true;
    }
    this.loadingService.hide();
  }
  Agree() {
    this.updateUser();
  }
  updateUser() {
    this.loadingService.show();
    const UserData = {
      'UserID': this.userInfo.UserID,
      'EULADate': new Date().toISOString()
    };
    this.apiService.updateUserEulaDate(UserData).subscribe(res => {
      if (res) {
        const updatedData = JSON.parse(sessionStorage.getItem('UserInfo'));
        updatedData.EulaAcknowledgedDate = this.datepipe.transform(new Date(),
          'yyyy-MM-ddThh:mm:ss');
        sessionStorage.setItem('UserInfo', JSON.stringify(updatedData));
        this.router.navigate([CONFIG.ROUTE_URLS.homepage]);
      }
      this.loadingService.hide();
    });


  }
  Disagree() {
    sessionStorage.clear();
    this.authService.logout();
  }
}
