import { Injectable } from '@angular/core';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ExceptionService {
    constructor(
    ) { }

    catchBadResponse: (errorResponse: any) => Observable<any> = (errorResponse: any) => {
        (errorResponse);
        const errorInfo: any = {
            title: 'Exception',
            messages: [],
        };
        if (errorResponse._body) {
            errorInfo.messages.push(errorResponse._body);
            delete errorResponse._body;
        }


        const res = errorResponse;
        const err = res;
        const emsg = err ?
            (err.error ? err.error : (err.Message ? err.Message : JSON.stringify(err))) :
            (res.statusText || 'unknown error');
        errorInfo.message = emsg;
       errorInfo.httpstatus = errorResponse.status;
       errorInfo.httpok = errorResponse.ok;
       errorInfo.httptype = errorResponse.emsg;
         return of(errorInfo);
    }
}
