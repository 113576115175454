import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
    PageSettingsModel, ToolbarItems,
    GridComponent, FilterSettingsModel, PdfExportProperties, ExcelExportProperties
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CONFIG } from '../../../Core/config';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { CustomSyncFusionGridService } from '../../Services/customSyncfusionGrid.service';
import { UserInfoService } from '../../../Services/userInfo.service';
import { UnitConverterService } from '../../../Services/unit-converter.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { LoadingService } from '../../../Services/loading.service';
import { TranslateService } from 'src/language-translation';


@Component({
    selector: 'app-refeer-popup',
    templateUrl: './refeer-popup.component.html',
    styleUrls: ['./refeer-popup.component.scss']
})
export class RefeerPopupComponent implements OnInit {
    @Input() RefeerInfo: any;
    @ViewChild('refeertab', { static: false }) tabObj: TabComponent;
    @ViewChild('setPointtab', { static: false }) setPointtabObj: TabComponent;
    @ViewChild('ejDialog', { static: false }) ejDialog: DialogComponent;
    @ViewChild('refeerDialog', { static: false }) public refeerDialog: DialogComponent;
    @ViewChild('upToDateGrid', { static: false }) public upToDateGrid: GridComponent;
    @ViewChild('outOfDateGrid', { static: false }) public outOfDateGrid: GridComponent;
    @ViewChild('frozenGrid', { static: false }) public frozenGrid: GridComponent;
    @ViewChild('perishableGrid', { static: false }) public perishableGrid: GridComponent;
    @Output() reeferDialogClose = new EventEmitter();

    public confirmCloseIcon: Boolean = true;
    refeerCount: number;
    totalCount: number;
    name: string;
    refeerResponseData: any[];
    TabName: any[];
    Group: any[];
    public data: any;
    public confirmHeader;
    fileName;
    public upToDateData: Object[] = [];
    public outOfDateData: Object[] = [];

    public setPointCount: number;
    public frozenData: Object[] = [];
    public perishableData: Object[] = [];
    public showContainerId = true;
    public showContainerIdForSoftware = true;
    public measurementUnit = 'Metric';
    public tempUnit = 'C';



    public showDeviceData: Boolean = false;
    public showControllerData: Boolean = false;
    public showSetPointData: Boolean = false;

    public visible: Boolean = true;
    public hidden: Boolean = true;
    public toolbarOptions: ToolbarItems[] | object;
    public filterOptions: FilterSettingsModel;
    public pageSettings: PageSettingsModel;
    public formatOptions: object;
    public dateFormat: string;
    public decimalpoint: any;
    public target = '.control-section';
    // public position: object = { X: 'center', Y: 'center' };
    public animationSettings: Object = { effect: 'None' };
    public isModal: Boolean = true;
    public dialogdragging: Boolean = true;
    public dialogResize: Boolean = true;

    constructor(public datepipe: DatePipe, public customSyncFusionGridService: CustomSyncFusionGridService,
        public router: Router, private userInfoService: UserInfoService, public dashboardService: DashboardService,
        public UnitConverterService: UnitConverterService, private loadingService: LoadingService,
        private _translate: TranslateService

    ) {
        this.filterOptions = {
            type: 'Excel'
        };
        // const passseddata = this.router.getCurrentNavigation() != null ?
        // this.router.getCurrentNavigation().extras.state.data : null;
        // this.confirmDialog.show();
        this.pageSettings = { pageSize: 10 };
        this.dateFormat = this.UnitConverterService.getDateFormat();
        this.formatOptions = { type: 'dateTime', format: this.dateFormat + ' hh:mm:ss a' };
        this.decimalpoint =  'N2' ; // { format: 'N2' };
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
        this.measurementUnit = this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.UnitConversionType
            : ((sessionStorage.getItem('UserInfo')) ? JSON.parse(sessionStorage.getItem('UserInfo')).UnitConversionType : '');

        if (this.measurementUnit == 'Imperial') {
            this.tempUnit = 'F';
        }
    }

    filldata() {
        // if (this.RefeerInfo) {
        //     this.data = this.RefeerInfo;
        // }
        if (this.data) {
            if (this.RefeerInfo['name'] === 'device' || this.RefeerInfo['name'] === 'controller') {
                this.refeerCount = this.data.OutofDateCount
                    + this.data.UptoDateCount;
                if (this.RefeerInfo['name'] === 'device') {
                    this.fileName = 'TripLINKDeviceFirmware';
                    this.confirmHeader = this._translate.translate('TripLINK_Device_Firmware') + '(' + this.refeerCount + ')';
                    if (this.data.OutofDateList) {
                        this.data.OutofDateList.map((item: any) => {
                            item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                            if (this.tempUnit === 'F') {
                                item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                            } else if (this.tempUnit === 'C') {
                                item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                            }
                        });
                    }
                    if (this.data.UptoDateList) {
                        this.data.UptoDateList.map((item: any) => {
                            item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                            if (this.tempUnit === 'F') {
                                item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                            } else if (this.tempUnit === 'C') {
                                item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                            }
                        });
                    }
                    this.upToDateData = DataUtil.parse.parseJson(this.data.UptoDateList);
                    this.outOfDateData = DataUtil.parse.parseJson(this.data.OutofDateList);
                }
                if (this.RefeerInfo['name'] === 'controller') {
                    this.fileName = 'ControllerSoftware';
                    this.confirmHeader = this._translate.translate('Controller_Software') + '(' + this.refeerCount + ')';
                    if (this.data.OutofDateList) {
                        this.data.OutofDateList.map((item: any) => {
                            item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                            if (this.tempUnit === 'F') {
                                item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                            } else if (this.tempUnit === 'C') {
                                item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                            }
                        });
                    }
                    if (this.data.UptoDateList) {
                        this.data.UptoDateList.map((item: any) => {
                            item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                            if (this.tempUnit === 'F') {
                                item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                            } else if (this.tempUnit === 'C') {
                                item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                            }
                        });
                    }
                    this.upToDateData = DataUtil.parse.parseJson(this.data.UptoDateList);
                    this.outOfDateData = DataUtil.parse.parseJson(this.data.OutofDateList);
                }
                this.showContainerIdForSoftware = (this.data.fromHistorypage) ? this.data.fromHistorypage : false;
            } else if (this.RefeerInfo['name'] === 'setPoint') {
                this.setPointCount = this.data.FrozenCount
                    + this.data.PersihableCount;
                if (this.data.FrozenList) {
                    this.data.FrozenList.map((item: any) => {
                        item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                        item.SetpointStatus = item.SetpointStatus ? this.capitalizeFirstLetter(item.SetpointStatus) : '';
                        if (this.tempUnit === 'F') {
                            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                        } else if (this.tempUnit === 'C') {
                            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                        }
                    });
                }
                if (this.data.PersihableList) {
                    this.data.PersihableList.map((item) => {
                        item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                        item.SetpointStatus = item.SetpointStatus ? this.capitalizeFirstLetter(item.SetpointStatus) : '';
                        if (this.tempUnit === 'F') {
                            item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                        } else if (this.tempUnit === 'C') {
                            item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                        }
                    });
                }
                this.frozenData = DataUtil.parse.parseJson(this.data.FrozenList);
                this.perishableData = DataUtil.parse.parseJson(this.data.PersihableList);
                this.confirmHeader = this._translate.translate('Cargo_Type') + '(' + this.setPointCount + ')';
                this.fileName = 'CargoType';
                this.showContainerId = (this.data.fromHistorypage) ? this.data.fromHistorypage : false;
            }
        }
    }
     capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }
      
    ngOnInit() {
        if (this.RefeerInfo && this.RefeerInfo.name == 'setPoint') {
            this.loadingService.show();
            this.dashboardService.getSetPointGeofenceDetails(this.RefeerInfo.refeerData.apiObject).subscribe(response => {
                this.data = response;
                this.filldata();
                this.showSetPointData = true;
                this.loadingService.hide();
            });
        }
        if (this.RefeerInfo && this.RefeerInfo.name == 'device') {
            this.loadingService.show();
            this.dashboardService.getDeviceGeofenceDetails(this.RefeerInfo.refeerData.apiObject).subscribe(response => {
                this.data = response;
                this.filldata();
                this.showDeviceData = true;
                this.loadingService.hide();
            });
        }
        if (this.RefeerInfo && this.RefeerInfo.name == 'controller') {
            this.loadingService.show();
            this.dashboardService.getControllerGeofenceDetails(this.RefeerInfo.refeerData.apiObject).subscribe(response => {
                this.data = response;
                this.filldata();
                this.showControllerData = true;
                this.loadingService.hide();
            });
        }

    }

    public tabCreated(): void {
        this.tabObj.selectedItem = this.RefeerInfo.activeTab;
        // args.selectedIndex = this.data.activeTab;

    }


    public setPointTabCreated(): void {
        this.setPointtabObj.selectedItem = this.RefeerInfo.activeTab;
        // args.selectedIndex = this.data.activeTab;

    }
    dataBound(type) {
        if (type === 'uptodate') {
            this.customSyncFusionGridService.showNoRecordstoView(this.upToDateGrid, this.upToDateData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.upToDateGrid.autoFitColumns();
            let toolObjCritical;
            let iscriticalhide;
            toolObjCritical = this.upToDateGrid.toolbarModule.toolbar;
            iscriticalhide = this.upToDateGrid.currentViewData.length ? false : true;
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
        }
        if (type === 'outofdate') {
            this.customSyncFusionGridService.showNoRecordstoView(this.outOfDateGrid, this.outOfDateData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.outOfDateGrid.autoFitColumns();
            let toolObjActive;
            let isactivehide;
            toolObjActive = this.outOfDateGrid.toolbarModule.toolbar;
            isactivehide = this.outOfDateGrid.currentViewData.length ? false : true;
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'PDF Export'), isactivehide);
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'Excel Export'), isactivehide);
        }


    }
    sePointDataBound(type) {
        if (type === 'frozen') {
            this.customSyncFusionGridService.showNoRecordstoView(this.frozenGrid, this.frozenData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.frozenGrid.autoFitColumns();
            let toolObjCritical;
            let iscriticalhide;
            toolObjCritical = this.frozenGrid.toolbarModule.toolbar;
            iscriticalhide = this.frozenGrid.currentViewData.length ? false : true;
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
        }
        if (type === 'perishable') {
            this.customSyncFusionGridService.showNoRecordstoView(this.perishableGrid, this.perishableData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.perishableGrid.autoFitColumns();
            let toolObjActive;
            let isactivehide;
            toolObjActive = this.perishableGrid.toolbarModule.toolbar;
            isactivehide = this.perishableGrid.currentViewData.length ? false : true;
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'PDF Export'), isactivehide);
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'Excel Export'), isactivehide);
        }
    }




    toolbarClick(args: ClickEventArgs, type): void {
        const pdfExportProperties: PdfExportProperties = {
            pageOrientation: 'Landscape',
            fileName: this.fileName + '.pdf'
        };
        switch (args.item.text) {
            case 'PDF Export':
                type === 'uptodate' ? this.upToDateGrid.pdfExport(pdfExportProperties) : this.outOfDateGrid.pdfExport(pdfExportProperties);
                break;
            case 'Excel Export':
                const excelExportProperties: ExcelExportProperties = {
                    fileName: this.fileName + '.xlsx'
                };
                type === 'uptodate' ? this.upToDateGrid.excelExport(excelExportProperties) : this.outOfDateGrid.excelExport(excelExportProperties);
                break;
            case 'CSV Export':
                type === 'uptodate' ? this.upToDateGrid.csvExport() : this.outOfDateGrid.csvExport();
                break;
        }
    }

    setPointToolbarClick(args: ClickEventArgs, type): void {
        const pdfExportProperties: PdfExportProperties = {
            pageOrientation: 'Landscape',
            fileName : this.fileName + '.pdf'
        };
        switch (args.item.text) {
            case 'PDF Export':
                type === 'frozen' ? this.frozenGrid.pdfExport(pdfExportProperties) : this.perishableGrid.pdfExport(pdfExportProperties);
                break;
            case 'Excel Export':
                const excelExportProperties: ExcelExportProperties = {
                    fileName: this.fileName + '.xlsx'
                };
                type === 'frozen' ? this.frozenGrid.excelExport(excelExportProperties) : this.perishableGrid.excelExport(excelExportProperties);
                break;
            case 'CSV Export':
                type === 'frozen' ? this.frozenGrid.csvExport() : this.perishableGrid.csvExport();
                break;
        }
    }

    navigateAssetHistory(invNumData, type) {
        let invNum;
        switch (type) {
            case 'frozen':
                invNum = this.frozenGrid.getRowInfo(invNumData.target).rowData;
                break;
            case 'perishable':
                invNum = this.perishableGrid.getRowInfo(invNumData.target).rowData;
                break;
            default:
                break;
        }
        const assetdata = {
            'startdate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
            'endDate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
        };
        const data = { type: 'dashboard', data: assetdata };
        this.loadingService.show();
        this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });

    }

    navigateAssetHistorySoftware(invNumData, type, main) {
        let invNum;
        if (main == 'device') {
            switch (type) {
                case 'outofdate':
                    invNum = this.outOfDateGrid.getRowInfo(invNumData.target).rowData;
                    break;
                case 'uptodate':
                    invNum = this.upToDateGrid.getRowInfo(invNumData.target).rowData;
                    break;
                default:
                    break;
            }
            const devicedata = {
                'startdate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
                'endDate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
            };
            const data = { type: 'dashboard', data: devicedata };
            this.loadingService.show();
            this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });

        } else if (main == 'controller') {
            switch (type) {
                case 'outofdate':
                    invNum = this.outOfDateGrid.getRowInfo(invNumData.target).rowData;
                    break;
                case 'uptodate':
                    invNum = this.upToDateGrid.getRowInfo(invNumData.target).rowData;
                    break;
                default:
                    break;
            }
            const controlerdata = {
                'startdate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
                'endDate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
            };
            const data = { type: 'dashboard', data: controlerdata };
            this.loadingService.show();
            this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });

        }


    }

    reeferDialogClosed() {
        this.reeferDialogClose.emit(0);
    }

}
