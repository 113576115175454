import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { RequestuseraccountComponent } from './requestuseraccount/requestuseraccount.component';
import { ErrorComponent } from './pages/error/error.component';
import { MsalCustom } from './Services/MsalCustom.service';
import { PrivacyagreementComponent } from './PrivacyAgreement/privacyagreement.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';

export const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full', canActivate: [MsalCustom] },
  {
    path: 'app',
   // loadChildren: () => import('src/app/pages/initial-page/initial.module').then(m => m.initialModule),
    loadChildren: () => import('src/app/pages/initial-page/initial.module').then(m => m.initialModule),
    canActivate: [MsalCustom],
  },
  // { path: 'pages', loadChildren: './pages/pages.module#PagesModule',canActivate : [MsalCustom] },
  // { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
  // { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
  {
    path: 'requestuseraccount',
    component: RequestuseraccountComponent,
    data: { breadcrumb: 'RequestUserAccount' },
  },
  {
    path: 'terms',
    component: TermsofuseComponent,
    data: { breadcrumb: 'TermsofUse' },
  },
  {
    path: 'privacy',
    component: PrivacyagreementComponent,
    data: { breadcrumb: 'PrivacyAgreement' },
  },
  // {
  //   path: 'releasenotes',
  //   component: ReleaseNotesComponent,
  //   data: { breadcrumb: 'Release Notes' },
  // },

  { path: '**', component: ErrorComponent, canActivate: [MsalCustom] },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload',
  // useHash: true
});
