import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, ApplicationRef, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './app.routing';
import { AppConfig } from './app.config';
import { AppComponent } from './app.component';
import { ErrorComponent } from './pages/error/error.component';
import { BroadcastService, MsalAngularConfiguration, MsalInterceptor, MsalModule,
  MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { Configuration, LogLevel } from 'msal';
import { ApiService } from './Services/api.service';
import { ServerService } from './Core/server.service';
import { ExceptionService } from './Core/exception.service';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AgmCoreModule } from './pages/maps/cluster-google-maps/agm/core';
import { HttpServiceHelper } from './Core/HttpServiceHelper';
import { RequestuseraccountComponent } from './requestuseraccount/requestuseraccount.component';
import { MatFormFieldModule } from '@angular/material';
import { MatTableModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import { MatIconModule } from '@angular/material';
import { MatToolbarModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material';
import { MatListModule, MatInputModule } from '@angular/material';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { environment } from '../environments/environment';
import { AgGridModule } from 'ag-grid-angular';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { MatTreeModule } from '@angular/material/tree';
import {
  ToolbarService,
  GridModule,
  GridAllModule
} from '@syncfusion/ej2-angular-grids';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';

import { CompanyService } from './pages/Services/company.service';
import { UserInfoService } from './Services/userInfo.service';
import { UnitConverterService } from './Services/unit-converter.service';
import { LoadingService } from './Services/loading.service';
import { MsalCustom } from './Services/MsalCustom.service';
import { PermissionsService } from './pages/Services/permissions.service';
import { NotificationService } from './pages/notifications/notifications.service';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { GridStatePreferencesService } from './Services/gridStatePreferences.service';
import { PrivacyagreementComponent } from './PrivacyAgreement/privacyagreement.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';
import { OnlynumberDirective } from './shared/Validators/alphabetonly.directive';
import { AppInsightService } from './Services/AppInsights-Service';
import {
  HttpXsrfInterceptor,
  HttpXsrfTokenExtractor,
  HttpXsrfCookieExtractor,
  XSRF_COOKIE_NAME,
  XSRF_HEADER_NAME,
} from './shared/Interceptors/httpxrsf.interceptor';
import { SuccessFailureService } from './pages/Services/successFailure.service';
import { NumberDirective } from './shared/Validators/numbersonly.directive';
import { SharedModule } from './shared.module';
import { UiModule } from './pages/ui/ui.module';
import { MenuService } from './theme/components/menu/menu.service';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GlobalErrorHandler } from './Core/GlobalErrorHandler';
// import { MsalComponent } from './msal/msal.component';
// import { MyMsalModule } from './msal/MyMsal.module';

//Raju Adding 
import { ChartModule } from '@syncfusion/ej2-angular-charts';

const triplinkConfig = environment.tripLinkConfig;

export function loggerCallback(logLevel, message, piiEnabled) {
  return 'client logging' + message;
}
export const protectedResourceMap: [string, string[]][] = [
  [
    'https://buildtodoservice.azurewebsites.net/api/todolist',
    triplinkConfig.consentScopes,
  ],
  ['https://graph.microsoft.com/v1.0/me', triplinkConfig.consentScopes],
];

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: triplinkConfig.clientId,
      authority: triplinkConfig.authority,
      validateAuthority: triplinkConfig.validateAuthority,
      redirectUri: triplinkConfig.redirectUri,
      postLogoutRedirectUri: triplinkConfig.postLogoutRedirectUri,
      navigateToLoginRequestUrl: triplinkConfig.navigateToLoginRequestUrl,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true  // set to true for IE 11
    },
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes:triplinkConfig.consentScopes,
    unprotectedResources: ['https://www.microsoft.com/en-us/'],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    // MsalComponent,
    ErrorComponent,
    RequestuseraccountComponent,
    PrivacyagreementComponent,
    TermsofuseComponent,
    OnlynumberDirective,
    NumberDirective,
    // ReleaseNotesComponent,
  ],
  imports: [
    UiModule,
    ReactiveFormsModule,
    GridModule,
    GridAllModule,
    SharedModule,
    TreeGridModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    TabModule,
    MatSortModule,
    MatIconModule,
    DialogModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-center-center',
      preventDuplicates: true,
    }),
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    MatDividerModule,
    BrowserModule,
    MatInputModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    MatFormFieldModule,
    MatTreeModule,
    BrowserAnimationsModule,
    AgmCoreModule,
    // MyMsalModule,
    routing,
    AgGridModule.withComponents([]),
    // MsalModule.forRoot({
    //   clientID: triplinkConfig.clientId,
    //   authority: triplinkConfig.authority,
    //   redirectUri: triplinkConfig.redirectUri,
    //   cacheLocation: triplinkConfig.cacheLocation,
    //   postLogoutRedirectUri: triplinkConfig.postLogoutRedirectUri,
    //   validateAuthority: triplinkConfig.validateAuthority,
    //   navigateToLoginRequestUrl: triplinkConfig.navigateToLoginRequestUrl,
    //   popUp: triplinkConfig.popUp,
    //   consentScopes: triplinkConfig.consentScopes,
    //   protectedResourceMap: protectedResourceMap,
    //   logger: loggerCallback,
    //   correlationId: triplinkConfig.correlationId,
    //   level: LogLevel.Info,
    //   piiLoggingEnabled: triplinkConfig.piiLoggingEnabled,
    // }),
    ChartModule
    
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MsalCustom,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,   // This will refresh the token for (A) if it is expired
      // as seen here https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/src/msal.interceptor.ts
      multi: true,
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    {
      provide: HttpXsrfTokenExtractor,
      useClass: HttpXsrfCookieExtractor,
      multi: true,
    },
    { provide: XSRF_COOKIE_NAME, useValue: 'XSRF-TOKEN', multi: true },
    { provide: XSRF_HEADER_NAME, useValue: 'X-XSRF-TOKEN', multi: true },
    AppConfig,
    ApiService,
    CompanyService,
    PermissionsService,
    NotificationService,
    UserInfoService,
    UnitConverterService,
    LoadingService,
    GridStatePreferencesService,
    ExceptionService,
    HttpClient,
    DatePipe,
    HttpServiceHelper,
    ToolbarService,
    AppInsightService,
    SuccessFailureService,
    ServerService,
    MenuService,
    {
      provide: ApplicationInsights,
      useFactory: appInsightsFactory
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
    BroadcastService
  ],
   bootstrap: [AppComponent],
 // entryComponents: [MsalComponent, AppComponent],
 // bootstrap: [AppComponent],
})
export class AppModule {
  public config: any;
  constructor(private _appConfig: AppConfig) {
    this.config = this._appConfig.config;
  }


  // ngDoBootstrap(ref: ApplicationRef) {
  //   if (window !== window.parent && !window.opener)
  //   {
  //     ref.bootstrap(MsalComponent);
  //   }
  //   else
  //   {
  //   //this.router.resetConfig(RouterModule);
  //     ref.bootstrap(AppComponent);
  //   }
  // }
}
export function appInsightsFactory(): ApplicationInsights {
  return new ApplicationInsights
({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: true,
      correlationHeaderExcludedDomains: ['*.googleapis.com']
    }
  });
}

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}