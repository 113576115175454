export const permissionList: any = {
  'Alarm': {
    'AlarmTroubleshootSteps': '27B34F32-C73A-4A96-BFD0-202A86AFEAC6'
  },
  'Analytics' : {
    'View' : 'F41E1D54-693B-4B6E-B6FA-E81B0D04BB7E',
    'HealthAnalytics': 'F41E1D54-693B-4B6E-B6FA-E81B0D04BB7E',
    'DCXHealthReports' : '6FE233AF-FB61-43E3-AE21-DA45E2053738'
  },
//  'CargoOwner': {
//     'View': 'C24482FB-7D50-4177-AC26-E9027419EE8A',
//     'Edit': '2888C5CC-DF2C-4509-855A-914A0B530EBF',
//     'Add': 'CB2D62F8-A03F-4555-9852-1F99D53B9902',
//     'delete': 'EA952D10-DD57-49B6-BC86-DE8E739C0E57'
//   },
  'Commands': {
    'ContainerID': '7A3A5C5F-879F-47A0-92BD-9FE165BE2139',
    'SetpointTemperature': '13C4BD40-3A2B-4678-A533-2B68118BD65D',
    'O2%setpoint': '9DCFD18C-DC30-4772-812A-753114746CBA',
    'CO2%setpoint': '0FB2E689-F182-4D63-9100-C7F2F33ABAF5',
    'DehumidificationSetpoint': '94C1CE25-5858-4A0D-8E18-EA861FC983A2',
    'AlarmReset': '4B7D9A13-77EB-4DD7-A990-98EE15CDDFCB',
    'NewDefrostInterval': 'D17724FD-F379-4672-9CBF-08EE181F47CC',
    'SetAutomaticColdTreatment': '292456D3-5D1B-47F9-ABDC-00DA89A2BD1F',
    'SetAutomaticSetPointChange': 'DCD657B0-C911-47B6-9AE8-8D694A8A908B',
    'SetEconomyMode ': '94E948EA-5F37-4F7A-A47D-04DCA15F6153',
    'SetNullMode': '2B6D8D42-3D0B-4E69-93E5-4654F1C97514',
    'SetQuestMode': 'A2ACD3D1-937E-4E86-B20E-8768CC2A8EC8',
    'RebootController': 'B6465690-D2BC-4327-B43A-851E66937E18',
    'InitiateDefrost': '69F6287E-7E0F-4A31-BCED-CDF63405D60F',
    'TripStart': 'EF1800E1-A29C-4210-9A84-79EE8DF0FFC5',
    'PretripInitiate': 'F6583437-FEDF-4156-9740-4CFC8E9411AB',
    'CAPretripInitiate' : '7FF6BF9C-2983-436C-8154-11800FAAA4EC',
    'ControllerSoftwareUpload': '7D96457B-6582-4CE0-AAA7-29F44DA95A11',
    'SetRTCSource': '825DECE3-7973-4DB0-B097-3B63378341D9',
    'DeviceRTC' : 'C689B601-003B-4A26-94E0-E3FD234F2B98',
    'EverFreshMode' :'028AB8D0-7085-4453-BE9A-6FD2EE4D4637',
    'Send2WayCommand': '990B2B26-5AF5-4435-A597-2B5AEF2C40F4',
    'ViewCommandStatus' : '276268C2-971E-4D57-91D7-29265FECA87B',
    'ACT': 'C43E2ABC-75CC-4F5B-9F30-3001316105D1',
    'CommandOverview': 'FA7765F4-79D9-42A8-9F61-E72587581FCA',
    'CommandNotifications': 'C8B58965-609F-4781-99A9-378B73F441A3',
    'Cancel2WayCommand': '72795931-3D5B-4998-BB70-56243BAD15A1'
  },
  'Company': {
    'View': '4C528F6C-BC80-43A7-9C51-334876929834',
    'Edit': '83F9332D-7604-4A40-B4AA-A6229FFD0500',
    'Add': 'BA788324-CB1E-4FE8-8446-5E582C0AB68B',
    'ViewAll': 'CF70F3E9-D642-4A9B-BE09-C945D7CBA170',
    'MixedFleetMap': '7E50CA84-E64B-4092-A7D5-DF0338A9F14A',
    'Delete': '3E7AEEFF-FBFD-490B-A7A9-BC2A08BD3050'
  },
  'Container': {
    'Delete': '3AFD1EA3-1354-4DFD-AD94-813F0E03379E',
    'Add': '264E85EE-79D1-4F30-8A55-812979F38F43',
    'Edit': '9DDF1DB2-9CDF-4A3C-898C-4C97542AC96A',
    'View': '01E9E32B-2C65-4EDA-BB08-DE9D963C92B1',
    'AssignDevice': 'B40B4F1A-0E40-49FA-8179-9205459CABFE',
    'Upgrade': '556ADCEA-1F5B-4138-97CF-229BB1813016',
    'Upgrades': '53D80325-0A5B-4852-99DF-87A2B70C158D',
    'EnableDisable':'4C69C8F8-3594-4B24-B02E-524DDF2820CE'
  },
  'ContainerStatus': {
    'View': '341757D6-962A-4740-AEE1-F2509730828F',
    'TemperatureCharts': '0D95C103-D81C-4540-9245-E2B7FF74F084'
  },
  'ContainerDetails': {
    'ViewHistory': '430899F8-0951-4BC9-BB12-B2C10D6569C1',
    'ViewAlarms': '73EE8273-6180-4B04-AC7A-1FE08AFE3096',
    'ViewTripwiseStatus': 'A075CBFB-4C49-4DCF-840B-2F23A1F53876',
    'ISOVoltageHistory': '98EB5FB6-51FC-4376-AB73-2F84D6B06742',
    'ViewBreadCrumbTrail' : 'D395F0D7-A81D-4F86-B999-5557D47AA517'
  },
  'ControllerSoftware': {
    'Delete': 'A3C1AEC9-40FC-43E4-9BC7-C77C011FBC8E',
    'Add': '77B086CF-4BA1-4CE3-BDCD-05455F49E757',
    'Edit': '82523C22-E52E-464A-A0C9-7347EE930E7E',
    'View': 'BA0ACBC4-FB5D-48CF-BD91-C0307326C8EE',
  },
  'ModelConfigOptions': {
    'Add': '329F5EEE-93AD-4E62-885C-365193B51086',
    'Edit': '329F5EEE-93AD-4E62-885C-365193B51086',
    'View': '5264FD3D-225D-44A0-8E29-77CE8283472D',
  },
  'ApproveDevices': {
    'Add': '88C25BBF-120D-464E-A8E6-AC10BB624E28',
    'View': '0518C66B-1477-4225-9400-B2628B454F30'
  },
  'Dashboard': {
    'ViewDashboardMap': '2828472B-A32D-4052-BCD0-9B543A3A149C'
  },
  'Device': {
    'Edit': '7EEF887F-25B8-4940-86BC-D085B8E1493C',
    'View': '2EE02AFA-626D-4767-AF07-340008EAF9DF',
    'Add': '1FDBE6CF-0167-4F80-B89F-07532D65F07D',
    'UpdateFirmware': 'DE58E7F2-36F1-405C-BF84-63DED8AFB689',
    'EditConfiguration': 'F28FF40C-E624-4B41-9DD3-76D37517A058',
    'ViewConfiguration': '9F054608-8B73-403B-87F4-93862D1DCE51',
    'EditSIMDetails': 'E3614460-5082-4F6F-B969-4C11B7D6CA1A',
    'Activate/DeactivateSIM': '1D2DF52F-E820-4BE5-8DCF-788496E50D87',
    'TransferOwnership': '9345E2AA-4ABF-4139-A051-CC18CDF07E89',
    'ViewDeviceFirmwareUpdateHistory' : '8F9998C2-B4A3-416E-B37D-40387D5453D6',
    'ViewControllerSoftwareUpdateHistory': '556ADCEA-1F5B-4138-97CF-229BB1813016',
    'ViewCommunicationLog': 'B8992C47-6A2C-4685-8834-21768D060F61',
    'DownloadCommunicationLogFile': 'A5357F35-D289-418E-9FAD-9D9BF72538B4',
    'RequestForLog': 'B870F612-DD05-4FB7-BFAB-26102BC901F8',
    'Polling': '2122CA80-B0C3-4449-A1CC-47BC335FE53C'
  },
  'DeviceFirmware': {
    'Delete': 'FCF6FE69-B643-41FA-A602-1EC866A9804F',
    'Add': '0C3DA053-C1FF-4894-9E97-D9A69FBAB604',
    'Edit': '4D5200CE-610F-4DD9-B897-30C08F514418',
    'View': 'E6B68D97-77AB-4407-85AA-BD34E2B55D05',
  },
  'Fleet': {
    'Delete': '34C9D7DD-C6ED-4E5C-BC12-2DF97B141847',
    'Add': '770935EC-F8A2-48E3-98D9-A09E504DE136',
    'Edit': 'CCC17C0B-A2F8-4DA2-AE22-B542D2FCE4ED',
    'View': '6399B328-2126-4CC4-BFB7-48ABF60318B2',
  },
  'Geofence': {
    'Delete': 'AB2B38A8-4A90-474A-B8A4-2F54E9297211',
    'Add': '40BC4227-3148-4681-BB50-62C439459423',
    'Edit': '9FA0EF11-6B1D-41BE-ACAF-99A35114CF49',
    'View': 'FE137E66-E244-41F8-AD8C-0A26C68D3F57',
  },
  'Notifications' : {
    'View' : '911C0784-3B9A-443B-9047-FCC0E26504D4'
  },
  'NotificationTemplate': {
    'Delete': '6A06E934-8CB1-42BF-BA05-1E6D9D2E6872',
    'Add': '9A2561F3-C0F2-4DFC-8661-49356C25034F',
    'Edit': 'A74BAC66-BB52-4330-BD36-EFA84BCD7AC3',
    'View': 'F5F57536-6214-4340-9DFB-35CDCC92A555'
  },
  'NotificationSetup': {
    'Delete': '15BCAC9F-46EB-46E8-91BF-696515C78F9E',
    'Add': '041CA197-7E6B-47FB-847D-BFDD3060B7F6',
    'Edit': 'E850466C-D016-4166-A58C-83FFCD2A7F48',
    'View': '0511E783-85AE-4982-9FC3-2041FA07EA77'
  },
  'Reports': {
    'CumulativeRunningHours' : '86DC9975-6479-4067-9E48-FF848B9B6893',
    'ISOUnitData': '93EC35E2-E644-4950-8E6B-A90B5AF9F44F',
    'OperatingHours': 'BC0D3D84-F1EF-4E3A-BE29-ABE300880307',
  },
  'Role': {
    'Delete': '6B85BFB2-C8E0-461A-BFCA-07DA5B31A000',
    'Add': 'CAE307B5-14B5-4065-AC93-009F53C34A60',
    'Edit': 'E3D9D1E4-14B8-4B72-84BB-13DDD9FB3978',
    'View': 'C617D262-6FCE-42E3-88F3-FF8803557BE6',
  },
  'User': {
    'Edit': '405CEE77-FC62-4502-8942-FEB0C278ED85',
    'Add': '9779521C-3292-40A0-8F08-F1F0108B53CF',
    'SendUserRegistraionLink': '933470B7-5182-46C7-BA0F-E7FFD57BB5C6',
    'ViewPendingUsers': '6879F4A1-7C3F-4808-B1DE-9D2C661C74CF',
    'View': '2B9842EE-25FF-4675-A21C-07A7AC6022F5',
    'Approval': '497636E3-5807-4A03-A847-5E2C3A0CF906',
    'ViewAll': 'CBEB82EB-34B4-4212-8842-84EA50A1BFBF',
    'Delete': '81229EC6-C11A-4D6A-B9A1-D0C32578F4C6'
  },
  'Widgets': {
    'PowerOnOff': '09B49F07-8959-4B14-93CE-35A4D5C4BEEF',
    'AlarmsCriticalNonCritical': 'FFF75F7E-B3D8-41FA-9EDF-A3D2A3C8866C',
    'DeviceReportingNonReporting': 'B855CD87-039F-4FE3-8F76-C434DC791E6A',
    'TripWiseStatus': 'E4AFA0C2-A263-4993-A678-B8E7E2E11258',
    'CargoType': '57725D7F-3FA6-4C5C-AB3F-00C6854B219E',
    'ContainerModelType': '0FA9BD1D-F08C-47AA-B2C5-8EB24B17C63B',
    'ControllerSoftware': '1E7712BA-16B0-4DCE-9282-3272723B3AC2',
    'TripLINKDeviceFirmware': '8901820F-E04B-41D3-9BD9-4F622D0C62F3',
    'LocationGeographic': '528EDA32-26A4-4990-AE98-893641AEC53A',
    'CargoStatus': 'CFC62624-2DA6-443A-8578-5602E6A57D37',
    'LocationGeoFence': '0470EDD0-3F24-40E9-B81D-A42A52668F72',
    'OperationMode': 'D53C3869-119E-4E50-A72A-20AE600F6A00',
    'TripLinkExpiry': '6D5C945D-B0E8-4DB6-B94B-FA9DC3F4932E',
    'Manufacturer' : '5D13F1BB-432A-414D-999A-0FB59D1DA2CE',
    'Genset':'58E72A4B-DC0B-4651-A826-FE77FBE0EEFB'
  },
  'Vessel' : {
    'View': '704746B6-135C-448D-AF2B-E0B4C7534909',
    'Add': 'CB934A47-9198-4E9B-AC67-EA3170B2617F',
    'Edit': 'E9A8E933-F991-4BC2-AFED-7B8838CB9ACF',
    'Delete': '45BC1B52-C79E-48AE-973B-E7444911554D'
  },
  'Permissions': {
    'View': '6AF88B06-921B-4D98-BB6F-A4BF8CCA0E81',
  },
  'Settings' : {
    'ChangePassword' : {
      'View' : '9A82A89F-D4CB-4005-A873-0D103212BF8E'
    }
  },
  'FleetAnalytics' : {
    'View': 'F08B8107-12EB-4614-A849-D8C75BB27385'
    // 'OverallFleetHealth' : '64D170DE-0344-4FA3-8B45-9AF9A9645FCD',
    // 'FleetCriticalUnits':'E3A5BC36-4EF2-46D4-BE09-27D0D4DA71C5',
    // 'ContainerOverallHealth':  '14FDB482-9C51-4234-B9E3-D75C92BBC4AA',
    // 'ContainerMapView': '69380858-616D-43B7-9162-BBFA88FAF2A2',
    // 'FleetQuickInsights' : '82118438-CE9F-47B3-8E5A-3A05278CDAD3',
    // 'FleetHealthTrends' : 'DCCDD45D-8E27-4D2F-8A1C-F8A19ABDAD50'
  },
  'GlobalRole' : {
    'Add' : '8B43E01B-401C-47FC-AF3A-D604578514E1'
  },
  'Genset' : {
    'StatusView': '58E72A4B-DC0B-4651-A826-FE77FBE0EEFB'
  },
  'SupportTeam':{
    'ContainerSearch':'14750FDF-97B2-4E46-A4E2-26C1318ED2B0'
  },
  'Trips': {
    'Edit': 'EC4CCE02-04C2-40B3-9BEF-1E171A7AE4A8',
    'View': '4489B65A-2C1B-4057-AD5F-7958CC22F98D',
    'Add': 'FE75C740-DE2B-44E9-9B3C-8E9F2A3BF691',
    'Delete': 'D035E942-EFB3-4A7A-A57C-A23D1B4A477E'

    
  },

};
// a.	If user has global permission, then only global role related user info will be visible along with all other information.
// b.	If user doesn’t have global permission, he will not be able to user’s info of global role but will be able to see non-global roles info.
// c.	Create global role option will be visible in the role screen toolbar if user is global user.
// d.	Permissions structure will get altered by hiding global permissions (company view all & users view all) when local user trying to create/edit a role.
// e.	When global user trying to create/edit global role company id will be null.
// f.	If user doesn’t have global permission, then editing global roles will be restricted from UI.
// g.	Only global user can delete/edit global roles restriction added.
