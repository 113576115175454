import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerService } from '../../Core/server.service';
import { ApiService } from '../../Services/api.service';
import { MsalService } from '@azure/msal-angular';
import { SuccessFailureService } from '../Services/successFailure.service';
import { UserInfoService } from 'src/app/Services/userInfo.service';


@Injectable()
export class PermissionsService {
  totalList = [];
  permissionList: Object[];
  public field: Object;
  constructor(private serverService: ServerService, public successFailureService: SuccessFailureService,
    private userInfoService: UserInfoService,
    private http: HttpClient, private apiService: ApiService, private authService: MsalService) {
  }

  public permissionData;
  public updatePermissionSession() {
    this.apiService.getUserLoggedInfo(this.userInfoService.getUserAccessEmail()).subscribe((response: any) => {
      if (this.successFailureService.successFailureReturn(response)) {
        if (response.Permissions) {
          sessionStorage.setItem('PermissionList', JSON.stringify(response.Permissions.map(v => v.toUpperCase())));
        }
      }
    });
  }
  public getpermissions() {
    const val = [];
    this.apiService.getPermissions().subscribe((tenants: any) => {
      if (this.successFailureService.successFailureReturn(tenants)) {
        this.permissionList = tenants.PermissionResponses.map(tenant => {
          return {
            name: tenant.GroupName,
            id: tenant.Permissions
          };
        });

        this.permissionList.forEach(element => {
          let objectmodel = {};
          objectmodel = { id: element['name'], name: element['name'], hasChild: true, expanded: true };
          val.push(objectmodel);
        });
        this.permissionList.forEach(element => {
          element['id'].forEach(e => {
            let objectmodelchild = {};
            objectmodelchild = { id: e.PermissionID, pid: e.GroupName, name: e.Permission };
            val.push(objectmodelchild);
            this.permissionData = val;
            this.field = {
              dataSource: this.permissionData, id: 'id',
              parentID: 'pid', text: 'name', hasChildren: 'hasChild'
            };
          });
        });
      }
    });
    return val;
  }
}
