import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
    PageSettingsModel, ToolbarItems,
    GridComponent, FilterSettingsModel, PdfExportProperties, ExcelExportProperties
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CONFIG } from '../../../Core/config';
import {TabComponent } from '@syncfusion/ej2-angular-navigations';
import { CustomSyncFusionGridService } from '../../Services/customSyncfusionGrid.service';
import { UserInfoService } from '../../../Services/userInfo.service';
import { UnitConverterService } from '../../../Services/unit-converter.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { LoadingService } from '../../../Services/loading.service';
import { TranslateService } from 'src/language-translation';

@Component({
    selector: 'app-asset-model-popup',
    templateUrl: './asset-model-popup.component.html',
    styleUrls: ['./asset-model-popup.component.scss']
})
export class AssetModelPopupComponent implements OnInit {
    @Input() AssetModelInfo: any;
    @ViewChild('tab', { static: false }) tabObj: TabComponent;
    @ViewChild('assetModelDialog', { static: false }) public assetModelDialog: DialogComponent;
    @ViewChild('PRIMELineGrid', { static: false }) public PRIMELineGrid: GridComponent;
    @ViewChild('ThinLineGrid', { static: false }) public ThinLineGrid: GridComponent;
    @ViewChild('NaturalLineGrid', { static: false }) public NaturalLineGrid: GridComponent;
    @ViewChild('EliteLineGrid', { static: false }) public EliteLineGrid: GridComponent;
    @Output() assetDialogClose = new EventEmitter();

    public data: any;
    public assetModelCount: number;
    public PrimeLINEData: Object[] = [];
    public ThinLINEData: Object[] = [];
    public NaturalLINEData: Object[] = [];
    public EliteLINEData: Object[] = [];
    public showContainerId = true;
    public measurementUnit = 'Metric';
    public tempUnit = 'C';

    public confirmHeader;
    public visible: Boolean = true;
    public hidden: Boolean = true;
    public toolbarOptions: ToolbarItems[] | object;
    public filterOptions: FilterSettingsModel;
    public pageSettings: PageSettingsModel;
    public formatOptions: object;
    public decimalpoint: any;
    public target = '.control-section';
    public dialogdragging: Boolean = true;
    public dialogResize: Boolean = true;
    //  public position: object = { X: 600, Y: 200 };
    public animationSettings: Object = { effect: 'None' };
    public confirmCloseIcon: Boolean = true;
    public isModal: Boolean = true;
    public showassetModelData: Boolean = false;
    public dateFormat: string;



    constructor(public datepipe: DatePipe, public customSyncFusionGridService: CustomSyncFusionGridService,
        private LoadingService: LoadingService,
        private _translate: TranslateService,
        private _dashboardService: DashboardService,    public router: Router, private userInfoService: UserInfoService, public UnitConverterService: UnitConverterService) {
        this.filterOptions = {
            type: 'Excel'
        };
        this.pageSettings = { pageSize: 10 };
        this.dateFormat = this.UnitConverterService.getDateFormat();
        this.formatOptions = { type: 'dateTime', format: this.dateFormat + ' hh:mm:ss a' };
        this.decimalpoint = 'N2' ;//{ format: 'N2' };
        this.toolbarOptions = ['ExcelExport', 'PdfExport', 'Search'];
        this.measurementUnit = this.userInfoService.loggedinUserData ? this.userInfoService.loggedinUserData.UnitConversionType
            : ((sessionStorage.getItem('UserInfo')) ? JSON.parse(sessionStorage.getItem('UserInfo')).UnitConversionType : '');

        if (this.measurementUnit == 'Imperial') {
            this.tempUnit = 'F';
        }
    }

    fillData() {
        // if (this.AssetModelInfo) {
        //     this.data = this.AssetModelInfo;
        // }
        if (this.data) {
            if (this.data.EliteLINEList) {
                this.data.EliteLINEList.map((item: any) => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                    } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                    }
                });
            }
            if (this.data.NaturalLINEList) {
                this.data.NaturalLINEList.map((item: any) => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                    } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                    }
                });
            }
            if (this.data.PrimeLINEList) {
                this.data.PrimeLINEList.map((item: any) => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                    } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                    }
                });
            }
            if (this.data.ThinLINEList) {
                this.data.ThinLINEList.map((item: any) => {
                    item.EventTimeUTC = this.UnitConverterService.convertTimeZone(item.EventTimeUTC);
                    if (this.tempUnit === 'F') {
                        item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(item.AmbientTemp);
                    } else if (this.tempUnit === 'C') {
                        item.AmbientTemp = this.UnitConverterService.temptoFixed(item.AmbientTemp);
                    }
                });
            }
            this.assetModelCount = this.data.PrimeLINECount
                + this.data.ThinLINECount + this.data.NaturalLINECount + this.data.EliteLINECount;
            this.PrimeLINEData = DataUtil.parse.parseJson(this.data.PrimeLINEList);
            this.ThinLINEData = DataUtil.parse.parseJson(this.data.ThinLINEList);
            this.NaturalLINEData = DataUtil.parse.parseJson(this.data.NaturalLINEList);
            this.EliteLINEData = DataUtil.parse.parseJson(this.data.EliteLINEList);
            this.confirmHeader = this._translate.translate('Container_Model_Type') + ' (' + this.assetModelCount + ')';
            this.showContainerId = (this.data.fromHistorypage) ? this.data.fromHistorypage : false;
        }

    }
    ngOnInit() {
        if (this.AssetModelInfo) {
            this.LoadingService.show();
            this._dashboardService.getReeferModalGeofenceDetails(this.AssetModelInfo.assetModelData.apiObject).subscribe(response => {
              this.data = response;
              this.fillData();
              this.showassetModelData = true;
              this.LoadingService.hide();
        });

        }
    }

    toolbarClick(args: ClickEventArgs, type): void {
        let checkNum;
        switch (type) {
            case 'PRIMELine':
                checkNum = this.PRIMELineGrid;
                break;
            case 'ThinLine':
                checkNum = this.ThinLineGrid;
                break;
            case 'NaturalLine':
                checkNum = this.NaturalLineGrid;
                break;
            case 'EliteLine':
                checkNum = this.EliteLineGrid;
                break;
            default:
                break;
        }
        const pdfExportProperties: PdfExportProperties = {
            pageOrientation: 'Landscape',
            fileName: 'ContainerModelType.pdf'
        };
        switch (args.item.text) {
            case 'PDF Export': checkNum.pdfExport(pdfExportProperties);
                break;
            case 'Excel Export':
                const excelExportProperties: ExcelExportProperties = {
                    fileName: 'ContainerModelType.xlsx'
                };    
            checkNum.excelExport(excelExportProperties);
                break;
            case 'CSV Export': checkNum.csvExport();
                break;
        }
    }

    dataBound(type) {
        if (type === 'PRIMELine') {
            this.customSyncFusionGridService.showNoRecordstoView(this.PRIMELineGrid, this.PrimeLINEData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.PRIMELineGrid.autoFitColumns();
            let toolObjCritical;
            let iscriticalhide;
            toolObjCritical = this.PRIMELineGrid.toolbarModule.toolbar;
            iscriticalhide = this.PRIMELineGrid.currentViewData.length ? false : true;
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'PDF Export'), iscriticalhide);
            toolObjCritical.hideItem(toolObjCritical.items.findIndex(c => c.text === 'Excel Export'), iscriticalhide);
        }
        if (type === 'ThinLine') {
            this.customSyncFusionGridService.showNoRecordstoView(this.ThinLineGrid, this.ThinLINEData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.ThinLineGrid.autoFitColumns();
            let toolObjActive;
            let isactivehide;
            toolObjActive = this.ThinLineGrid.toolbarModule.toolbar;
            isactivehide = this.ThinLineGrid.currentViewData.length ? false : true;
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'PDF Export'), isactivehide);
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'Excel Export'), isactivehide);
        }
        if (type === 'NaturalLine') {
            this.customSyncFusionGridService.showNoRecordstoView(this.NaturalLineGrid, this.NaturalLINEData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.NaturalLineGrid.autoFitColumns();
            let toolObjActive;
            let isactivehide;
            toolObjActive = this.NaturalLineGrid.toolbarModule.toolbar;
            isactivehide = this.NaturalLineGrid.currentViewData.length ? false : true;
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'PDF Export'), isactivehide);
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'Excel Export'), isactivehide);
        }
        if (type === 'EliteLine') {
            this.customSyncFusionGridService.showNoRecordstoView(this.EliteLineGrid, this.EliteLINEData, this.customSyncFusionGridService.lengthGreatreThanZero);
            this.EliteLineGrid.autoFitColumns();
            let toolObjActive;
            let isactivehide;
            toolObjActive = this.EliteLineGrid.toolbarModule.toolbar;
            isactivehide = this.EliteLineGrid.currentViewData.length ? false : true;
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'PDF Export'), isactivehide);
            toolObjActive.hideItem(toolObjActive.items.findIndex(c => c.text === 'Excel Export'), isactivehide);
        }


    }

    public tabCreated(): void {
        this.tabObj.selectedItem = this.AssetModelInfo.activeTab;
    }

    navigateAssetHistory(invNumData, type) {
        let invNum;
        switch (type) {
            case 'PRIMELine':
                invNum = this.PRIMELineGrid.getRowInfo(invNumData.target).rowData;
                break;
            case 'ThinLine':
                invNum = this.ThinLineGrid.getRowInfo(invNumData.target).rowData;
                break;
            case 'NaturalLine':
                invNum = this.NaturalLineGrid.getRowInfo(invNumData.target).rowData;
                break;
            case 'EliteLine':
                invNum = this.EliteLineGrid.getRowInfo(invNumData.target).rowData;
                break;
            default:
                break;
        }
        const assetdata = {
            'startdate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
            'endDate': this.datepipe.transform(invNum['EventTimeUTC'], this.dateFormat),
        };
        const data = { type: 'dashboard', data: assetdata };
        this.LoadingService.show();
        this.router.navigate([CONFIG.ROUTE_URLS.AssetDetails, invNum['DeviceID'], invNum['ContainerID']], { state: { data } });

    }

    assetDialogClosed() {
        this.assetDialogClose.emit(0);
    }
}
