import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import './polyfills';
import { MyMsalModule } from './app/msal/MyMsal.module';


if (environment.production) {
  window.console.log = ()=>{}
  enableProdMode();
}
// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
// const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
//  const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);


if (environment.hmr) {
  if (module[ 'hot' ]) {
    // hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  // bootstrap().catch(err => console.log(err));
  if (!(window !== window.parent && !window.opener)) {
    platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
  } else {
    platformBrowserDynamic().bootstrapModule(MyMsalModule)
    .catch(err => console.log(err));
  }
}
